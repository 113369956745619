import { useCallback, useEffect, useState } from "react";
import NextLink, { LinkProps } from "next/link";

import useBasePath from "@/hooks/useBasePath";
import useRouter from "@/hooks/useRouter";

import styles from "./link.module.scss";
import cn from "@/libs/cn";

interface Link extends Omit<LinkProps, "href"> {
  href: string | (() => string);
  target?: string;
  module?: string;
  wrapClassName?: string;
  title?: string;
  onClick?: (e: any) => void;
  stopClickEvent?: boolean;
  disabled?: boolean;
  className?: string;
  classNameActive?: string;
  children?: any;
}

const Link = ({
  href,
  target,
  module,
  children,
  title,
  onClick: onClickOut = (e: any) => {},
  stopClickEvent,
  disabled,
  className,
  classNameActive,
  ...rest
}: Link) => {
  const router = useRouter();
  const [active, setActive] = useState<any>("");

  const baseRoute = useBasePath({ module });

  const path = typeof href === "function" ? href() : href;

  const preHref = `${baseRoute ? `/${baseRoute}/` : ""}${path}`;

  // Fix error https://nextjs.org/docs/messages/react-hydration-error#possible-ways-to-fix-it
  useEffect(
    () =>
      setActive(
        preHref === router.asPath ? [styles.active, classNameActive] : ""
      ),
    [classNameActive, preHref, router.asPath]
  );

  const onClick = useCallback(
    (e: any) => {
      if (stopClickEvent) {
        e.stopPropagation();
      }
      onClickOut(e);
    },
    [onClickOut, stopClickEvent]
  );

  return (
    <NextLink {...rest} href={disabled ? "#" : preHref}>
      <a
        target={target}
        className={cn(styles.wrap, active, className)}
        onClick={onClick}
        title={title}
      >
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
