import "@/styles/globals.css";
import "@/styles/mixines.scss";
import "@/styles/themes.scss";
import type { AppProps } from "next/app";
import GlobalProvider from "@/context/GlobalProvider";
import MainLayout from "@/modules/layout/pages/main";
import ProgressBar from "@/components/basic/ProgressBar";

//////////////////////////////////////////////////////////////////////////
// Fontawesome icons
// If necessary, connect it
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGift,
  faGrip,
  faGripLines,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowUpShortWide,
  faCartShopping,
  faXmark,
  faTruck,
  faCaretUp,
  faCaretDown,
  faPhone,
  faPersonWalking,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faEye, faHeart } from "@fortawesome/free-regular-svg-icons";
// import { faTwitter, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
library.add(
  faGift,
  faGrip,
  faGripLines,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowUpShortWide,
  faCartShopping,
  faEye,
  faHeart,
  faXmark,
  faTruck,
  faCaretUp,
  faCaretDown,
  faPhone,
  faPersonWalking,
  faEnvelope
);
// Fontawesome icons
//////////////////////////////////////////////////////////////////////////

function App({ Component, pageProps }: AppProps) {
  // Use the layout defined at the page level, if available
  const getLayout =
    // @ts-ignore
    Component.getLayout || ((page: any) => <MainLayout>{page}</MainLayout>);

  return (
    <>
      {/* @ts-ignore */}
      <GlobalProvider pageProps={pageProps}>
        {getLayout(<Component {...pageProps} />)}
      </GlobalProvider>
      <ProgressBar />
    </>
  );
}

export default App;
