import React from "react";
import cn from "@/libs/cn";
import useGlobalContext from "@/context/GlobalContext";
import DetailsAccordion from "../DetailsAccordion";

import styles from "./styles.module.scss";
import { IApiOrder } from "@/types/interfaces/orders";

const OrderStatus = (props: IApiOrder) => {
  const { translate } = useGlobalContext();

  return (
    <DetailsAccordion
      className={styles.details}
      header={
        <div className={styles.details_header}>
          <div
            className={cn(styles.details_header, styles.title, styles.status)}
          >
            {translate(status)}
          </div>
          <div className={cn(styles.details_header, styles.date)}>
            29.05.2021
          </div>
        </div>
      }
    >
      <div className={styles.details_content}>
        <div className={styles.statusDetails_step}>
          <div className={styles.statusDetails_wrapper}>
            <div className={cn(styles.statusDetails_point, styles.active)} />
            <div className={styles.statusDetails_time}>10:12</div>
            <div className={styles.statusDetails_label}>Completed</div>
          </div>
          <div className={styles.statusDetails_wrapper}>
            <div className={styles.statusDetails_point} />
            <div className={styles.statusDetails_time}>09:03</div>
            <div className={styles.statusDetails_label}>
              On the way to the recipient
            </div>
          </div>
        </div>
        <div className={styles.statusDetails_step}>
          <div className={styles.statusDetails_date}>12.05.2021</div>
          <div className={styles.statusDetails_wrapper}>
            <div className={styles.statusDetails_point} />
            <div className={styles.statusDetails_time}>22:10</div>
            <div className={styles.statusDetails_label}>
              On the way to the Ukraine
            </div>
          </div>
          <div className={styles.statusDetails_wrapper}>
            <div className={styles.statusDetails_point} />
            <div className={styles.statusDetails_time}>19:09</div>
            <div className={styles.statusDetails_label}>Ready to ship</div>
          </div>
        </div>
        <div className={styles.statusDetails_step}>
          <div className={styles.statusDetails_date}>10.05.2021</div>
          <div className={styles.statusDetails_wrapper}>
            <div className={styles.statusDetails_point} />
            <div className={styles.statusDetails_time}>21:32</div>
            <div className={styles.statusDetails_label}>Confirmed</div>
          </div>
          <div className={styles.statusDetails_wrapper}>
            <div className={styles.statusDetails_point} />
            <div className={styles.statusDetails_time}>18:43</div>
            <div className={styles.statusDetails_label}>New order</div>
          </div>
        </div>
      </div>
    </DetailsAccordion>
  );
};

export default OrderStatus;
