import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import useGlobalContext from "@/context/GlobalContext";
import cn from "@/libs/cn";
import routesPublic from "@/modules/public/constants/routesPublic";

import styles from "./styles.module.scss";

interface ButtonCart {
  className?: string;
}

const ButtonCart = (props: ButtonCart) => {
  const { className } = props;
  const {
    isMobileSize,
    translate,
    currency,
    modules: { cart },
  } = useGlobalContext();

  return (
    <div className={cn(styles.ButtonCart, className)}>
      <Link href={routesPublic.cart()}>
        <div className={styles.wrapIcon}>
          <Image
            src="/assets/cart.svg"
            className={styles.icon}
            alt="cart"
            width={28}
            height={28}
          />
          {isMobileSize && !!cart.list.length && (
            <div className={styles.badge}>{cart.list.length}</div>
          )}
        </div>
        {!isMobileSize && (
          <div className={styles.text}>{`${translate("Cart")}: ${
            currency.symbol
          }${cart.total.toFixed(2)}`}</div>
        )}
      </Link>
    </div>
  );
};

export default ButtonCart;
