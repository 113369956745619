import { useCommonSWR } from "@/libs/swr";
import apiCart from "../constants/apiCart";
import apiOrder from "../constants/apiOrder";

export const useDataCartPurchases = () => {
  return useCommonSWR({
    key: "/api/v1/products/UA/",
    defaultData: [],
  });
};

export const useDataPaymentsMethods = () => {
  return useCommonSWR({
    key: apiCart.paymentsMethods(),
    defaultData: [],
  });
};

export const useDataOrders = (filterStr?: string) => {
  return useCommonSWR({
    key: `${apiOrder.getOrders()}${filterStr ? `?${filterStr}` : ""}`,
    defaultData: [],
  });
};

export const useDataOrder = (orderId: any) => {
  return useCommonSWR({
    key: apiOrder.getOrder(orderId),
  });
};
