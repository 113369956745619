import { APP_API } from "@/constants/common";

const API_ORDER = `${APP_API}/orders/`;

const apiOrder = {
  getOrders: () => `${API_ORDER}`,
  getOrder: (orderId: string) => `${API_ORDER}${orderId}/`,
  createOrder: () => `${API_ORDER}`,
};

export default apiOrder;
