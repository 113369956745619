import React from "react";

import styles from "./styles.module.scss";

interface NativeSelect {
  options: { value: any; title: string }[];
  onChange: (value: any) => void;
  defaultValue?: any;
  className?: string;
}

// eslint-disable-next-line react/display-name
const NativeSelect = React.forwardRef((props: NativeSelect, ref) => {
  const { options, defaultValue, className, onChange, ...rest } = props;
  return (
    <select
      // @ts-ignore
      ref={ref}
      className={styles.NativeSelect}
      onChange={(e) => onChange(e.target.value)}
      defaultValue={defaultValue}
      {...rest}
    >
      {options.map((item) => (
        <option key={item.value} value={item.value}>
          {item.title}
        </option>
      ))}
    </select>
  );
});

export default NativeSelect;
