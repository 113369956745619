import { ValueRoute } from "@/types/modules/interface-types";

export const baseRoute = "gallery";

export type TypeRoutes = {
  root: ValueRoute;
};

const routesGallery = {
  root: () => `/${baseRoute}/`,
} as TypeRoutes;

export default routesGallery;
