import { useFormikContext } from "formik";
import { useEffect } from "react";

const sortKeys = (a: string, b: string) => (a > b ? 1 : -1);

interface ListenerFormikValuesChanges {
  initValues: {};
  onListen: (modify: boolean) => void;
}

const ListenerFormikValuesChanges = (props: ListenerFormikValuesChanges) => {
  const { initValues, onListen } = props;
  const { values } = useFormikContext();

  useEffect(() => {
    const oldKeys = Object.keys(initValues).sort(sortKeys);
    // @ts-ignore
    const newKeys = Object.keys(values).sort(sortKeys);

    if (oldKeys.join(",") !== newKeys.join(",")) {
      onListen(true);
      return;
    }

    const oldVals = oldKeys.map((key) =>
      // @ts-ignore
      typeof initValues[key] === "object" && initValues[key] !== null
        ? // @ts-ignore
          JSON.stringify(initValues[key])
        : // @ts-ignore
          initValues[key]
    );

    const newVals = oldKeys.map((key) =>
      // @ts-ignore
      typeof values[key] === "object" && values[key] !== null
        ? // @ts-ignore
          JSON.stringify(values[key])
        : // @ts-ignore
          values[key]
    );

    if (oldVals.join(",") !== newVals.join(",")) {
      onListen(true);
      return;
    }

    onListen(false);
  }, [initValues, onListen, values]);

  return null;
};

export default ListenerFormikValuesChanges;
