import useVisibilityWindow from "@/hooks/useVisibilityWindow";
import { isZero } from "@/utils/format";
import { getOldPrice, getRealPrice } from "@/utils/price";
import { useCallback, useEffect, useMemo, useState } from "react";
import { crudFetcherCommon, crudFetcherNextApi } from "services/crud";
import apiAuthCart from "../constants/apiAuthCart";
import apiRedisCart from "../constants/apiRedisCart";

export interface Product {
  id: number;
  price: string;
  final_price: string;
  vat: number;
  in_stock: boolean;
  is_active: boolean;
}
export interface CartItem {
  id: number;
  product: Product;
  quantity: number;
  price_for_unit: number;
  price_total: number;
}

interface useCart {
  isReady: boolean;
  isAuthorized: boolean;
  isSeller: boolean;
  toast: {
    info: (text: string) => void;
    success: (text: string) => void;
    warning: (text: string) => void;
    error: (text: string) => void;
  };
  translate: (text: string) => string;
}

export const normalizeCartProductItem = (data: Product) => {
  return {
    id: data.id,
    price: data.price,
    final_price: data.final_price,
    vat: data.vat,
    in_stock: data.in_stock,
    is_active: data.is_active,
  };
};

const useCart = ({
  isReady,
  isAuthorized,
  isSeller,
  toast,
  translate,
}: useCart) => {
  const [list, setList] = useState<CartItem[]>([]);
  const [isCartAddProcessing, setCartAddProcessing] = useState<any>(false);
  const [isCartDeleteProcessing, setCartDeleteProcessing] =
    useState<any>(false);
  const [isCartUpdateProcessing, setCartUpdateProcessing] =
    useState<any>(false);
  const [isCartCommonProcessing, setCartCommonProcessing] =
    useState<any>(false);

  const quantity = useMemo(
    () =>
      list.reduce(
        (prevValue, currentValue) => prevValue + currentValue.quantity,
        0
      ),
    [list]
  );

  const total = useMemo(() => {
    let t = 0;

    try {
      t = list.reduce((prevValue, currentValue) => {
        return (
          prevValue +
          currentValue.quantity * parseFloat(currentValue.product.final_price)
        );
      }, 0);
    } catch (err) {
      console.log("Error total", err, list);
    }

    return t;
  }, [list]);

  const discount = useMemo(() => {
    let t = 0;

    try {
      t = list.reduce((prevValue, currentValue) => {
        const { price, final_price } = currentValue.product;
        const realPrice = getRealPrice(price, final_price);
        const oldPrice = getOldPrice(price, final_price);

        if (isZero(oldPrice)) {
          return prevValue;
        } else {
          return (
            prevValue +
            currentValue.quantity *
              (parseFloat(oldPrice) - parseFloat(`${realPrice}`))
          );
        }
      }, 0);
    } catch (err) {
      console.log("Error discount", err, list);
    }

    return t;
  }, [list]);

  const vatSum = useMemo(() => {
    let t = 0;

    try {
      t = list.reduce((prevValue, currentValue) => {
        const { price, final_price, vat } = currentValue.product;
        const realPrice = getRealPrice(price, final_price);

        return (
          prevValue +
          (currentValue.quantity * parseFloat(`${realPrice}`) * vat) / 100
        );
      }, 0);
    } catch (err) {
      console.log("Error discount", err, list);
    }

    return t;
  }, [list]);

  const getList = useCallback(() => {
    setCartCommonProcessing(true);

    const getItems = isAuthorized
      ? crudFetcherCommon.get({ url: apiAuthCart.cart() })
      : crudFetcherNextApi.get({
          url: apiRedisCart.cart(),
        });

    getItems.then((data) => {
      setList(Array.isArray(data) ? data : []);
      setCartCommonProcessing(false);
    });
  }, [isAuthorized]);

  const updateList = useCallback(() => {
    if (isReady) getList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getList, isReady, isAuthorized]);

  useVisibilityWindow(updateList, true);

  const addProductToCart = useCallback(
    (data: Product, quantity: number) => {
      if (!data.in_stock) {
        toast.info(translate("The product is out of stock"));
        return;
      }

      if (isSeller) {
        toast.info(translate("The seller cannot work with the cart"));
        return;
      }

      setCartAddProcessing(data?.id);

      const addItem = isAuthorized
        ? crudFetcherCommon.post({
            url: apiAuthCart.cart(),
            requestData: {
              product: data.id,
              quantity,
            },
          })
        : crudFetcherNextApi.post({
            url: apiRedisCart.addTocart(),
            requestData: {
              quantity,
              product: data,
            },
          });

      addItem.then((data) => {
        setCartAddProcessing(false);
        getList();
      });
    },
    [getList, isAuthorized, isSeller, toast, translate]
  );

  const deleteProductFromCart = useCallback(
    (cartId: number) => {
      if (isSeller) {
        toast.info(translate("The seller cannot work with the cart"));
        return;
      }

      setCartDeleteProcessing(cartId);

      const deleteItem = isAuthorized
        ? crudFetcherCommon.delete({
            url: apiAuthCart.cartItem(cartId),
          })
        : crudFetcherNextApi.delete({
            url: apiRedisCart.deleteFromCart(cartId),
          });

      deleteItem.then((data) => {
        setCartDeleteProcessing(false);
        getList();
      });
    },

    [getList, isAuthorized, isSeller, toast, translate]
  );

  const updateProductInCart = useCallback(
    (cartId: any, quantity: number) => {
      if (isSeller) {
        toast.info(translate("The seller cannot work with the cart"));
        return;
      }

      setCartUpdateProcessing(cartId);

      const updateItem = isAuthorized
        ? crudFetcherCommon.patch({
            url: apiAuthCart.cartItem(cartId),
            requestData: {
              quantity,
            },
          })
        : crudFetcherNextApi.put({
            url: apiRedisCart.updateProductCart(cartId),
            requestData: {
              quantity,
            },
          });

      updateItem.then((data) => {
        setCartUpdateProcessing(false);
        getList();
      });
    },

    [getList, isAuthorized, isSeller, toast, translate]
  );

  const data = useMemo(
    () => ({
      quantity,
      total,
      vatSum,
      discount,
      formattedValue: "0.00",
      list,
      isCartAddProcessing,
      isCartDeleteProcessing,
      isCartUpdateProcessing,
      isCartCommonProcessing,
      addProductToCart,
      deleteProductFromCart,
      updateProductInCart,
    }),
    [
      quantity,
      total,
      vatSum,
      discount,
      list,
      isCartAddProcessing,
      isCartDeleteProcessing,
      isCartUpdateProcessing,
      isCartCommonProcessing,
      addProductToCart,
      deleteProductFromCart,
      updateProductInCart,
    ]
  );

  return data;
};

export default useCart;
