import { useCommonSWR, useNextApiSWR } from "@/libs/swr";
import apiPublic from "../constants/apiPublic";

export const useDataPublicLanguages = () => {
  return useCommonSWR({
    key: apiPublic.languages(),
    defaultData: [],
  });
};

export const useDataPublicCurrencies = () => {
  return useCommonSWR({
    key: apiPublic.currencies(),
  });
};

export const useDataPublicStores = () => {
  return useCommonSWR({
    key: apiPublic.stores(),
  });
};

export const useDataPublicStore = (storeId: any) => {
  return useCommonSWR({
    key: apiPublic.store(storeId),
  });
};

export const useDataPublicStoreTags = () => {
  return useCommonSWR({
    key: apiPublic.storesTags(),
    defaultData: [],
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicStoreTag = (storeId: any) => {
  return useCommonSWR({
    key: apiPublic.storeTag(storeId),
    defaultData: [],
  });
};

export const useDataPublicSocialNetworks = () => {
  return useCommonSWR({
    key: apiPublic.socialNetworks(),
    defaultData: [],
  });
};

export const useDataPublicProductTags = () => {
  return useCommonSWR({
    key: apiPublic.productTags(),
    defaultData: [],
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicCampaigns = (baseApi: string, filterStr: string = '') => {
  return useCommonSWR({
    key: `${baseApi}?${filterStr}`,
    defaultData: [],
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicProduct = (productId: any) => {
  return useCommonSWR({
    key: apiPublic.product(productId),
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicCountries = () => {
  return useCommonSWR({
    key: apiPublic.countries(),
    defaultData: [],
  });
};

export const useDataPublicCountryProvinces = (countryCode: string) => {
  return useCommonSWR({
    key: apiPublic.countryProvinces(countryCode),
  });
};

export const useDataPublicCountryCities = (countryCode: string) => {
  return useCommonSWR({
    key: apiPublic.countryCities(countryCode),
    defaultData: [],
  });
};

export const useDataPublicDeliveryOptions = () => {
  return useCommonSWR({
    key: apiPublic.storesDeliveryOptions(),
    defaultData: [],
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicCategories = () => {
  return useCommonSWR({
    key: apiPublic.categories(),
    defaultData: [],
  });
};

export const useDataPublicTopCategories = () => {
  return useCommonSWR({
    key: `${apiPublic.categories()}?level=0`,
    defaultData: [],
  });
};

export const useDataPublicCategory = (categoryId: any) => {
  return useCommonSWR({
    key: apiPublic.category(categoryId),
  });
};

export const useDataPublicVAT = () => {
  return useCommonSWR({
    key: apiPublic.vat(),
  });
};

// From next api

export const useDataPublicBanners = () => {
  return useNextApiSWR({
    key: apiPublic.banners(),
    defaultData: [],
  });
};

export const useDataPublicSales = () => {
  return useNextApiSWR({
    key: apiPublic.sales(),
    defaultData: [],
  });
};

export const useDataPublicFeaturedProducts = () => {
  return useNextApiSWR({
    key: apiPublic.featuredProducts(),
    defaultData: [],
  });
};

export const useDataPublicFeaturedTags = () => {
  return useNextApiSWR({
    key: apiPublic.featuredTags(),
    defaultData: [],
  });
};

export const useDataPublicNewStoresMOCK = () => {
  return useNextApiSWR({
    key: apiPublic.MOCKnewStores(),
    defaultData: [],
  });
};
