import { ValueRoute } from "@/types/modules/interface-types";

export const baseRoute = "";

export type TypeRoutes = {
  root: ValueRoute;
  category: ValueRoute;
  seller: ValueRoute;
  products: ValueRoute;
  product: (productId: any) => string;
  stores: ValueRoute;
  store: (storeId: any) => string;
  storeDelivery: ValueRoute;
  storeAbout: ValueRoute;
  storePolicies: ValueRoute;
  cart: ValueRoute;
};

const routesPublic = {
  root: () => `/`,
  category: () => `/category/`,
  seller: () => `/seller/`,
  products: () => `/products/`,
  product: (productId: any) => `/products/${productId}/`,
  stores: () => `/stores/`,
  store: (storeId: any) => `/stores/${storeId}/`,
  storeDelivery: (storeId: any) => `/stores/${storeId}/delivery/`,
  storeAbout: (storeId: any) => `/stores/${storeId}/about/`,
  storePolicies: (storeId: any) => `/stores/${storeId}/policies/`,
  cart: () => `/cart/`,
} as TypeRoutes;

export default routesPublic;
