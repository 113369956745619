import useIsSSR from "./useIsSSR";
import useRouter from "./useRouter";

const useLocation = () => {
  const isSSR = useIsSSR();
  const router = useRouter();

  return isSSR
    ? {
        pathname: router.asPath,
        search: 'not implemented'
      }
    : window.location;
};

export default useLocation;
