// @ts-nocheck
import Waiting from "@/components/basic/Waiting";
import dynamic from "next/dynamic";
import { useMemo } from "react";
import { ArgsOpenModal, UseInterface } from "types/modules/interface-types";
import { KEY_NAME_ONBOARDING } from "../constants/common";

const ListAddresses = dynamic(() => import("../components/ListAddresses"), {
  ssr: false,
  suspense: false,
});

const FormAddress = dynamic(() => import("../form/FormAddress"), {
  ssr: false,
  suspense: false,
});

const FormProfile = dynamic(() => import("../form/FormProfile"), {
  ssr: false,
  suspense: false,
});

const PreferencesProfile = dynamic(
  () => import("../modals/PreferencesProfile"),
  {
    ssr: false,
    // suspense: false,
  }
);

const PreferencesNotifications = dynamic(
  () => import("../modals/PreferencesNotifications"),
  {
    ssr: false,
    // suspense: false,
  }
);

const PreferencesCategories = dynamic(
  () => import("../modals/PreferencesCategories"),
  {
    ssr: false,
    // suspense: false,
  }
);

const PreferencesProductsTags = dynamic(
  () => import("../modals/PreferencesProductsTags"),
  {
    ssr: false,
    // suspense: false,
  }
);

const PreferencesStores = dynamic(() => import("../modals/PreferencesStores"), {
  ssr: false,
  // suspense: false,
});

const PreferencesStoresTags = dynamic(
  () => import("../modals/PreferencesStoresTags"),
  {
    ssr: false,
    // suspense: false,
  }
);

const PreferencesLocations = dynamic(
  () => import("../modals/PreferencesLocations"),
  {
    ssr: false,
    // suspense: false,
  }
);

const normalizeInitAddress = ({ id, country, province, ...rest }: any) => ({
  ...rest,
  country: country ? country.code : null,
  province: province ? province.id : null,
});

const normalizeInitProfile = ({ ...rest }: any) => ({
  ...rest,
});

const useActions = ({ openModal, closeModal, translate }: UseInterface) => {
  return useMemo(() => {
    return {
      openAddress: (props) => {
        openModal(
          (helpers) => {
            return (
              <Waiting>
                <FormAddress
                  {...props}
                  initValues={
                    props.initValues
                      ? normalizeInitAddress(props.initValues)
                      : undefined
                  }
                  onSuccess={() => {
                    helpers.backModal();
                    props?.onSuccess();
                  }}
                />
              </Waiting>
            );
          },
          {
            title: props.addressId
              ? translate("Edit address")
              : translate("Add address"),
          }
        );
      },
      openProfile: (props) => {
        openModal(
          (helpers) => {
            return (
              <Waiting>
                <FormProfile
                  {...props}
                  initValues={
                    props.initValues
                      ? normalizeInitProfile(props.initValues)
                      : undefined
                  }
                  onSuccess={() => {
                    helpers.closeModal();
                    props?.onSuccess();
                  }}
                />
              </Waiting>
            );
          },
          {
            title: translate("Edit profile"),
          }
        );
      },
      openListAddresses: (props) => {
        openModal(
          (helpers) => {
            return (
              <Waiting>
                <ListAddresses
                  {...props}
                  onSelect={(addressId: any) => {
                    helpers.backModal();
                    props?.onSelect(addressId);
                  }}
                />
              </Waiting>
            );
          },
          {
            title: translate("Addresses"),
          }
        );
      },
      openOnboarding: (props) => {
        openModal(
          (helpers) => {
            localStorage?.setItem(KEY_NAME_ONBOARDING, "1");

            return (
              // <Waiting>
              <PreferencesProfile
                {...props}
                nextActions={[
                  "openPreferencesNotifications",
                  "openPreferencesProductsTags",
                ]}
                onboarding
              />
              // </Waiting>
            );
          },
          {
            closeAllPrevious: true,
            breakOpen: !!localStorage?.getItem(KEY_NAME_ONBOARDING),
          }
        );
      },
      openPreferencesProfile: (props) => {
        openModal((helpers) => {
          return (
            // <Waiting>
            <PreferencesProfile {...props} />
            // </Waiting>
          );
        });
      },
      openPreferencesNotifications: (props) => {
        openModal((helpers) => {
          return (
            // <Waiting>
            <PreferencesNotifications {...props} />
            // </Waiting>
          );
        });
      },
      openPreferencesCategories: (props) => {
        openModal((helpers) => {
          return (
            // <Waiting>
            <PreferencesCategories {...props} />
            // </Waiting>
          );
        });
      },
      openPreferencesProductsTags: (props) => {
        openModal((helpers) => {
          return (
            // <Waiting>
            <PreferencesProductsTags {...props} />
            // </Waiting>
          );
        });
      },
      openPreferencesStoresTags: (props) => {
        openModal((helpers) => {
          return (
            // <Waiting>
            <PreferencesStoresTags {...props} />
            // </Waiting>
          );
        });
      },
      openPreferencesStores: (props) => {
        openModal((helpers) => {
          return (
            // <Waiting>
            <PreferencesStores {...props} />
            // </Waiting>
          );
        });
      },
      openPreferencesLocations: (props) => {
        openModal((helpers) => {
          return (
            // <Waiting>
            <PreferencesLocations {...props} />
            // </Waiting>
          );
        });
      },
    };
  }, [openModal, translate]);
};

export default useActions;
