const LocalStorage = global.localStorage ? localStorage : null;
const SessionStorage = global.sessionStorage ? sessionStorage : null;

const getToken = (key: string) => {
  const localAuth = LocalStorage?.getItem(key);

  if (localAuth && localAuth !== "undefined") {
    return localAuth;
  }

  const sessionAuth = SessionStorage?.getItem(key);

  if (sessionAuth && sessionAuth !== "undefined") {
    return sessionAuth;
  }

  return "";
};

const isSaveInSession = () => {
  const sessionAuth = SessionStorage?.getItem("auth");

  if (sessionAuth && sessionAuth !== "undefined") {
    return true;
  }

  return false;
};

const setToken = (key: string, value: string) => {
  try {
    if (isSaveInSession()) {
      SessionStorage?.setItem(key, value);
    } else {
      LocalStorage?.setItem(key, value);
    }
  } catch (err) {
    console.error("setToken", err, key, value);
  }
};

const getTokenAuth = () => getToken("auth");
const setTokenAuth = (val: string) => setToken("auth", val);

const getRefreshToken = () => getToken("auth-refresh");

const getTokenAnonymous = () => getToken("anonymous");
const saveAnonymousToken = (value: string) => setToken("anonymous", value);

const getBearerToken = () => {
  const token = getTokenAuth();
  return token ? `Bearer ${token}` : "";
};

const getCSRFToken = () => {
  const token = getTokenAuth();
  return token || "";
};

const erase = () => {
  LocalStorage?.removeItem("auth");
  LocalStorage?.removeItem("auth-refresh");
  SessionStorage?.removeItem("auth");
  SessionStorage?.removeItem("auth-refresh");
};

const set = (
  { token, tokenRefresh }: { token: string; tokenRefresh?: string },
  remember?: boolean
) => {
  erase();

  if (remember) {
    token && LocalStorage?.setItem("auth", token);
    tokenRefresh && LocalStorage?.setItem("auth-refresh", tokenRefresh);
  } else {
    token && SessionStorage?.setItem("auth", token);
    tokenRefresh && SessionStorage?.setItem("auth-refresh", tokenRefresh);
  }
};

const tokenStorage = {
  isSaveInSession,
  getTokenAuth,
  setTokenAuth,
  getRefreshToken,
  getTokenAnonymous,
  saveAnonymousToken,
  getBearerToken,
  getCSRFToken,
  set,
  erase,
};

export default tokenStorage;
