import { UseInterface } from "types/modules/interface-types";
import { SellerInterface } from "./interface-types";

import useActions from "./hooks/useActions";
import routesSeller, { baseRoute } from "./constants/routesSeller";
import { useMemo } from "react";

export const moduleName = "seller";

export const defaultValue: SellerInterface = {
  moduleName,
  baseRoute,
  routes: routesSeller,
  actions: {
    openSeller: () => {},
    openNewCampaign: (args: {
      productId: any;
      campaignId?: any;
      onSuccess?: () => void;
    }) => {},
  },
};

export const useInterface = (args: UseInterface): SellerInterface => {
  const actions = useActions(args);

  // @ts-ignore
  return useMemo(
    () => ({
      moduleName,
      baseRoute,
      routes: routesSeller,
      actions,
    }),
    [actions]
  );
};

export default useInterface;
