import { useMemo } from "react";
import dynamic from "next/dynamic";

import { PropsGlobalProvider } from "./context-types";
import { dataProviderCommon, dataProviderSeller } from "services/crud";
import { useInterface as useAuthInterface } from "@/modules/auth/interface";
import { useInterface as useHomeInterface } from "@/modules/home/interface";
import { useInterface as useCartInterface } from "@/modules/cart/interface";
import { useInterface as useProfileInterface } from "@/modules/profile/interface";
import { useInterface as usePublicInterface } from "@/modules/public/interface";
import { useInterface as useSellerInterface } from "@/modules/seller/interface";
import { useInterface as useGalleryInterface } from "@/modules/gallery/interface";

import useModal from "@/libs/modal/useModal";
import useUser from "@/hooks/useUser";
import useSession from "@/hooks/useSession";
import useToasts from "@/libs/toasts/useToasts";
import useTranslate from "@/hooks/useTranslate";
import useLanguages from "@/hooks/useLanguages";
import useCurrency from "@/hooks/useCurrency";

import { globalContext as Context } from "./GlobalContext";

import useConfirm from "@/libs/modal/confirm/useConfirm";

const ToastContainer = dynamic(() => import("@/libs/toasts/ToastContainer"), {
  ssr: false,
});

/*
  This place for Dependency Injections of modules
*/

const providers = [dataProviderCommon, dataProviderSeller];

const GlobalProvider = ({ children, pageProps }: PropsGlobalProvider) => {
  const isMobileSize = pageProps?.device?.type === "mobile";
  // TODO not implemented
  const isIOS = false;
  const isMediumHeight = false;

  const locale = pageProps.locale;

  const {
    isReady,
    isAuthorized,
    isSeller,
    setAuthToken,
    eraseAuthToken,
    SWRConfig,
  } = useUser(providers, locale);

  const { modals, open, back, close, isOpen } = useModal({ isIOS });
  const confirm = useConfirm({ open, close, back });
  const toast = useToasts();

  // Initialize session for use cart of anonymous user
  useSession();

  const languages = useLanguages(pageProps);
  const translate = useTranslate(pageProps);
  const currency = useCurrency();

  const propsInterface = useMemo(
    () => ({
      translate,
      isAuthorized,
      isReady,
      isSeller,
      eraseAuthToken,
      toast,
      openModal: open,
      backModal: back,
      closeModal: close,
      openConfirm: confirm,
    }),
    [
      back,
      close,
      confirm,
      eraseAuthToken,
      isAuthorized,
      isReady,
      isSeller,
      open,
      toast,
      translate,
    ]
  );

  // import interface of modules
  const auth = useAuthInterface(propsInterface);
  const cart = useCartInterface(propsInterface);
  const profile = useProfileInterface(propsInterface);
  const publicModule = usePublicInterface(propsInterface);
  const seller = useSellerInterface(propsInterface);
  const gallery = useGalleryInterface(propsInterface);
  const home = useHomeInterface(propsInterface);

  const value = useMemo(() => {
    return {
      pageProps,
      setAuthToken,
      isReady,
      isAuthorized,
      isSeller,
      isMobileSize,
      isIOS,
      isMediumHeight,
      locale,
      translate,
      toast,
      modules: {
        auth,
        home,
        cart,
        profile,
        public: publicModule,
        seller,
        gallery,
      },
      languages,
      currency,
      openModal: open,
      backModal: back,
      closeModal: close,
      openConfirm: confirm,
      providers: {
        common: dataProviderCommon.instance,
        seller: dataProviderSeller.instance,
      }
    };
  }, [
    pageProps,
    setAuthToken,
    isReady,
    isAuthorized,
    isSeller,
    isMobileSize,
    isIOS,
    isMediumHeight,
    locale,
    translate,
    toast,
    auth,
    home,
    cart,
    profile,
    publicModule,
    seller,
    gallery,
    languages,
    currency,
    open,
    back,
    close,
    confirm,
  ]);

  return (
    <SWRConfig>
      <Context.Provider value={value}>
        <>
          {children}
          {modals}
          <ToastContainer />
        </>
      </Context.Provider>
    </SWRConfig>
  );
};

export default GlobalProvider;
