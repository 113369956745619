import React, { useMemo } from "react";
import cn from "@/libs/cn";

import useGlobalContext from "@/context/GlobalContext";
import Link from "@/components/basic/Link";
import dataProfileMenu from "../../constants/dataProfileMenu";

import styles from "./styles.module.scss";
import InlineIcons from "@/components/basic/InlineIcons";

type typeProps = {
  className?: string;
};

const SidePanel = ({ className }: typeProps) => {
  const {
    translate,
    modules: { auth },
  } = useGlobalContext();

  const tabs = useMemo(() => dataProfileMenu(translate), [translate]);

  const menu = useMemo(
    () =>
      Object.values(tabs)
        .filter((item: any) => !item.hiddenInMenu)
        .sort((a: any, b: any) => (a.sort >= b.sort ? -1 : 1)),
    [tabs]
  );

  return (
    <nav className={cn(styles.SidePanel, className)} aria-hidden="true">
      <div className={styles.menu}>
        <Link
          key="user"
          href={tabs.profile.route}
          classNameActive={styles.active}
        >
          <div className={styles.item}>
            <InlineIcons.User width={18} height={18} />
            <div>{translate("Account details")}</div>
          </div>
        </Link>
        {menu.map((item: any) => {
          const Icon = item.icon;

          return (
            <Link
              key={item.route}
              href={item.route}
              classNameActive={styles.active}
            >
              <div className={styles.item}>
                {Icon && <Icon width={18} height={18} />}
                <div>{item.title}</div>
              </div>
            </Link>
          );
        })}
        <div
          className={cn(styles.item, styles.logout)}
          onClick={() => auth.actions.logout()}
        >
          <InlineIcons.Logout width={18} height={18} />
          <div>{translate("Log out")}</div>
        </div>
      </div>
    </nav>
  );
};

export default SidePanel;
