// TODO make to loadable icons by url from the public page
import cn from "@/libs/cn";
import Icon from "./Icon";

import styles from "./styles.module.scss";

export { default as AuthSignIn } from "./svg/auth-sign-in.svg";
export { default as AuthSignUp } from "./svg/auth-sign-up.svg";
export { default as Securing } from "./svg/securing.svg";
export { default as ArrowBottom } from "./svg/arrow-bottom.svg";
export { default as ArrowDown } from "./svg/arrow-down.svg";
export { default as ArrowUp } from "./svg/arrow-up.svg";
export { default as ArrowRight } from "./svg/arrow-right.svg";
export { default as ArrowShortLeft } from "./svg/arrow-short-left.svg";
export { default as ArrowShortRight } from "./svg/arrow-short-right.svg";
export { default as Ask } from "./svg/ask.svg";
export { default as Blurry } from "./svg/blurry.svg";
export { default as Bus } from "./svg/bus.svg";
export { default as Beep } from "./svg/beep.svg";
export { default as Bell } from "./svg/bell.svg";
export { default as Camera } from "./svg/camera.svg";
export { default as CaretDown } from "./svg/caret-down.svg";
export { default as Cart } from "./svg/cart.svg";
export { default as CheckWhite } from "./svg/check-white.svg";
export { default as Check } from "./svg/check.svg";
export { default as CopyLink } from "./svg/copy-link.svg";
export { default as Copy } from "./svg/copy.svg";
export { default as Currency } from "./svg/currency.svg";
export { default as Dashboard } from "./svg/dashboard.svg";
export { default as Cubes } from "./svg/cubes.svg";
export { default as Filter } from "./svg/filter.svg";
export { default as Home } from "./svg/home.svg";
export { default as Hamburger } from "./svg/hamburger.svg";
export { default as Info } from "./svg/info.svg";
export { default as Image } from "./svg/image.svg";
export { default as Guard } from "./svg/guard.svg";
export { default as Search } from "./svg/search.svg";
export { default as MobileLogin } from "./svg/mobile-login.svg";
export { default as PlaceholderImage } from "./svg/placeholder-image.svg";
export { default as Unwatch } from "./svg/unwatch.svg";
export { default as User } from "./svg/user.svg";
export { default as Watch } from "./svg/watch.svg";
export { default as LogoShop } from "./svg/logo-shop.svg";
export { default as Logout } from "./svg/logout.svg";
export { default as ListUi } from "./svg/list-ui.svg";
export { default as LabelCartApplePay } from "./svg/label-cart-apple-pay.svg";
export { default as LabelCartMaster } from "./svg/label-cart-master.svg";
export { default as LabelCartVisa } from "./svg/label-cart-visa.svg";
export { default as Minus } from "./svg/minus.svg";
export { default as Menu } from "./svg/menu.svg";
export { default as MenuVertical } from "./svg/menu-vertical.svg";
export { default as Plus } from "./svg/plus.svg";
export { default as Products } from "./svg/products.svg";
export { default as Play } from "./svg/play.svg";
export { default as AddPlus } from "./svg/add-plus.svg";
export { default as Refresh } from "./svg/refresh.svg";
export { default as Reviews } from "./svg/reviews.svg";
export { default as Map } from "./svg/map.svg";
export { default as CreditCard } from "./svg/credit-card.svg";
export { default as Edit } from "./svg/edit.svg";
export { default as Star } from "./svg/star.svg";
export { default as StarFill } from "./svg/star-fill.svg";
export { default as StarNoFill } from "./svg/star-no-fill.svg";
export { default as StarFilled } from "./svg/star-filled.svg";
export { default as Start } from "./svg/start.svg";
export { default as Stop } from "./svg/stop.svg";
export { default as SaleImage } from "./svg/sale-image.svg";
export { default as SocialNetwork } from "./svg/social-network.svg";
export { default as SocialFacebook } from "./svg/social/facebook.svg";
export { default as SocialInstagram } from "./svg/social/instagram.svg";
export { default as SocialTwitter } from "./svg/social/twitter.svg";
export { default as Apple } from "./svg/social/apple.svg";
export { default as Playmarket } from "./svg/social/playmarket.svg";
export { default as Upload } from "./svg/upload.svg";
export { default as Comment } from "./svg/comment.svg";
export { default as Trash } from "./svg/trash.svg";
export { default as Exit } from "./svg/exit.svg";
export { default as Help } from "./svg/help.svg";
export { default as Location } from "./svg/location.svg";
export { default as Settings } from "./svg/settings.svg";
export { default as Terms } from "./svg/terms.svg";
export { default as Notifications } from "./svg/notifications.svg";
export { default as NotificationsGift } from "./svg/notifications-gift.svg";
export { default as NotificationsCar } from "./svg/notifications-car.svg";
export { default as NotificationsCart } from "./svg/notifications-cart.svg";
export { default as NotificationsComment } from "./svg/notifications-comment.svg";
export { default as World } from "./svg/world.svg";
export { default as AttachFile } from "./svg/attach-file.svg";
export { default as Sent } from "./svg/sent.svg";
export { default as Picture } from "./svg/picture.svg";
export { default as Send } from "./svg/send.svg";
export { default as Feedback } from "./svg/feedback.svg";
export { default as Warranty } from "./svg/warranty.svg";
export { default as Attention } from "./svg/attention.svg";
export { default as Question } from "./svg/question.svg";
export { default as SettingsActive } from "./svg/settings-active.svg";
export { default as BluePoint } from "./svg/blue-point.svg";
export { default as SuccessPurchase } from "./svg/success-purchase.svg";
export { default as Orders } from "./svg/orders.svg";
export { default as Warning } from "./svg/warning.svg";
export { default as Like } from "./svg/like.svg";
export { default as InstagramLarge } from "./svg/social/instagram-large.svg";
export { default as FacebookLarge } from "./svg/social/facebook-large.svg";
export { default as AppleLarge } from "./svg/social/apple-large.svg";
export { default as GoogleLarge } from "./svg/social/google-large.svg";
export { default as CheckGrey } from "./svg/check-grey.svg";
export { default as WhiteClose } from "./svg/white-close.svg";
export { default as Email } from "./svg/email.svg";
export { default as Phone } from "./svg/phone.svg";
export { default as PaymentMethod } from "./svg/payment-method.svg";
export { default as Completed } from "./svg/completed.svg";
export { default as Process } from "./svg/process.svg";
export { default as NotStarted } from "./svg/not-started.svg";
export { default as VerticalLine } from "./svg/vertical-line.svg";
export { default as CompletedVerticalLine } from "./svg/completed-vertical-line.svg";
export { default as ArrowShortLeftBlue } from "./svg/arrow-short-left-blue.svg";
export { default as Gift } from "./svg/gift.svg";

import SVGShare from "./svg/share.svg";
import SVGClose from "./svg/close.svg";
import SVGClose2 from "./svg/close2.svg";
import SVGHeart from "./svg/heart.svg";

export const Close = (props: any) => (
  <Icon {...props} component={SVGClose} size={props.size || 20} />
);
export const Close2 = (props: any) => (
  <Icon {...props} component={SVGClose2} size={props.size || 12} />
);
export const Heart = ({ className, active, style, ...rest }: any) => (
  <SVGHeart
    // className={cn(styles.heart, className)}
    // active={active}
    // style={style}
    {...rest}
  />
);
export const HeartFill = () => <SVGHeart className={styles.heartFill} />;
export const HeartBlack = ({ className, ...rest }: any) => (
  <SVGHeart className={cn(styles.heartBlack, className)} {...rest} />
);
// export const Image;
// export const Map,
export const Share = () => <SVGShare className={styles.share} />;
// export const Comment,
// export const Location,
