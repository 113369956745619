import React, { useCallback, useMemo, useState } from "react";
import cn from "@/libs/cn";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";

export interface IDateInput {
  type?: "date" | "datetime-local" | "month";
  className?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  min?: string;
  max?: string;
  placeholderClassName?: string;
  isIOS?: boolean;
}

const DateInput = ({
  type = "date",
  className,
  label,
  value,
  placeholder,
  onChange = (e: any) => {},
  onBlur: onBlurOut = (e: any) => {},
  disabled,
  min,
  max = "",
  placeholderClassName,
  isIOS,
  ...rest
}: IDateInput) => {
  const [isFocus, setFocus] = useState(false);

  const handleChange = useCallback(
    (e: any) => {
      let newValue = e.target.value;
      if (max) {
        const v = newValue.split("-");
        const m = max.split("-");
        if (parseInt(v[0], 10) > parseInt(m[0], 10)) {
          newValue = `${m[0]}-${v[1]}-${v[2]}`;
        }

        e.target.value = newValue;
      }
      onChange(e);
    },
    [max, onChange]
  );

  const onFocus = useCallback((e: any) => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(
    (e: any) => {
      onBlurOut(e);
      setFocus(false);
    },
    [onBlurOut]
  );

  const Wrap = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      ({ children }: any) =>
        isIOS ? (
          <div className={styles.wrapInput}>{children}</div>
        ) : (
          <>{children}</>
        ),
    [isIOS]
  );

  return (
    <div
      className={cn(stylesForms.basicWrapInput, styles.dateInput, className)}
      onClick={onFocus}
    >
      <Wrap>
        <input
          type={type}
          className={cn(
            isIOS && styles.ios,
            !value && !isFocus && styles.empty
          )}
          onBlur={onBlur}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          min={min}
          max={max}
          {...rest}
        />
      </Wrap>
      {!value && !isFocus && (
        <div className={cn(styles.placeholder, placeholderClassName)}>
          {placeholder}
        </div>
      )}
    </div>
  );
};

export default DateInput;
