import authValidation from "@/hooks/validation/authValidation";
import { IArgsValidation } from "@/hooks/validation/types";

export const schemaProfile = ({ translate }: IArgsValidation) => {
  const validation = authValidation({ translate });

  const scheme = {
    first_name: validation.first_name,
    last_name: validation.last_name,
    birthday: validation.birthday,
    email: validation.email_signin,
    phone: validation.phone,
    gender: validation.gender,
  };

  return scheme as any;
};
