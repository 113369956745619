import { useCommonSWR } from "@/libs/swr";
import apiProfile from "../constants/apiProfile";

export const useDataProfile = () => {
  return useCommonSWR({
    key: apiProfile.profile(),
  });
};

export const useDataOrdersAddresses = () => {
  return useCommonSWR({
    key: apiProfile.ordersAddresses(),
    defaultData: [],
  });
};

export const useDataOrdersAddress = (addressId: any) => {
  return useCommonSWR({
    key: apiProfile.ordersAddress(addressId),
  });
};

export const useDataListGenders = () => {
  return useCommonSWR({
    key: apiProfile.listGenders(),
    defaultData: [],
  });
};

export const useDataNotifications = (type?: string) => {
  return useCommonSWR({
    key: apiProfile.notifications(type),
    defaultData: [],
  });
};

const normalizeDataPreferences = (data?: {
  categories?: any[];
  notifications?: any[];
  products_tags?: any[];
  stores?: any[];
  stores_tags?: any[];
}) => {
  return !data
    ? data
    : {
        categories: data.categories || [],
        notifications: data.notifications || [],
        products_tags: data.products_tags || [],
        stores: data.stores || [],
        stores_tags: data.stores_tags || [],
      };
};

export const useDataPreferences = () => {
  return useCommonSWR({
    key: apiProfile.preferences(),
    normalizeData: normalizeDataPreferences,
  });
};

export const useDataNotificationChannels = () => {
  return useCommonSWR({
    key: apiProfile.notificationChannels(),
    defaultData: [],
  });
};
