import { UseInterface } from "types/modules/interface-types";
import { CartInterface } from "./interface-types";

import useActions from "./hooks/useActions";
import useCart from "./hooks/useCart";
import routesCart, { baseRoute } from "./constants/routesCart";

export const moduleName = "cart";

export const defaultValue: CartInterface = {
  moduleName,
  baseRoute,
  quantity: 0,
  discount: 0,
  total: 0,
  vatSum: 0,
  formattedValue: "0.00",
  list: [],
  isCartAddProcessing: false,
  isCartDeleteProcessing: false,
  isCartUpdateProcessing: false,
  isCartCommonProcessing: false,
  addProductToCart: (data, quantity) => {},
  deleteProductFromCart: (cartId) => {},
  updateProductInCart: (cartId, quantity) => {},
  routes: routesCart,
  actions: {
    openAddNewAddress: () => {},
    openApplyPromocode: () => {},
    onCloseChooseAddress: () => {},
    openChooseAddressHandler: () => {},
    openChoosePaymentMethod: () => {},
    onCloseChoosePaymentMethod: () => {},
    openConfirmHandler: () => {},
    openAddressHandler: () => {},
    openCreateOrderHandler: () => {},
  },
};

export const useInterface = (args: UseInterface): CartInterface => {
  const { isReady, isAuthorized, isSeller, toast, translate } = args;
  const data = useCart({ isReady, isAuthorized, isSeller, toast, translate });
  const actions = useActions(args);

  return { ...data, moduleName, baseRoute, routes: routesCart, actions };
};

export default useInterface;
