import { useEffect } from "react";
import apiNextSide from "@/constants/apiNextSide";
import { crudFetcherNextApi } from "services/crud";

const useSession = () => {
  useEffect(() => {
    crudFetcherNextApi.get({
      url: apiNextSide.initSession(),
    });
  }, []);
};

export default useSession;
