import { UseInterface } from "types/modules/interface-types";
import { PublicInterface } from "./interface-types";

import useActions from "./hooks/useActions";
import * as data from "./hooks/useData";
import routesAuth, { baseRoute } from "./constants/routesPublic";
import { useMemo } from "react";
import routesResponse from "./constants/routesPublic";

export const moduleName = "public";

export const defaultValue: PublicInterface = {
  moduleName,
  baseRoute,
  routes: routesAuth,
  actions: {
    openPublic: () => {},
  },
  data,
};

export const useInterface = (args: UseInterface): PublicInterface => {
  const actions = useActions(args);

  return useMemo(
    () => ({
      moduleName,
      baseRoute,
      routes: routesResponse,
      actions,
      data,
    }),
    [actions]
  );
};

export default useInterface;
