import useGlobalContext from "@/context/GlobalContext";
import { useDataOrders } from "@/modules/cart/hooks/useData";
import FilterDropDown from "@/modules/home/components/variant-one/FilterDropDown";
import CardOrder from "../components/CardOrder";
import useProfileOrdersFilter, {
  useDataByTimeOrders,
} from "../hooks/filters/useProfileOrdersFilter";

import styles from "./styles.module.scss";

const PageOrders = () => {
  const { translate } = useGlobalContext();
  const filterStr = useProfileOrdersFilter();
  const { data } = useDataOrders(filterStr);
  const dataTime = useDataByTimeOrders();
  return (
    <div>
      <div className={styles.wrapByTime}>
        <FilterDropDown
          label={translate("By time")}
          className={styles.sortby}
          data={dataTime}
          defaultIndex={0}
          paramName="date_range"
          makeSorting={false}
          hideUnselect
        />
      </div>
      {data.map((item: any) => (
        <CardOrder key={item.id} {...item} />
      ))}
    </div>
  );
};

export default PageOrders;
