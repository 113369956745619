import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "@/libs/cn";
import {
  useDataPublicCategories,
  useDataPublicProductTags,
  useDataPublicStoreTags,
} from "@/modules/public/hooks/useData";
import { getIds, idsToNumber, idsToString } from "@/utils/data";
import TagsCloud from "@/components/TagsCloud";
import TreeView from "@/controls/TreeView";
import useGlobalContext from "@/context/GlobalContext";
import Button from "@/controls/Button";
import LoaderCircle from "@/controls/LoaderCircle";
import Checkbox from "@/controls/Checkbox";
import {
  useDataNotificationChannels,
  useDataPreferences,
} from "../hooks/useData";
import profileRequests from "../requests";
import { IChannel } from "./PreferencesNotifications";

import styles from "./styles.module.scss";
import TextInput from "@/controls/TextInput";
import CustomScroll from "@/components/CustomScroll";
import PublicIcons from "@/components/basic/PublicIcon";

interface IPreferences {
  categories: any[];
  notifications: any[];
  products_tags: any[];
  stores: any[];
  stores_tags: any[];
}

const PreferencesAll = () => {
  const { translate, toast } = useGlobalContext();
  const { data: channels } = useDataNotificationChannels() as {
    data: IChannel[];
  };
  const { data: dataPreferences, mutate } = useDataPreferences();
  const { data: dataProductTags } = useDataPublicProductTags();
  const { data: dataStoreTags } = useDataPublicStoreTags();
  const { data: dataApi } = useDataPublicCategories();
  const [preferences, setPreferences] = useState<IPreferences>();
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");

  const transformerApiData = useCallback(
    (item: any) => ({ ...item, value: item.id, label: item.name }),
    []
  );

  const treeData = useMemo(
    () => (dataApi ? dataApi.map(transformerApiData) : []),
    [dataApi, transformerApiData]
  );

  useEffect(() => {
    if (!preferences && dataPreferences) {
      setPreferences({
        categories: getIds(dataPreferences?.categories, true),
        notifications: getIds(dataPreferences?.notifications, true),
        products_tags: getIds(dataPreferences?.products_tags, true),
        stores: getIds(dataPreferences?.stores, true),
        stores_tags: getIds(dataPreferences?.stores_tags, true),
      });
    }
  }, [dataPreferences, preferences]);

  const onToggleChannel = useCallback(
    (id: any) => {
      const selected = idsToString(preferences?.notifications);

      const isExist = selected.includes(`${id}`);
      let newSelected = [];
      if (isExist) {
        newSelected = selected.filter((i) => i != id);
      } else {
        newSelected = [...selected, `${id}`];
      }

      // @ts-ignore
      setPreferences({ ...preferences, notifications: newSelected });
    },
    [preferences]
  );

  const onSelect = useCallback(
    (field: string, val: any[]) => {
      // @ts-ignore
      setPreferences({ ...preferences, [field]: idsToString(val) });
    },
    [preferences]
  );

  const onSelectCategories = useCallback(
    (val: any[]) => {
      // @ts-ignore
      setPreferences({ ...preferences, categories: val });
    },
    [preferences]
  );

  const onSelectCategory = useCallback(
    (categoryId: any, checked: boolean) => {
      let categories = preferences?.categories;
      if (checked) {
        categories?.push(categoryId);
      } else {
        categories = categories?.filter((item) => item.value == categoryId);
      }
      // @ts-ignore
      setPreferences({ ...preferences, categories });
    },
    [preferences]
  );

  const onSelectCategoriesAll = useCallback(
    (select: boolean) => {
      setSearchStr("");

      if (select) {
        const rootsSelected = treeData
          .filter((item: any) => !item.parent)
          .map((item: any) => `${item.id}`);
        // @ts-ignore
        setPreferences({ ...preferences, categories: rootsSelected });
      } else {
        // @ts-ignore
        setPreferences({ ...preferences, categories: [] });
      }
    },
    [preferences, treeData]
  );

  const onConfirm = useCallback(async () => {
    setLoading(true);

    const data = {
      categories: idsToNumber(preferences?.categories),
      notifications: idsToNumber(preferences?.notifications),
      products_tags: idsToNumber(preferences?.products_tags),
      stores: idsToNumber(preferences?.stores),
      stores_tags: idsToNumber(preferences?.stores_tags),
    };

    await profileRequests
      .updatePreferences(data)
      .then(() => {
        mutate();
      })
      .catch((err) => toast.error("Server error"));

    setLoading(false);
  }, [mutate, preferences, toast]);

  const listSearch = useMemo(
    () =>
      searchStr
        ? treeData.filter(
            (item: any) =>
              `${item.label}`.toLowerCase().indexOf(searchStr.toLowerCase()) !==
              -1
          )
        : [],
    [searchStr, treeData]
  );

  return preferences ? (
    <div className={styles.PreferencesAll}>
      <div className={styles.section}>
        {translate("Notifications methods")}:
      </div>
      <div className={styles.notifications}>
        {channels.map((item) => (
          <div key={item.id} className={styles.item}>
            <Checkbox
              onChange={() => onToggleChannel(item.id)}
              label={item.title}
              checked={preferences?.notifications.includes(`${item.id}`)}
              circle
            />
          </div>
        ))}
      </div>
      <div className={styles.section}>{translate("Dietary preferences")}:</div>
      <div className={styles.list}>
        <TagsCloud
          data={dataProductTags}
          selected={preferences?.products_tags}
          onSelected={(val: any[]) => onSelect("products_tags", val)}
          classNameItem={styles.item}
        />
      </div>
      <div className={styles.section}>{translate("Seller preferences")}:</div>
      <div className={styles.list}>
        <TagsCloud
          data={dataStoreTags}
          selected={preferences?.stores_tags}
          onSelected={(val: any[]) => onSelect("stores_tags", val)}
          classNameItem={styles.item}
        />
      </div>
      <div className={cn(styles.section, styles.categories)}>
        <div>{translate("Categories")}:</div>
        <div className={styles.buttons}>
          <div
            className={styles.select}
            onClick={() => onSelectCategoriesAll(true)}
          >
            {translate("Select all")}
          </div>
          <div
            className={styles.unselect}
            onClick={() => onSelectCategoriesAll(false)}
          >
            {translate("Unselect all")}
          </div>
        </div>
      </div>
      <div>
        <TextInput
          className={styles.input}
          placeholder={translate("Category name")}
          onChange={(e: any) => setSearchStr(e.target.value)}
          value={searchStr}
          icon={<PublicIcons src={PublicIcons.names.search} />}
        />
        {searchStr ? (
          <CustomScroll
            className={styles.searchList}
            minHeight={200}
            maxHeight={200}
          >
            {listSearch.map((item: any) => {
              const isChecked = preferences?.categories.includes(
                `${item.value}`
              );
              return (
                <div key={item.value} className={styles.item}>
                  <Checkbox
                    label={item.label}
                    checked={isChecked}
                    isCompact
                    onChange={() =>
                      onSelectCategory(`${item.value}`, !isChecked)
                    }
                  />
                </div>
              );
            })}
          </CustomScroll>
        ) : (
          <TreeView
            className={styles.tree}
            classNameScrollContent={styles.treeContent}
            data={treeData}
            value={preferences?.categories}
            selected={preferences?.categories}
            multiple
            onSelect={onSelectCategories}
            onlyNodal
            minHeight={200}
            maxHeight={200}
          />
        )}
      </div>
      <Button
        onClick={onConfirm}
        color="primary"
        loading={loading}
        className={styles.button}
      >
        {translate("Save changes")}
      </Button>
    </div>
  ) : (
    <LoaderCircle stretchToSpace />
  );
};

export default PreferencesAll;
