import { APP_API } from "@/constants/common";

const API_CART = `${APP_API}/cart/`;
const API_STRIPE = `/payments/stripe/`;
const API_CART_TEMP = `${APP_API}mock-cart/`;

const API_ADDRESSES = `${APP_API}/addresses/`;

const apiCart = {
  cartPurchases: () => `${API_CART}`,
  paymentsClientSecret: () => `${API_STRIPE}intents/setup-intent/`,
  attatchPaymentMethod: () => `${API_STRIPE}intents/attach-payment-method/`,
  paymentsDetachMethod: (paymentMethodId: any) =>
    `${API_STRIPE}payment-methods/${paymentMethodId}/`,
  paymentsCreateIntent: () => `${API_STRIPE}payment-intent/`,
  createCheckoutSession: () => `${API_STRIPE}create-checkout-session/`,
  paymentsMethods: () => `${API_STRIPE}payment-methods/`,

  cartAddProduct: () => `${API_CART}add_to_cart/`,
  cartSetProductQuantity: (purchaseId: string | number) =>
    `${API_CART}${purchaseId}/`,
  cartRemoveProduct: () => `${API_CART}remove_from_cart/`,

  createOrder: () => `/orders/create_orders/`,
  order: (orderId: any) => `/orders/${orderId}/`,

  getAddresses: () => `${API_ADDRESSES}`,
  getAddress: (addressId: string | number) => `${API_ADDRESSES}${addressId}/`,
  createAddress: () => `${API_ADDRESSES}`,
  updateAddress: (addressId: string | number) =>
    `${API_ADDRESSES}${addressId}/`,
  deleteAddress: (addressId: string | number) =>
    `${API_ADDRESSES}${addressId}/`,
  sendQuestion: () => `${APP_API}/questions/`,
  sendQuestionByOrder: () => `${APP_API}/order-issues/`,
  sendWarrantyCase: () => `${APP_API}/warranties/`,
};

export default apiCart;
