import { useCallback } from "react";

interface ArgsTranslate {
  dictionary: { [key: string]: string };
}

const useTranslate = (args: ArgsTranslate) => {
  const { dictionary } = args;

  const translate = useCallback(
    (text: string) =>
      dictionary && dictionary[text] ? dictionary[text] : text,
    [dictionary]
  );

  return translate;
};

export default useTranslate;
