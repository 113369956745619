import { useMemo } from "react";

import { useDataPublicCategories } from "./useData";

export const slugByName = (name = "") => {
  try {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "-")
      .replace(/-{2,}/g, "-");
  } catch (err) {
    console.log("Error slugByName", name, err);
    return "";
  }
};

export const slugByCategoryId = (categories: any, categoryId: any) => {
  if (!categories) {
    return "";
  }

  const find = categories.find((item: any) => item.id == categoryId);

  if (!find) {
    return "";
  }

  return slugByName(find.name);
};

export const useSlugByCategory = (categoryId: any) => {
  const { data, error } = useDataPublicCategories();

  const slug = useMemo(() => {
    if (error) {
      return "";
    }

    return slugByCategoryId(data, categoryId);
  }, [categoryId, data, error]);

  return slug;
};

export const categoryBySlug = (categories?: any[], slug?: string) => {
  if (!categories) {
    return null;
  }

  const find = categories.find((item) => {
    const tSlug = slugByName(item.name);
    return tSlug === slug;
  });

  if (!find) {
    return null;
  }

  return find;
};

export const useCategoryBySlug = (slug: any) => {
  const { data, error } = useDataPublicCategories();

  const category = useMemo(() => {
    if (error) {
      return null;
    }

    return categoryBySlug(data, slug);
  }, [data, error, slug]);

  return category;
};

const getParentsData: any = (
  categories?: any[],
  parentId?: any,
  arr: any[] = []
) => {
  const find = categories?.find((item) => item.id == parentId);

  if (find) {
    arr.push(find);

    if (find.parent == null) {
      return arr;
    }

    return getParentsData(categories, find.parent, arr);
  }

  return arr;
};

const getParents = (categories: any[], parentId: any, arr: any[] = []) => {
  return getParentsData(categories, parentId, arr).map((item: any) =>
    slugByName(item.name)
  );
};

export const chainByCategoryId = (categories?: any[], categoryId?: any) => {
  if (!categories) {
    return [];
  }

  const find = categories.find((item) => item.id == categoryId);

  if (!find) {
    return [];
  }

  return getParents(categories, categoryId).reverse();
};

export const useChainByCategoryId = (categoryId: any) => {
  const { data: categories, error } = useDataPublicCategories();

  const slugs = useMemo(() => {
    if (error) {
      return "";
    }

    return chainByCategoryId(categories, categoryId);
  }, [categoryId, categories, error]);

  return slugs;
};

export const chainBySlug = (categories?: any[], slug?: any) => {
  if (!categories) {
    return [];
  }

  const category = categoryBySlug(categories, slug);

  const find = categories.find((item) => item.id == category.id);

  if (!find) {
    return [];
  }

  return getParents(categories, category.id).reverse();
};

export const useChainBySlug = (slug: any) => {
  const { data, error } = useDataPublicCategories();

  if (error) {
    return [];
  }

  return chainBySlug(data, slug);
};

export const getBreadcrumbsData = (categories?: any[], slug?: any) => {
  const category = categoryBySlug(categories, slug);

  if (!category) {
    return [];
  }

  const data = getParentsData(categories, category.id).reverse();

  return data;
};
