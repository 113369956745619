// @ts-nocheck
import dynamic from "next/dynamic";
import { ArgsOpenModal, UseInterface } from "types/modules/interface-types";

// const AddNewPaymentMethod = dynamic(
//   () => import("../forms/AddNewPaymentMethod"),
//   // {
//   //   ssr: false,
//   //   suspense: false,
//   // }
// );

const useActions = ({ openModal }: UseInterface) => {
  return {
    openAddNewAddress: () =>
      openModal(({ closeModal }: ArgsOpenModal) => (
        <div>
          openAddNewAddress <input tabIndex={1} />
          <div onClick={() => closeModal()}>CLOSE</div>
        </div>
      )),
    openApplyPromocode: () => openModal(() => "openApplyPromocode"),
    onCloseChooseAddress: () => {},
    openChooseAddressHandler: () => {},
    openChoosePaymentMethod: () => {},
    onCloseChoosePaymentMethod: () => {},
    openConfirmHandler: () => {},
    openAddressHandler: () => {},
    openCreateOrderHandler: () => {},
  };
};

export default useActions;
