import React from "react";
import InlineIcons from "@/components/basic/InlineIcons";
import Button from "@/controls/Button";
import ButtonSubmit from "@/controls/ButtonSubmit";
import useGlobalContext from "@/context/GlobalContext";
import { calculatePrice } from "../../CardOrder";
import { IApiOrder } from "@/types/interfaces/orders";

import styles from "./styles.module.scss";


const Result = (props: IApiOrder) => {
  const { translate, currency, isMobileSize } = useGlobalContext();
  // const { onOpenQuestionByOrderHandler } = useContext(ModalsContext);
  const { shipping_sum, items_sum } = props;

  const total = calculatePrice(shipping_sum, items_sum);

  // const onRepeatOrder = useCallback(async () => {
  //   setIsLoading(true);
  //   await removeProduct(list);

  //   for (const item of order.items) {
  //     await addProduct(
  //       item.quantity,
  //       variants[item?.variant?.id]?.parent,
  //       item?.variant?.id
  //     );
  //   }

  //   replace(routes.cart);
  //   setIsLoading(false);
  // }, [removeProduct, list, replace, order.items, addProduct, variants]);

  return (
    <div className={styles.result}>
      <div className={styles.result_labelWrapper}>
        <div className={styles.result_shipping}>{translate("Shipping")}</div>
        <div className={styles.result_shippingStatus}>
          {shipping_sum
            ? `${currency.symbol}${shipping_sum}`
            : translate("Free")}
        </div>
      </div>
      <div className={styles.result_labelWrapper}>
        <div className={styles.result_total}>{translate("Total")}</div>
        <div
          className={styles.result_price}
        >{`${currency.symbol}${total}`}</div>
      </div>
      <ButtonSubmit
        className={styles.result_repeatOrder}
        width="100%"
        // loading={isLoading}
        // onClick={onRepeatOrder}
      >
        {translate("Repeat order")}
      </ButtonSubmit>
      {!isMobileSize && (
        <Button
          className={styles.result_questionByOrder}
          // onClick={() => onOpenQuestionByOrderHandler(order)}
        >
          <InlineIcons.Question />
          {translate("Question by order")}
        </Button>
      )}
    </div>
  );
};

export default Result;
