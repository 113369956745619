import React, { useMemo } from "react";
import cn from "@/libs/cn";
import styles from "./styles.module.scss";

interface LoaderCircle {
  classNameWrap?: string;
  className?: string;
  size?: number;
  stretchToSpace?: boolean;
  minHeight?: number | string;
  minWidth?: number | string;
  margin?: number | string;
  flex?: number;
  color?: string;
}

const LoaderCircle = ({
  classNameWrap,
  className,
  size = 60,
  stretchToSpace,
  minHeight,
  minWidth,
  margin,
  flex,
  color = "#3498db",
}: LoaderCircle) => {
  const Circle = useMemo(
    () => (
      <div
        className={cn(styles.circle, className)}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          margin,
          borderTopColor: color,
        }}
      />
    ),
    [className, color, margin, size]
  );
  return (
    <>
      {stretchToSpace ? (
        <div
          className={cn(styles.wrap, classNameWrap)}
          style={{ minHeight, minWidth, margin, flex }}
        >
          {Circle}
        </div>
      ) : (
        <>{Circle}</>
      )}
    </>
  );
};

export default LoaderCircle;
