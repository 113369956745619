import React from "react";
import cn from "@/libs/cn";

import OrderInfo from "./OrderInfo";
import Result from "./Result";
import OrderStatus from "./OrderStatus";
import Products from "./Products";

import styles from "./styles.module.scss";
import useGlobalContext from "@/context/GlobalContext";

import InlineIcons from "@/components/basic/InlineIcons";
import { IApiOrder } from "@/types/interfaces/orders";


const OrderDetails = (props: IApiOrder) => {
  const { id, items, shipping_address } = props;
  const { translate, isMobileSize } = useGlobalContext();
  // const { onOpenQuestionByOrderHandler } = useContext(ModalsContext);

  return (
    <div className={cn(styles.orderDetails)}>
      <div className={styles.orderDetails_content}>
        <div className={styles.orderDetails_column}>
          <OrderStatus {...props} />
          <Products items={props.items} />
        </div>

        <div
          className={cn(
            styles.orderDetails_column,
            isMobileSize && styles.reversed
          )}
        >
          {isMobileSize && (
            <div
              className={styles.questionByOrder}
              // onClick={() => onOpenQuestionByOrderHandler(orderModel)}
            >
              <InlineIcons.Question
                className={styles.questionByOrder_questionIcon}
              />
              {translate("Question by order")}
              <InlineIcons.ArrowDown className={styles.questionByOrder_arrow} />
            </div>
          )}
          {!!shipping_address && <OrderInfo {...shipping_address} />}
          <Result {...props} />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
