import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import PublicIcons from "@/components/basic/PublicIcon";
import Drawer from "@/components/Drawer";
import useGlobalContext from "@/context/GlobalContext";
import useRouter from "@/hooks/useRouter";
import TreeViewCategories from "@/modules/home/components/variant-one/TreeViewCategories";
import SidePanel from "@/modules/profile/components/SidePanel";
import apiPublic from "@/modules/public/constants/apiPublic";
import routesPublic from "@/modules/public/constants/routesPublic";
import { useEffect, useState, useRef } from "react";
import ButtonCart from "../ButtonCart";
import ButtonGlobalSearch from "../ButtonGlobalSearch";

import styles from "./styles.module.scss";

const TopMenuMobile = () => {
  const {
    translate,
    isMobileSize,
    isAuthorized,
    isSeller,
    currency,
    languages,
    modules: {
      home: { routes: homeRoutes },
      auth: {
        actions: { openLogIn },
      },
      profile: { routes: profileRoutes },
      seller: { routes: sellerRoutes },
    },
  } = useGlobalContext();
  const { pathname, query, asPath } = useRouter();
  const { search } = query;
  const ref = useRef(null);

  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showSearch && ref.current) {
      // @ts-ignore
      ref.current.querySelector("input").focus();
    }
  }, [showSearch]);

  useEffect(() => {
    if (showSearch) {
      setShowSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);

  return (
    <nav className={styles.TopMenuMobile} ref={ref}>
      <div className={styles.wrap}>
        <div style={{ display: "flex", flex: 1 }}>
          <PublicIcons
            className={styles.menu}
            src={PublicIcons.names.menu}
            width={28}
            height={28}
            onClick={() => setShowMenu(true)}
          />
          <PublicIcons
            className={styles.search}
            src={PublicIcons.names.search}
            width={28}
            height={28}
            onClick={() => setShowSearch(true)}
          />
        </div>
        <Link href={homeRoutes.home}>
          <Image src="/assets/logo.svg" width="150" height="30" alt="logo" />
        </Link>
        <div style={{ flex: 1 }}>
          {/* <PublicIcons src={PublicIcons.names.user} width={40} height={40} /> */}
          <ButtonCart className={styles.cart} />
          {isAuthorized ? (
            <>
              {isSeller ? (
                <Link href={sellerRoutes.dashboard} locale="en" className={styles.link}>
                  {/* {translate("Seller")} */}
                  <PublicIcons
                    className={styles.user}
                    src={PublicIcons.names.user}
                    width={28}
                    height={28}
                  />
                </Link>
              ) : (
                <Link href={profileRoutes.profile} className={styles.link}>
                  {/* {translate("Profile")} */}
                  <PublicIcons
                    className={styles.user}
                    src={PublicIcons.names.user}
                    width={28}
                    height={28}
                  />
                </Link>
              )}
            </>
          ) : (
            <Link
              href="#"
              className={styles.link}
              onClick={() =>
                openLogIn({ from: asPath !== "/" ? asPath : undefined })
              }
            >
              {/* {translate("Sign In")} */}
              <PublicIcons
                className={styles.user}
                src={PublicIcons.names.user}
                width={28}
                height={28}
              />
            </Link>
          )}
        </div>
      </div>
      <Drawer
        anchor="common"
        classNameContent={styles.links}
        show={showSearch}
        setShow={setShowSearch}
        hideByClickSpace
      >
        <ButtonGlobalSearch className={styles.mobileSearch} />
      </Drawer>
      <Drawer
        anchor="left"
        classNameContent={styles.links}
        show={showMenu}
        setShow={setShowMenu}
      >
        <Link href={homeRoutes.home} className={styles.logo}>
          <Image src="/assets/logo.svg" width="150" height="40" alt="logo" />
        </Link>
        <div className={styles.links}>
          <Link href={homeRoutes.home} className={styles.link}>
            {translate("Home")}
          </Link>
          <Link
            href={`${homeRoutes.products()}?campaigns=all`}
            className={styles.link}
          >
            {translate("Campaigns")}
          </Link>
          <Link href={homeRoutes.stores} className={styles.link}>
            {translate("Sellers")}
          </Link>
          <Link href={homeRoutes.products} className={styles.link}>
            {translate("Products")}
          </Link>
        </div>
        {/* <h3>{translate("Categories")}</h3>
        <TreeViewCategories
          apiUrl={apiPublic.categories()}
          baseRoute={routesPublic.category()}
          activeId={null}
          minHeight={250}
          maxHeight={400}
        /> */}
        {isAuthorized && (
          <div className={styles.profile}>
            <h3>{translate("Profile")}</h3>
            <SidePanel className={styles.profileMenu} />
          </div>
        )}
      </Drawer>
    </nav>
  );
};

export default TopMenuMobile;
