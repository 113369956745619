import Image from "next/image";
import iconsNames from "./icons-names";

interface PublicIcons {
  src: string;
  objectFit?: NonNullable<JSX.IntrinsicElements["img"]["style"]>["objectFit"];
  alt?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  onClick?: (e: any) => void;
}

const PublicIcons = ({
  src,
  alt = "",
  width = 20,
  height = 20,
  className,
  objectFit,
  onClick = () => {},
}: PublicIcons) => {
  return (
    <div className={className}>
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        onClick={onClick}
        objectFit={objectFit}
      />
    </div>
  );
};

PublicIcons.names = iconsNames;

export default PublicIcons;
