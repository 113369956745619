import { useCallback, useEffect, useMemo } from "react";

const isSSR = typeof window === "undefined";

const useVisibilityWindow = (
  callbackVisibilityChange: (show: boolean) => void,
  firstRun?: boolean
) => {
  // Set the name of the hidden property and the change event for visibility
  const hidden = useMemo(() => {
    if (isSSR) {
      return;
    }

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      return "hidden";
      // @ts-ignore
    } else if (typeof document.webkitHidden !== "undefined") {
      return "webkitHidden";
    }
  }, []);

  const visibilityChange = useMemo(() => {
    if (isSSR) {
      return;
    }

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      return "visibilitychange";
      // @ts-ignore
    } else if (typeof document.webkitHidden !== "undefined") {
      return "webkitvisibilitychange";
    }
  }, []);

  const isSupportsPageVisibilityAPI =
    !isSSR &&
    !(typeof document.addEventListener === "undefined" || hidden === undefined);

  const handleVisibilityChange = useCallback(() => {
    // @ts-ignore
    if (document?.[hidden]) {
      callbackVisibilityChange(false);
    } else {
      callbackVisibilityChange(true);
    }
  }, [callbackVisibilityChange, hidden]);

  useEffect(() => {
    if (firstRun) {
      callbackVisibilityChange(true);
    }
  }, [callbackVisibilityChange, firstRun]);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    if (!isSupportsPageVisibilityAPI) {
      console.warn(
        "Requires a browser, that supports the Page Visibility API."
      );
    } else {
      document?.addEventListener(
        // @ts-ignore
        visibilityChange,
        handleVisibilityChange,
        false
      );
    }

    return () =>
      document?.removeEventListener(
        // @ts-ignore
        visibilityChange,
        handleVisibilityChange,
        false
      );
  }, [handleVisibilityChange, isSupportsPageVisibilityAPI, visibilityChange]);
};

export default useVisibilityWindow;
