import React from "react";
import { useField } from "formik";
import cn from "@/libs/cn";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";
import DateInput, { IDateInput } from "@/controls/DateInput";

interface FieldDate extends IDateInput {
  className?: string;
  name: string;
  label?: string;
  placeholder?: string;
  onChange?: () => void;
  disabled?: boolean;
  placeholderClassName?: string;
  isIOS?: boolean;
}

const FieldDate = (props: FieldDate) => {
  const {
    className,
    name,
    label,
    placeholder,
    onChange = () => {},
    disabled,
    max = "",
    placeholderClassName,
    isIOS,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;

  return (
    <div className={cn(styles.fieldDate, className)}>
      {!!label && <div className={stylesForms.basicLabel}>{label}</div>}
      <DateInput
        {...field}
        {...rest}
        max={max}
        isIOS={isIOS}
        placeholder={placeholder}
        placeholderClassName={placeholderClassName}
        disabled={disabled}
      />
      <div
        className={cn(
          stylesForms.basicError,
          error && touched && stylesForms.show
        )}
      >
        {touched && error}
      </div>
    </div>
  );
};

export default FieldDate;
