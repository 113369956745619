import React, { useCallback, useId } from "react";
import cn from "@/libs/cn";

import styles from "./styles.module.scss";
import InlineIcons from "@/components/basic/InlineIcons";

export interface PropsCheckbox {
  className?: string;
  classNameWrap?: string;
  label?: string | JSX.Element;
  isCompact?: boolean;
  circle?: boolean;
  id?: string | number;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: any, id?: string) => void;
  disabled?: boolean;
  colorDark?: boolean;
}

const Checkbox = ({
  className,
  classNameWrap,
  label = " ",
  isCompact,
  circle,
  id,
  checked,
  defaultChecked,
  onChange = (e: any, id?: string) => {},
  disabled,
  colorDark,
  ...rest
}: PropsCheckbox) => {
  const iid = useId();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e, `${id}`);
    },
    [id, onChange]
  );

  return (
    <div className={cn(styles.checkbox, colorDark && styles.dark, className)}>
      <label className={styles.checkbox_custom} htmlFor={`${id || iid}`}>
        <input
          id={`${id || iid}`}
          type="checkbox"
          className={cn(isCompact && styles.compact, circle && styles.circle)}
          onChange={handleChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          {...rest}
        />
        <div
          className={cn(
            styles.span,
            isCompact && styles.compact,
            circle && styles.circle,
            classNameWrap
          )}
        >
          {label || " "}
        </div>
        <InlineIcons.Check
          className={cn(
            styles.icon,
            isCompact && styles.compact,
            circle && styles.circle
          )}
        />
      </label>
    </div>
  );
};

export default Checkbox;
