import BlockMaxWidth from "@/modules/layout/components/BlockMaxWidth";
import ButtonCart from "../ButtonCart";
import ButtonCategories from "../ButtonCategories";
import ButtonGlobalSearch from "../ButtonGlobalSearch";

import styles from "./StickyMenuV1.module.scss";

const StickyMenuV1 = () => {
  return (
    <div className={styles.StickyMenu}>
      <BlockMaxWidth className={styles.wrap}>
        <div className={styles.left}>
          <ButtonCategories />
        </div>
        <div className={styles.center}>
          <ButtonGlobalSearch />
        </div>
        <div className={styles.right}>
          <ButtonCart />
        </div>
      </BlockMaxWidth>
    </div>
  );
};

export default StickyMenuV1;
