import React from "react";
import cn from "@/libs/cn";
import useGlobalContext from "@/context/GlobalContext";
import DetailsAccordion from "../DetailsAccordion";
import InlineIcons from "@/components/basic/InlineIcons";

import styles from "./styles.module.scss";

const OrderInfo = (props: any) => {
  const { translate } = useGlobalContext();

  const {
    first_name,
    last_name,
    phone,
    email,
    street,
    city,
    country,
    zip_code,
    type,
    card_number,
    expired_date,
  } = props;

  return (
    <DetailsAccordion
      className={styles.details}
      header={
        <div className={styles.details_header}>
          <div
            className={cn(styles.details_header, styles.title, styles.order)}
          >
            <InlineIcons.Attention />
            {translate("Order details")}
          </div>
        </div>
      }
    >
      <div className={styles.details_content}>
        <div className={styles.orderInfo}>
          <div className={styles.orderInfo_wrapper}>
            <div className={styles.orderInfo_title}>
              {translate("Personal info")}
            </div>
            <div className={styles.orderInfo_label}>
              {`${first_name} ${last_name}`}
            </div>
            <div className={styles.orderInfo_label}>{phone}</div>
            <div className={styles.orderInfo_label}>{email}</div>
          </div>
          {(street || city || country || zip_code) && (
            <div className={styles.orderInfo_wrapper}>
              <div className={styles.orderInfo_title}>
                {translate("Address")}
              </div>
              <div className={styles.orderInfo_label}>{street}</div>
              <div className={styles.orderInfo_label}>{city}</div>
              <div className={styles.orderInfo_label}>{country}</div>
              <div className={styles.orderInfo_label}>{zip_code}</div>
            </div>
          )}
          {/* <div className={styles.orderInfo_wrapper}>
            <div className={styles.orderInfo_title}>
              {translate("Payment method")}
            </div>
            <div className={styles.orderInfo_label}>{type}</div>
            <div className={styles.orderInfo_label}>{card_number}</div>
            <div className={styles.orderInfo_label}>{expired_date}</div>
          </div> */}
        </div>
      </div>
    </DetailsAccordion>
  );
};

export default OrderInfo;
