import useGlobalContext from "@/context/GlobalContext";
import { useMemo } from "react";

type typeProps = (text: string) => string;

const dataFooterLinks = (
  routes: any,
  translate: typeProps = (text) => text
) => [
  {
    text: translate("Become a seller"),
    href: routes.becomeSeller(),
  },
  {
    text: translate("About Us"),
    href: routes.about(),
  },
  {
    text: translate("Contact Us"),
    href: routes.contact(),
  },
  {
    text: translate("Delivery"),
    href: routes.delivery(),
  },
  {
    text: translate("Return"),
    href: routes.return(),
  },
  {
    text: translate("Policy"),
    href: routes.policy(),
  },
];

const useDataLinks = () => {
  const {
    translate,
    modules: {
      home: { routes: homeRoutes },
    },
  } = useGlobalContext();

  const data = useMemo(
    () => dataFooterLinks(homeRoutes, translate),
    [homeRoutes, translate]
  );

  return data;
};

export default useDataLinks;
