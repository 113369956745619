import { ValueRoute } from "@/types/modules/interface-types";

export const baseRoute = "seller";

export type TypeRoutes = {
  dashboard: ValueRoute;
  store: ValueRoute;
  products: ValueRoute;
  product: (productId: any) => string;
  campaigns: ValueRoute;
  addProduct: ValueRoute;
  gallery: ValueRoute;
  importCSV: ValueRoute;
  apiKey: ValueRoute;
  orders: ValueRoute;
  order: (orderId: any) => string;
  reviews: ValueRoute;
  notifications: ValueRoute;
  settings: ValueRoute;
  settingsGeneral: ValueRoute;
  settingsAddresses: ValueRoute;
  settingsDeliveryInfo: ValueRoute;
  settingsDeliveryCost: ValueRoute;
  support: ValueRoute;
  profile: ValueRoute;
  media: ValueRoute;
};

const routesSeller = {
  dashboard: () => `/${baseRoute}/`,
  store: () => `/${baseRoute}/store/`,
  products: () => `/${baseRoute}/products/`,
  product: (productId: any) => `/${baseRoute}/product/?id=${productId}`,
  campaigns: () => `/${baseRoute}/campaigns/`,
  addProduct: () => `/${baseRoute}/add-product/`,
  gallery: () => `/${baseRoute}/gallery/`,
  importCSV: () => `/${baseRoute}/import-csv/`,
  apiKey: () => `/${baseRoute}/api-key/`,
  orders: () => `/${baseRoute}/orders/`,
  order: (orderId: any) => `/${baseRoute}/order/?id=${orderId}`,
  reviews: () => `/${baseRoute}/reviews/`,
  notifications: () => `/${baseRoute}/notifications/`,
  settings: () => `/${baseRoute}/settings/`,
  settingsGeneral: () => `/${baseRoute}/settings/general/`,
  settingsAddresses: () => `/${baseRoute}/settings/adresses/`,
  settingsDeliveryInfo: () => `/${baseRoute}/settings/delivery-info/`,
  settingsDeliveryCost: () => `/${baseRoute}/settings/delivery-cost/`,
  support: () => `/${baseRoute}/support/`,
  profile: () => `/${baseRoute}/profile/`,
  media: () => `/${baseRoute}/media/`,
} as TypeRoutes;

export default routesSeller;
