import { useCallback } from "react";
import useRouter from "@/hooks/useRouter";
import cn from "@/libs/cn";
import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import CustomScroll from "@/components/CustomScroll";
import Tree from "@/components/Tree";

import { useCommonSWR } from "@/libs/swr";
import { useChainByCategoryId } from "@/modules/public/hooks/useCategorySlug";

import styles from "./styles.module.scss";
import { processURLWithCampaigns } from "../Breadcrumbs";

interface ComponentItem {
  item: any;
  isActive: boolean;
  baseRoute?: string;
  paramName: string;
  router: any;
}

const ComponentItem = ({
  item,
  isActive,
  baseRoute,
  paramName,
  router,
}: ComponentItem) => {
  const { query, pathname } = router;
  const { campaigns } = query as any;
  const parents = useChainByCategoryId(item.id);

  const onClick = useCallback(() => {
    const isNew = query[paramName] != item.id;

    if (isNew) {
      const newQuery = { ...query, campaigns };
      delete newQuery["page"];

      router.push(
        {
          pathname,
          query: {
            ...newQuery,
            [paramName]: item.id,
          },
        },
        undefined,
        { scroll: false }
      );
    } else {
      const newQuery = { ...query, campaigns };
      delete newQuery["page"];
      delete newQuery[paramName];

      router.push(
        {
          pathname,
          query: newQuery,
        },
        undefined,
        { scroll: false }
      );
    }
  }, [campaigns, item.id, paramName, pathname, query, router]);

  if (paramName) {
    return (
      <a
        className={cn(styles.item, isActive && styles.active)}
        onClick={onClick}
      >
        <Image src={item.icon} width={24} height={24} alt="icon" />
        <div className={styles.label} title={item.name}>{`${item.name} ${
          item.products_count ? `(${item.products_count})` : ""
        }`}</div>
      </a>
    );
  }

  if (baseRoute) {
    const href = processURLWithCampaigns(
      `${baseRoute}${parents.length ? parents.join("/") + "/" : ""}`,
      campaigns
    );

    return (
      <Link
        href={href}
        scroll={false}
        className={cn(styles.item, isActive && styles.active)}
      >
        <>
          <Image src={item.icon} width={24} height={24} alt="icon" />
          <div className={styles.label} title={item.name}>{`${item.name} ${
            item.products_count ? `(${item.products_count})` : ""
          }`}</div>
        </>
      </Link>
    );
  }

  return (
    <div className={styles.label} title={item.name}>{`${item.name} ${
      item.products_count ? `(${item.products_count})` : ""
    }`}</div>
  );
};

interface TreeViewCategories {
  activeId: any;
  label?: string;
  minHeight: number;
  maxHeight: number;
  apiUrl: string;
  baseRoute?: string;
  paramName?: string;
}

const TreeViewCategories = ({
  activeId,
  label = "",
  minHeight = 150,
  maxHeight = 260,
  apiUrl,
  baseRoute,
  paramName,
}: TreeViewCategories) => {
  const router = useRouter();
  const { query } = router;
  const categories = useCommonSWR({ key: apiUrl });

  return (
    <div className={styles.TreeViewCategories}>
      {!!label && <h3>{label}</h3>}
      <CustomScroll
        minHeight={minHeight}
        maxHeight={maxHeight}
        classNameContent={styles.content}
      >
        <Tree
          rootId={null}
          data={categories.data}
          // @ts-ignore
          activeId={activeId || query[paramName]}
          componentItem={(props: any) => (
            <ComponentItem
              {...props}
              baseRoute={baseRoute}
              router={router}
              paramName={paramName}
            />
          )}
        />
      </CustomScroll>
    </div>
  );
};

export default TreeViewCategories;
