// @ts-nocheck
import dynamic from "next/dynamic";
import { useMemo } from "react";
import Waiting from "@/components/basic/Waiting";
import { UseInterface } from "types/modules/interface-types";

const FormCampaign = dynamic(() => import("../forms/FormCampaign"), {
  ssr: false,
  suspense: false,
});

const useActions = ({ openModal, translate }: UseInterface) => {
  return useMemo(() => {
    return {
      openNewCampaign: (props) => {
        openModal(
          () => {
            return (
              <Waiting>
                <FormCampaign {...props} />
              </Waiting>
            );
          },
          {
            title: props.campaignId
              ? translate("Edit campaign")
              : translate("Launch new campaign"),
          }
        );
      },
    };
  }, [openModal, translate]);
};

export default useActions;
