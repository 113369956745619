import { toast } from "react-toastify";

import styles from "./styles.module.scss";

const useToasts = () => {
  return {
    info: (text: string) => toast.info(text, { className: styles.info }),
    success: (text: string) =>
      toast.success(text, { className: styles.success }),
    warning: (text: string) =>
      toast.warning(text, { className: styles.warning }),
    error: (text: string) => toast.error(text, { className: styles.error }),
  };
};

export default useToasts;
