// @ts-nocheck
import { useCallback, useMemo } from "react";
import * as Yup from "yup";

interface UseValidation {
  scheme: Yup.AnyObjectSchema;
  requiredFields?: {
    [key: string]: boolean;
  };
}

const useValidation = ({ scheme, requiredFields = {} }: UseValidation) => {
  const schemaData = useMemo(() => {
    const data = { ...scheme };
    Object.keys(data).forEach((field) => {
      if (requiredFields[field]) {
        data[field] = data[field].clone().required("This field is required");
      } else {
        data[field] = data[field].clone();
      }
    });
    return Yup.object().shape(data);
  }, [requiredFields, scheme]);

  const setAsyncError = useCallback((helper, error) => {
    if (typeof error === "object") {
      Object.keys(error).forEach((key) => {
        const text = Array.isArray(error[key])
          ? error[key].join("\n")
          : `${error[key]}`;

        helper.setFieldError(key, text);
        helper.setFieldTouched(key, true, false);
      });
    }
  }, []);

  return {
    scheme: schemaData,
    setAsyncError,
  };
};

export default useValidation;
