export const addingZero = (val: number) => `${val > 9 ? val : `0${val}`}`;

export const timestampToISOString = (date: number, withoutTime?: boolean) => {
  const current = new Date(date);

  try {
    return withoutTime
      ? current.toISOString().split("T")[0]
      : current.toISOString();
  } catch (err) {
    console.error("timestampToISOString", err, date);
    return "";
  }
};

// This month

export const timestampStartThisMonth = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();

  const timestamp = new Date(`${year}-${addingZero(month + 1)}-01`).getTime();

  return timestamp;
};

export const getThisMonthMilliseconds = () => {
  const current = new Date(Date.now());
  const year = current.getFullYear();
  const month = current.getMonth();

  try {
    const timestamp =
      new Date(`${year}-${addingZero(month + 2)}-01`).getTime() -
      1 -
      new Date(`${year}-${addingZero(month + 1)}-01`).getTime() +
      current.getTimezoneOffset() * 60 * 1000;

    return timestamp;
  } catch (err) {
    console.error("getThisMonthMilliseconds", err);
    return 0;
  }
};

// Last month

export const timestampStartLastMonth = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();

  if (month > 0) {
    month -= 1;
  } else {
    month = 11;
    year -= 1;
  }

  return new Date(`${year}-${addingZero(month + 1)}-01`).getTime();
};

export const getLastMonthMilliseconds = () => {
  const startTimestamp = timestampStartLastMonth();

  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();

  if (month > 0) {
    month -= 1;
  } else {
    month = 11;
    year -= 1;
  }

  const endTimestamp =
    new Date(`${year}-${addingZero(month + 2)}-01`).getTime() -
    1 -
    current.getTimezoneOffset() * 60 * 1000;

  return endTimestamp - startTimestamp;
};

// This year

export const timestampStartThisYear = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();

  return new Date(`${year}-01-01`).getTime();
};

export const getThisYearMilliseconds = () => {
  const current = new Date(Date.now());
  const year = current.getFullYear();

  return (
    new Date(`${year + 1}-01-01`).getTime() -
    1 -
    new Date(`${year}-01-01`).getTime()
  );
};

// Last year

export const timestampStartLastYear = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();

  return new Date(`${year - 1}-01-01`).getTime();
};

export const getLastYearMilliseconds = () => {
  const startTimestamp = timestampStartLastYear();

  const current = new Date(Date.now());
  let year = current.getFullYear();

  const endTimestamp = new Date(`${year}-01-01`).getTime() - 1;

  return endTimestamp - startTimestamp;
};

// Range

export const rangeThisMonthISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartThisMonth(),
    withoutTime
  )},${timestampToISOString(
    timestampStartThisMonth() + getThisMonthMilliseconds(),
    withoutTime
  )}`;

export const rangeLastMonthISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartLastMonth(),
    withoutTime
  )},${timestampToISOString(
    timestampStartLastMonth() + getLastMonthMilliseconds(),
    withoutTime
  )}`;

export const rangeThisYearISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartThisYear(),
    withoutTime
  )},${timestampToISOString(
    timestampStartThisYear() + getThisYearMilliseconds(),
    withoutTime
  )}`;

export const rangeLastYearISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartLastYear(),
    withoutTime
  )},${timestampToISOString(
    timestampStartLastYear() + getLastYearMilliseconds(),
    withoutTime
  )}`;
