import LoaderCircle from "@/controls/LoaderCircle";

import FormProfile from "../form/FormProfile";
import { useDataProfile } from "../hooks/useData";

const normalizeInit = ({ id, is_seller, ...rest }: any) => ({
  ...rest,
});

const PageProfile = () => {
  const { data, isLoading, mutate } = useDataProfile();

  return (
    <div>
      {isLoading ? (
        <LoaderCircle />
      ) : (
        <FormProfile initValues={normalizeInit(data)} onSuccess={mutate} />
      )}
    </div>
  );
};

export default PageProfile;
