import useProcessQueryAction from "@/hooks/useProcessQueryAction";
import { DOMAINE } from "@/constants/common";
import Head from "@/components/basic/Head";
import TopMenu from "../components/TopMenu";
import StickyMenuV1 from "../components/StickyMenuV1";
import Footer from "../components/Footer";
import BlockMaxWidth from "@/modules/layout/components/BlockMaxWidth";
import useGlobalContext from "@/context/GlobalContext";
import TopMenuMobile from "../components/TopMenuMobile";
import Content from "../components/Content";

import styles from "./main.module.scss";
import { MARKETPLACE_NAME } from "@/constants/info";

const title = `${MARKETPLACE_NAME} - marketplace`;
const description = `${MARKETPLACE_NAME} Marketplace`;
const keywords = `${MARKETPLACE_NAME} Marketplace`;
const urlImage = `${DOMAINE}/assets/logo.png`;

type typeProps = {
  children: JSX.Element | JSX.Element[];
};

const MainLayout = ({ children }: typeProps) => {
  useProcessQueryAction();
  const { isMobileSize } = useGlobalContext();

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description}></meta>
        <meta property="keywords" content={keywords} key="keywords" />
        <meta property="og:title" content={title} key="title" />
        <meta
          property="og:description"
          content={description}
          key="description"
        />
        <meta property="og:keywords" content={keywords} key="keywords" />
        <meta property="og:image" content={urlImage} key="image" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="logo" href={urlImage} />
      </Head>
      <div className={styles.Layout}>
        {isMobileSize ? (
          <TopMenuMobile />
        ) : (
          <>
            <div>
              <BlockMaxWidth>
                <TopMenu />
              </BlockMaxWidth>
            </div>
            <StickyMenuV1 />
          </>
        )}

        {/* <DevInfo /> */}
        <Content>{children}</Content>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
