// @ts-nocheck
import { useMemo } from "react";
import useGlobalContext from "@/context/GlobalContext";

interface useBasePath {
    module?: string
}

const useBasePath = ({ module }: useBasePath) => {
    const { modules } = useGlobalContext();

    const baseRoute = useMemo(() => {
        if (!module) {
            return '';
        }

        try {
            return modules[module].baseRoute || '';
        } catch (err) {
            console.error('Not found module', module);
            return '';
        }
    }, [module, modules]);

    return baseRoute;
};

export default useBasePath 