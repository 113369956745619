import Link from "next/link";
import React from "react";
import useGlobalContext from "@/context/GlobalContext";
import routesPublic from "@/modules/public/constants/routesPublic";
import { slugByName } from "@/modules/public/hooks/useCategorySlug";
import useRouter from "@/hooks/useRouter";

import styles from "./styles.module.scss";

// TODO moved
export const processURLWithCampaigns = (url: string, campaignsParam?: string) => {
  return campaignsParam ? `${url}?campaigns=${campaignsParam}` : url;
};

interface Breadcrumbs {
  data: any[];
  isPageCategory?: boolean;
}

const Breadcrumbs = ({ data, isPageCategory }: Breadcrumbs) => {
  const { translate } = useGlobalContext();
  const { query } = useRouter();
  const { campaigns } = query as any;

  return (
    <nav className={styles.Breadcrumbs}>
      <Link href={routesPublic.root()} className={styles.link}>
        {translate("Home")}
      </Link>
      <div className={styles.separator}>/</div>
      {isPageCategory && (
        <>
          <Link
            href={processURLWithCampaigns(routesPublic.category(), campaigns)}
            className={styles.link}
          >
            {translate("All")}
          </Link>
          <div className={styles.separator}>/</div>
        </>
      )}
      {isPageCategory
        ? data.map((item, idx) => {
            const chain = data.slice(0, idx + 1);
            const url = chain
              .map((categoryItem) => slugByName(categoryItem.name))
              .join("/");

            return (
              <React.Fragment key={url}>
                <Link
                  href={processURLWithCampaigns(
                    `${routesPublic.category()}${url}`,
                    campaigns
                  )}
                  className={styles.link}
                >
                  {item.name}
                </Link>
                {idx !== data.length - 1 && (
                  <div className={styles.separator}>/</div>
                )}
              </React.Fragment>
            );
          })
        : data.map((item, idx) => {
            const { label, route } = item;
            return (
              <React.Fragment key={route}>
                <Link
                  href={processURLWithCampaigns(route, campaigns)}
                  className={styles.link}
                >
                  {label}
                </Link>
                {idx !== data.length - 1 && (
                  <div className={styles.separator}>/</div>
                )}
              </React.Fragment>
            );
          })}
    </nav>
  );
};

Breadcrumbs.defaultProps = {
  data: [],
  isPageCategory: false,
};

export default Breadcrumbs;
