import { useMemo } from "react";

const useQuery = () => {
  const URLparams = new URLSearchParams(global?.location?.search);

  const query = useMemo(() => {
    const temp: any = {};
    URLparams.forEach((value, key) => {
      temp[key] = value;
    });
    return temp;
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global?.location?.search]);

  return query;
};

export default useQuery;
