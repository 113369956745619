import { TypeID, ValueRoute } from "@/types/modules/interface-types";

export const baseRoute = "profile";

export type TypeRoutes = {
  profile: ValueRoute;
  addresses: ValueRoute;
  favorites: ValueRoute;
  notifications: (tab?: TypeID) => string;
  orders: ValueRoute;
  order: ValueRoute;
  payments: ValueRoute;
  preferences: ValueRoute;
};

const routesProfile = {
  profile: () => `/${baseRoute}/`,
  addresses: () => `/${baseRoute}/addresses/`,
  favorites: () => `/${baseRoute}/favorites/`,
  notifications: (tab?: string) =>
    `/${baseRoute}/notifications/?tab=${tab || "all"}`,
  orders: () => `/${baseRoute}/orders/`,
  order: (orderId: any) => `/${baseRoute}/order/?id=${orderId}`,
  payments: () => `/${baseRoute}/payments/`,
  preferences: () => `/${baseRoute}/preferences/`,
} as TypeRoutes;

export default routesProfile;
