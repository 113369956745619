const API_PUBLIC = `/public/`;
const API_STORES = `${API_PUBLIC}stores/`;
const API_CATEGORIES = `${API_PUBLIC}categories/`;
const API_PRODUCTS = `${API_PUBLIC}products/`;
const API_PROPERTIES = `${API_PUBLIC}properties/`;
const API_COUNTRIES = `${API_PUBLIC}countries/`;

const API_MAP = `/map/`;

const API_NEXT = `/`;
const MOCK_API = `${API_NEXT}mock/`;

const apiPublic = {
  assets: () => `/assets/`,
  languages: () => `${API_PUBLIC}general/languages`,
  currencies: () => `${API_PUBLIC}currencies/`,
  vat: () => `${API_PUBLIC}general/vat`,
  storesTags: () => `${API_PUBLIC}store_tags/`,
  storeTags: (storeId: any) => `${API_PUBLIC}stores/${storeId}/tags/`,
  storeTag: (tagId: any) => `${API_PUBLIC}store_tags/${tagId}/`,
  socialNetworks: () => `${API_PUBLIC}social_networks/`,
  productTags: () => `${API_PUBLIC}product_tags/`,

  countries: () => `${API_COUNTRIES}`,
  countryProvinces: (countryCode: string) =>
    `${API_COUNTRIES}${countryCode}/provinces/`,
  countryCities: (countryCode: string) =>
    `${API_COUNTRIES}${countryCode}/cities/`,

  categories: () => `${API_CATEGORIES}`,
  category: (categoryId: any) => `${API_CATEGORIES}${categoryId}/`,

  products: () => `${API_PRODUCTS}`,
  product: (productId: any) => `${API_PRODUCTS}${productId}/`,
  relatedProducts: (productId: any) =>
    `${API_PRODUCTS}${productId}/related_products/`,
  myCampaigns: () => `${API_PRODUCTS}my_campaigns/`,
  allCampaigns: () => `${API_PRODUCTS}all_campaigns/`,

  properties: () => `${API_PROPERTIES}`,
  property: (propertyId: any) => `${API_PROPERTIES}${propertyId}/`,
  categoryProperties: (categoryId: any) =>
    `${API_PROPERTIES}category/${categoryId}/`,

  // TODO
  storesDeliveryOptions: () => `${API_STORES}delivery/options`,
  stores: () => `${API_STORES}`,
  store: (storeId: any) => `${API_STORES}${storeId}/`,
  feturedStores: () => `${API_STORES}fetured_stores/`,
  newStores: () => `${API_STORES}new_stores/`,
  storeCategories: (storeId: any) => `${API_STORES}${storeId}/categories/`,

  filterPriceRange: () => `${API_PRODUCTS}get_price_range/`,
  filterWeigthRange: () => `${API_PRODUCTS}get_weight_range/`,
  mapAddresses: () => `${API_MAP}addresses/`,

  // from next api
  banners: () => `${MOCK_API}banners/`,
  sales: () => `${MOCK_API}sales/`,
  featuredProducts: () => `${MOCK_API}featured-products/`,
  featuredTags: () => `${MOCK_API}featured-tags/`,
  MOCKnewStores: () => `${MOCK_API}new-stores/`,
};

export default apiPublic;
