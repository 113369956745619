import { useCallback } from "react";
import { idsToString } from "@/utils/data";

import styles from "./styles.module.scss";
import LoaderCircle from "@/controls/LoaderCircle";
import ToggleIconButton from "@/controls/ToggleIconButton";

interface TagsCloud {
  data: any[];
  selected: any[];
  onSelected: (selected: any[]) => void;
  classNameItem?: string;
}

const TagsCloud = ({
  data,
  selected,
  onSelected,
  classNameItem,
}: TagsCloud) => {
  const isReady = data;

  const onClick = useCallback(
    (val: any) => {
      const isNew = !idsToString(selected).includes(`${val}`);
      const newList = isNew
        ? [...idsToString(selected), `${val}`]
        : idsToString(selected).filter((item) => item != `${val}`);

      onSelected(newList);
    },
    [onSelected, selected]
  );

  return (
    <div className={styles.TagsCloud}>
      <div className={styles.list}>
        {isReady ? (
          data.map((item: any, idx: number) => {
            const isActive = idsToString(selected).includes(`${item.id}`);

            return (
              <ToggleIconButton
                key={idx}
                label={item.title}
                onToggle={() => onClick(item.id)}
                value={isActive}
                hideNoActiveIcon
                className={classNameItem}
              />
            );
          })
        ) : (
          <LoaderCircle stretchToSpace />
        )}
      </div>
    </div>
  );
};

export default TagsCloud;
