// @ts-nocheck
import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import useGlobalContext from "@/context/GlobalContext";
import Dropdown from "@/controls/Dropdown";
import useRouter from "@/hooks/useRouter";

import styles from "./styles.module.scss";

const TopMenu = () => {
  const {
    translate,
    isAuthorized,
    isSeller,
    currency,
    languages,
    modules: {
      home: { routes: homeRoutes },
      auth: {
        actions: { openLogIn },
      },
      profile: { routes: profileRoutes },
      seller: { routes: sellerRoutes },
    },
  } = useGlobalContext();
  const { asPath } = useRouter();

  return (
    <nav className={styles.TopMenu}>
      <div className={styles.left}>
        <Link href={homeRoutes.home}>
          <Image src="/assets/logo.svg" width="154" height="43" alt="logo" />
        </Link>
      </div>
      <div className={styles.center}>
        <Link href={homeRoutes.home} className={styles.link}>
          {translate("Home")}
        </Link>
        <Link
          href={`${homeRoutes.products()}?campaigns=all`}
          className={styles.link}
        >
          {translate("Campaigns")}
        </Link>
        <Link href={homeRoutes.stores} className={styles.link}>
          {translate("Sellers")}
        </Link>
        <Link href={homeRoutes.products} className={styles.link}>
          {translate("Products")}
        </Link>
        <Link href={homeRoutes.about} className={styles.link}>
          {translate("About us")}
        </Link>
        <Link href={homeRoutes.contact} className={styles.link}>
          {translate("Contact us")}
        </Link>
        <Link href={homeRoutes.term} className={styles.link}>
          {translate("Terms")}
        </Link>
      </div>
      <div className={styles.right}>
        <Dropdown
          options={currency.list}
          value={currency.value}
          onChange={currency.onChange}
          hiddenBackground
          classNameValue={styles.link}
        />
        <Dropdown
          options={languages.list}
          value={languages.value}
          onChange={languages.onChange}
          hiddenBackground
          classNameValue={styles.link}
        />
        {isAuthorized ? (
          <>
            {isSeller ? (
              <Link href={sellerRoutes.dashboard} locale="en" className={styles.link}>
                {translate("Seller")}
              </Link>
            ) : (
              <Link href={profileRoutes.profile} className={styles.link}>
                {translate("Profile")}
              </Link>
            )}
          </>
        ) : (
          <Link
            href="#"
            className={styles.link}
            onClick={() =>
              openLogIn({ from: asPath !== "/" ? asPath : undefined })
            }
          >
            {translate("Sign In")}
          </Link>
        )}
      </div>
    </nav>
  );
};

export default TopMenu;
