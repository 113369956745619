// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import cn from "@/libs/cn";
import disableScroll from "@/utils/disableScroll";
import useOverlay from "./useOverlay";
import { wait } from "@/utils/wait";

import styles from "./modal.module.scss";
import PublicIcons from "@/components/basic/PublicIcon";

interface ModalOptions {
  isIOS?: boolean;
  elementId?: string;
  preventScroll?: boolean;
  closeOnOverlayClick?: boolean;
}

export interface OptionsModal {
  isConfirmModal?: boolean;
  title: string;
  message?: string;
  onConfirm: () => void;
  textOK?: string;
  buttonOkColor?: "primary" | "secondary";
  icon: JSX.Element;
  iconColor?: "primary" | "secondary" | "info" | "error";
  closeAllPrevious?: boolean;
  breakOpen?: boolean;
}

export type UseModal = (args: ModalOptions) => {
  modals?: JSX.Element;
  open: (component: any, options?: OptionsModal) => any;
  back: () => void;
  close: () => void;
  isOpen: boolean;
};

const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "#00000080",
  zIndex: 100000,
};

const containerStyle: React.CSSProperties = {
  position: "relative",
  zIndex: 100001,
  minWidth: "200px",
  minHeight: "200px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
};

const Portal = ({ children, root, active }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function transition() {
      await wait(0);
      setShow(true);
    }

    transition();
  });

  const cont = useMemo(
    () => (
      <div className={cn(styles.Modal, active && show && styles.show)}>
        {children}
      </div>
    ),
    [active, children, show]
  );

  return createPortal(cont, root);
};

const useModal: UseModal = (args) => {
  const {
    elementId = "__next",
    preventScroll = true,
    closeOnOverlayClick = true,
  } = args;
  const [isOpen, setOpen] = useState(false);
  const [content, setContent] = useState([]);

  const open = useCallback(
    (component: () => JSX.Element, options?: OptionsModal = {}) => {
      if (options.breakOpen) {
        return;
      }

      const prevContent = options.closeAllPrevious ? [] : [...content];

      setContent([...prevContent, { component, options }]);
      setOpen(true);
      if (preventScroll) {
        disableScroll.on(null, { disableWheel: false });
      }
    },
    [content, preventScroll]
  );

  const back = useCallback(() => {
    const newContent = content.slice(0, content.length - 1);
    setContent(newContent);

    if (newContent.length < 1) {
      setOpen(false);
      if (preventScroll) {
        disableScroll.off();
      }
    }
  }, [content, preventScroll]);

  const close = useCallback(() => {
    setOpen(false);
    setContent([]);
    if (preventScroll) {
      disableScroll.off();
    }
  }, [setOpen, preventScroll]);

  const onOverlayClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (closeOnOverlayClick) {
        close();
      }
    },
    [closeOnOverlayClick, close]
  );

  const [root, setRoot] = useState(null);

  const contentProps = useMemo(
    () => ({
      openModal: open,
      backModal: back,
      closeModal: close,
    }),
    [back, close, open]
  );

  useOverlay(isOpen, close, { current: root });

  const modals = useMemo(
    () => (
      <div role="dialog" aria-modal className={styles.dialog}>
        <div style={overlayStyle} onClick={onOverlayClick} />
        <div ref={(el) => setRoot(el)} style={containerStyle}>
          {!!root &&
            content.map(({ component, options }, idx) => {
              const Comp = component;
              const { title, icon: Icon } = options;
              const isLast = idx === content.length - 1;

              return (
                <Portal key={`modal-${idx}`} root={root} active={isLast}>
                  <div className={styles.header}>
                    {!!Icon && (
                      // @ts-ignore
                      <Icon
                        height={26}
                        className={cn(
                          iconColor === "primary" && styles.primary,
                          iconColor === "secondary" && styles.secondary,
                          iconColor === "info" && styles.info,
                          iconColor === "error" && styles.error
                        )}
                      />
                    )}
                    <div className={styles.title}>{title}</div>
                    <PublicIcons
                      src={PublicIcons.names.close2}
                      className={styles.iconClose}
                      onClick={close}
                    />
                    {idx > 0 && (
                      <PublicIcons
                        src={PublicIcons.names.arrowShortLeft}
                        className={styles.iconBack}
                        onClick={back}
                      />
                    )}
                  </div>

                  {typeof item === "string" ? item : <Comp {...contentProps} />}
                </Portal>
              );
            })}
        </div>
      </div>
    ),
    [back, close, content, contentProps, onOverlayClick, root]
  );

  return useMemo(
    () => ({ modals: isOpen ? modals : null, open, back, close, isOpen }),
    [back, close, isOpen, modals, open]
  );
};

export default useModal;
