export const getRealPrice = (price?: string, final_price?: string) => {
  if (price) {
    return final_price !== price ? final_price : price;
  } else {
    return final_price;
  }
};

export const getOldPrice = (price?: string, final_price?: string) => {
  if (price) {
    return final_price !== price ? price : "0";
  } else {
    return "0";
  }
};

export const getDiscountPercent = (price?: string, final_price?: string) => {
  if (!price || final_price === price) {
    return 0;
  }

  const valPrice = parseFloat(price);
  const valFinalPrice = parseFloat(final_price || '');

  return ((100 - (valFinalPrice / valPrice)*100)).toFixed(0);
};
