import styles from "./styles.module.scss";
import { Key, useCallback, useMemo, useState } from "react";
import cn from "@/libs/cn";
import PublicIcons from "../basic/PublicIcon";

const checkActiveBranch: any = (
  data: any[],
  rootIdActiveBranch: any,
  activeId: any,
  rootId: any
) => {
  if (typeof activeId === "undefined") {
    return false;
  }

  const activeItem = data.find((item: { id: any }) => item.id == activeId);

  if (!activeItem) {
    return false;
  }

  if (activeItem.parent === rootId) {
    return activeItem.id == rootIdActiveBranch;
  }

  return checkActiveBranch(data, rootIdActiveBranch, activeItem.parent);
};

interface Nested {
  group: any;
  data: any;
  value?: any;
  highlightValue?: boolean;
  rootId: any;
  rootIdActiveBranch: any;
  parentId: any;
  isShow: boolean;
  activeId: any;
  groupTag?: any;
  childrenTag?: any;
  componentItem: any;
}

const Nested = ({
  group,
  data,
  value,
  highlightValue,
  rootId,
  rootIdActiveBranch,
  parentId,
  isShow = true,
  activeId,
  groupTag: GroupTag = "ul",
  childrenTag: ChildrenTag = "li",
  componentItem: ComponentItem,
}: Nested) => {
  const isActive = activeId == parentId;
  const isActiveBranch = checkActiveBranch(
    data,
    rootIdActiveBranch,
    activeId,
    rootId
  );
  const isCurrentValue = value == group.id;
  const [show, setShow] = useState(isActiveBranch);

  const nestedData = useMemo(
    () => data.filter((item: { parent: any }) => item.parent == parentId),
    [data, parentId]
  );

  const onClick = useCallback(
    (ev: { stopPropagation: () => void }) => {
      if (!nestedData.length) {
        return;
      }
      ev.stopPropagation();
      setShow(!show);
    },
    [nestedData.length, show]
  );

  return (
    <>
      <ChildrenTag
        key={parentId}
        className={cn(styles.li, styles.label, isShow && styles.show)}
      >
        <div
          className={cn(
            styles.text,
            isActive && styles.active,
            highlightValue && isCurrentValue && styles.highlightValue
          )}
        >
          {ComponentItem ? (
            <ComponentItem
              item={group}
              nestedData={nestedData}
              isActive={isActive}
            />
          ) : (
            `${group.parent} - ${group.name} (${nestedData.length})`
          )}
        </div>
        {!!nestedData.length && (
          <div
            className={cn(styles.arrow, show && styles.show)}
            onClick={onClick}
          >
            <PublicIcons src={PublicIcons.names.arrowDownBorder} />
          </div>
        )}
      </ChildrenTag>
      {!!nestedData.length && (
        <GroupTag className={styles.ul}>
          {nestedData.map((item: { id: Key | null | undefined }) => (
            <Nested
              key={item.id}
              activeId={activeId}
              isShow={isShow && show}
              group={item}
              data={data}
              value={value}
              highlightValue={highlightValue}
              rootId={rootId}
              rootIdActiveBranch={rootIdActiveBranch}
              parentId={item.id}
              componentItem={ComponentItem}
            />
          ))}
        </GroupTag>
      )}
    </>
  );
};

interface Tree {
  activeId?: string | number;
  data: any;
  value?: any;
  highlightValue?: boolean;
  rootId: any;
  parentId?: any;
  groupTag?: any;
  childrenTag?: string;
  componentItem: any;
}

const Tree = ({
  activeId,
  data = [],
  value,
  highlightValue,
  rootId = null,
  parentId = null,
  groupTag: GroupTag = "ul",
  childrenTag: ChildrenTag = "li",
  componentItem,
}: Tree) => {
  return (
    <GroupTag className={cn(styles.Tree, styles.ul)}>
      {data.map((item: { parent: any; id: any }, idx: Key) =>
        item.parent == parentId ? (
          <Nested
            key={idx}
            activeId={activeId}
            isShow
            group={item}
            data={data}
            value={value}
            highlightValue={highlightValue}
            rootId={rootId}
            rootIdActiveBranch={item.id}
            parentId={item.id}
            groupTag={GroupTag}
            childrenTag={ChildrenTag}
            componentItem={componentItem}
          />
        ) : null
      )}
    </GroupTag>
  );
};

export default Tree;
