import InlineIcons from "@/components/basic/InlineIcons";

import routesProfile from "./routesProfile";
import PageAddresses from "../pages/addresses";
import PageFavorites from "../pages/favorites";
import PageNotifications from "../pages/notifications";
import PageOrders from "../pages/orders";
import PagePayments from "../pages/payments";
import PageProfile from "../pages/profile";
import PagePreferences from "../pages/preferences";
import PageOrder from "../pages/order";

type typeProps = (text: string) => string;

export interface dataTab {
  component: JSX.Element;
  title: string;
  hideTitleOnPage?: boolean;
  route: string;
  icon: JSX.Element;
  order: number;
  hiddenInMenu?: boolean;
}

const dataProfileMenu = (translate: typeProps = (text) => text, id?: any) => ({
  profile: {
    component: PageProfile,
    title: translate("Profile"),
    route: routesProfile.profile(),
    order: 0,
    hiddenInMenu: true,
  },
  addresses: {
    component: PageAddresses,
    title: translate("Addresses"),
    hideTitleOnPage: true,
    route: routesProfile.addresses(),
    icon: InlineIcons.Location,
    order: 4,
  },
  /*favorites: {
    component: PageFavorites,
    title: translate("Favorites"),
    route: routesProfile.favorites(),
    icon: InlineIcons.Heart,
    order: 2,
  },
  notifications: {
    component: PageNotifications,
    title: translate("Notifications"),
    route: routesProfile.notifications(),
    icon: InlineIcons.Notifications,
    order: 3,
  },*/
  orders: {
    component: PageOrders,
    title: translate("My Orders"),
    route: routesProfile.orders(),
    icon: InlineIcons.Orders,
    order: 1,
  },
  order: {
    component: PageOrder,
    title: translate("Order") + ` #${id}`,
    route: routesProfile.order(id),
    icon: InlineIcons.Orders,
    order: -1,
    hiddenInMenu: true,
  },
  /*
  payments: {
    component: PagePayments,
    title: translate("Payments method"),
    hideTitleOnPage: true,
    route: routesProfile.payments(),
    icon: InlineIcons.CreditCard,
    order: 5,
  },
  */
  preferences: {
    component: PagePreferences,
    title: translate("Preferences"),
    route: routesProfile.preferences(),
    icon: InlineIcons.Settings,
    order: 6,
  },
});

export default dataProfileMenu;
