
import cn from "@/libs/cn";
import React from "react";

import styles from "./styles.module.scss";

interface HrLine {
  height?: string | number;
  padding?: string | number;
  margin?: string | number;
}

const HrLine = ({ height = 1, padding = 0, margin }: HrLine) => {
  return (
    <div className={cn(styles.hrLine)} style={{ padding, margin }}>
      <div style={{ height }} />
    </div>
  );
};

export default HrLine;
