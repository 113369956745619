import { GlobalContext } from "./context-types";

import { createContext, useContext } from "react";
import { defaultValue as authDefaultValue } from "@/modules/auth/interface";
import { defaultValue as homeDefaultValue } from "@/modules/home/interface";
import { defaultValue as cartDefaultValue } from "@/modules/cart/interface";
import { defaultValue as profileDefaultValue } from "@/modules/profile/interface";
import { defaultValue as publicDefaultValue } from "@/modules/public/interface";
import { defaultValue as sellerDefaultValue } from "@/modules/seller/interface";
import { defaultValue as galleryDefaultValue } from "@/modules/gallery/interface";
import { PropsConfirmModal } from "@/libs/modal/confirm/useConfirm";

/*
  This place for Dependency Injections of modules interface
*/

export const globalContext = createContext<GlobalContext>({
  // @ts-ignore
  providers: {},
  pageProps: { url: "" },
  setAuthToken: (
    data: { access: string; refresh: string },
    remember?: boolean
  ) => {},
  isReady: false,
  isAuthorized: false,
  isSeller: false,
  isMobileSize: false,
  isIOS: false,
  isMediumHeight: false,
  locale: "",
  languages: {
    value: "",
    list: [],
    onChange: (code: string) => {},
  },
  currency: {
    value: "",
    priceByRate: (price: any) => 0,
    symbol: "",
    list: [],
    onChange: (code: string) => {},
  },
  translate: (text: string) => text,
  modules: {
    auth: authDefaultValue,
    home: homeDefaultValue,
    cart: cartDefaultValue,
    profile: profileDefaultValue,
    public: publicDefaultValue,
    seller: sellerDefaultValue,
    gallery: galleryDefaultValue,
  },
  toast: {
    info: (text: string) => {},
    success: (text: string) => {},
    warning: (text: string) => {},
    error: (text: string) => {},
  },
  openModal: (content: any) => {},
  backModal: () => {},
  closeModal: () => {},
  openConfirm: (args: PropsConfirmModal) => {},
});

export const useGlobalContext = () => {
  return useContext(globalContext);
};

export default useGlobalContext;
