import { useRouter } from "next/router";
import { useMemo } from "react";

interface ArgsLanguages {
  locale: string;
  locales: string[];
}

const useLanguages = (args: ArgsLanguages) => {
  const { locale = "", locales = [] } = args;
  const router = useRouter();

  const data = useMemo(
    () => ({
      value: locale,
      list: locales.map((code) => ({
        value: code,
        label: `${code}`.toUpperCase(),
      })),
      onChange: (code: string) => {
        router.replace(router.asPath, router.asPath, { locale: code });
      },
    }),
    [locale, locales, router]
  );

  return data;
};

export default useLanguages;
