// @ts-nocheck
import React, { useCallback, useRef } from "react";
import cn from "@/libs/cn";
import PublicIcons from "@/components/basic/PublicIcon";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";

export interface PropsTextInput {
  value?: string;
  label?: string;
  type?: "textarea" | "text" | "number" | string;
  className?: string;
  onChange?: (e: Event) => void;
  onKeyPress?: (e: Event) => void;
  onBlur?: (e: React.FocusEventHandler<HTMLInputElement>) => void;
  onClean?: () => void;
  hideButtonClean?: boolean;
  icon?: JSX.Element;
  placeholder?: string;
  replaceString?: string;
}

const TextInput = (props: PropsTextInput) => {
  const {
    value,
    label,
    type = "text",
    className,
    onChange = (e: Event) => {},
    onBlur = (e) => {},
    onKeyPress = () => {},
    onClean: onCleanOut = () => {},
    hideButtonClean,
    icon,
    replaceString,
    disabled,
    ...rest
  } = props;

  const ref = useRef(null);

  const handleChange = useCallback(
    (event: Event) => {
      const { value } = event.target;

      if (type === "number") {
        const newValue = value.replace(/[^\d.]/gi, "");
        event.target.value = newValue;
      }

      onChange(event);
    },
    [onChange, type]
  );

  const onClean = useCallback(() => {
    ref.current.value = "";
    handleChange({ target: { value: "", name: rest.name } });
    onCleanOut();
  }, [handleChange, onCleanOut, rest.name]);

  const handleBlur = useCallback(
    (e: Event) => {
      onBlur(e);
    },
    [onBlur]
  );

  const Tag = type === "textarea" ? type : "input";
  const showCleanButton = !disabled && !!value && !hideButtonClean;

  return (
    <>
      {!!label && <div className={stylesForms.basicLabel}>{label}</div>}
      <div
        className={cn(
          stylesForms.basicWrapInput,
          type === "textarea" && stylesForms.textarea,
          className
        )}
      >
        {icon && <div className={styles.icon}>{icon}</div>}

        <Tag
          ref={ref}
          value={value}
          className={cn(
            styles.input,
            icon && styles.withIcon,
            showCleanButton && styles.showCleanButton,
            className
          )}
          type={type === "number" ? "text" : type}
          disabled={disabled}
          {...rest}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={onKeyPress}
        />

        {showCleanButton && (
          <PublicIcons
            src={PublicIcons.names.close2}
            className={styles.clean}
            onClick={onClean}
            alt="clean"
          />
        )}
      </div>
    </>
  );
};

export default TextInput;
