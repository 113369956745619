const API_ACCOUNTS = `/accounts/`;
const API_REGISTERING = `/accounts/registering/`;
const API_TOKEN = `/accounts/`;
const API_SOCIAL_AUTH = `/rest-auth/`;

const apiAuth = {
  signIn: () => `${API_ACCOUNTS}login/`,
  logout: () => `${API_ACCOUNTS}blacklist/`,

  tokenRefresh: () => `${API_TOKEN}refresh/`,
  tokenVerify: () => `${API_TOKEN}verify/`,

  googleAuth: () => `${API_SOCIAL_AUTH}google/`,
  facebookAuth: () => `${API_SOCIAL_AUTH}facebook/`,
  appleAuth: () => `${API_SOCIAL_AUTH}apple/`,
  instagramAuth: () => `${API_SOCIAL_AUTH}instagram/`,

  profile: () => `${API_ACCOUNTS}profile/`,
  profilePhoto: () => `${API_ACCOUNTS}profile/`,

  changePassword: () => `${API_ACCOUNTS}reset-password/`,

  authSignUp: () => `${API_REGISTERING}register/`,
  authResetPassword: () => `${API_REGISTERING}reset-password/`,
  authForgotPassword: () => `${API_REGISTERING}send-reset-password-link/`,
  authVerifyRegistration: () => `${API_REGISTERING}verify-registration/`,

  authLogout: () => `${API_ACCOUNTS}logout/`,

  // TODO not tested
  authRestoreAccount: () => `${API_ACCOUNTS}restore_account/`,
  authVerifyAccountRestoration: () =>
    `${API_ACCOUNTS}verify_account_restoration/`,

  authResendEmail: () => `${API_ACCOUNTS}resend-email/`,

  authConfirmEmail: () => `${API_ACCOUNTS}confirm-email/`,
  authRegisterEmail: () => `${API_ACCOUNTS}register-email/`,
  authVerifyEmail: () => `${API_ACCOUNTS}verify-email/`,

  authDeleteProfile: () => `${API_ACCOUNTS}delete/`,

  sessionChooseCurrencies: () => `/currencies/choose_currency/`,
  sessionChooseCountry: () => `/country/choose_country/`,
  sessionChooseLanguage: () => `/languages/choose_language/`,

  currencies: () => `/currencies/`,
  currency: (code: string) => `/currencies/${code}`,
  languages: () => `/languages/`,
  language: (code: string) => `/languages/${code}`,

  languageText: (code: string) => `${global.location.origin}/lang/${code}.json`,
  dependencies: () => `${global.location.origin}/countries/dependencies.json`,
  getAllCountries: () => `${global.location.origin}/countries/data.json`,
  getCountries: () => `/country/`,
};

export default apiAuth;
