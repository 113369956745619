import React, { useCallback, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import useGlobalContext from "@/context/GlobalContext";
import useRouter from "@/hooks/useRouter";

import authRequests from "@/modules/auth/requests";

import useValidation from "@/hooks/useValidation";
import FieldTextInput from "@/controls/fields/FieldTextInput";
import FieldDate from "@/controls/fields/FieldDate";
import LinkToModal from "@/components/basic/LinkToModal";
import ButtonSubmit from "@/controls/ButtonSubmit";

import { schemaProfile } from "./validation/schemaProfile";
import ListenerFormikValuesChanges from "@/components/form/ListenerFormikValuesChanges";
import FieldCheckbox from "@/controls/fields/FieldCheckbox";
import FieldDropdown from "@/controls/fields/FieldDropdown";
import { useDataListGenders } from "../hooks/useData";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";

interface FormProfile {
  isSeller?: boolean;
  initValues?: { [key: string]: any };
  onSuccess?: () => void;
  classNameForm?: string;
  classNameFields?: string;
  classNameSubmit?: string;
}

const FormProfile = ({
  isSeller = false,
  initValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  },
  onSuccess = () => {},
  classNameForm,
  classNameFields,
  classNameSubmit,
}: FormProfile) => {
  const {
    translate,
    isIOS,
    toast,
    modules: {
      auth: { actions: authActions },
    },
  } = useGlobalContext();
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const [isModify, setModify] = useState(false);

  const requiredFields = useMemo(
    () =>
      isSeller
        ? {
            first_name: true,
            last_name: true,
            email: true,
            phone: true,
            password: true,
          }
        : {
            first_name: true,
            last_name: true,
            phone: true,
            email: true,
            password: true,
          },
    [isSeller]
  ) as any;

  const initialValues = useMemo(() => initValues, [initValues]);

  const { scheme, setAsyncError } = useValidation({
    scheme: schemaProfile({ translate }),
    requiredFields,
  });

  const actionUpdateProfile = useCallback(
    async (data: any, helper: any) => {
      if (data.birth_date === "") {
        data.birth_date = null;
      }

      data.gender = data.gender || undefined;

      const result = await authRequests.updateProfile(data).catch((error) => {
        if (error?.detail) {
          toast.error(error?.detail);
        }
        setAsyncError(helper, error);
      });

      if (result) {
        toast.info(translate("Profile updated success"));
      }

      onSuccess();
    },
    [onSuccess, setAsyncError, toast, translate]
  );

  const handleSubmit = useCallback(
    async (values: any, helpers: any) => {
      setLoading(true);

      const { id, is_seller, ...rest } = values;
      await actionUpdateProfile(rest, helpers);

      setLoading(false);
    },
    [actionUpdateProfile]
  );

  /*
  TODO Not implemented

  const onDeleteHandler = useCallback(async () => {
    await actions.auth
      .deleteProfile()
      .then(() => {
        navigate(sallerRoutes.home);
        toast.success(translate(`Your profile has been successfully deleted.`));
      })
      .catch(() => {
        setLoading(false);
        toast.error(translate("Ooops!"));
      });
  }, [navigate, toast, translate]);
  */

  const { data } = useDataListGenders();

  const optionsGenders = useMemo(
    () => data.map((item: any) => ({ value: item.code, label: item.value })),
    [data]
  );

  return (
    <Formik
      validationSchema={scheme}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form className={classNameForm}>
        <ListenerFormikValuesChanges
          initValues={initValues}
          onListen={setModify}
        />
        <div className={classNameFields}>
          <div className={stylesForms.row}>
            <FieldTextInput name="first_name" label={translate("First name")} />
            <FieldTextInput name="last_name" label={translate("Last name")} />
          </div>
          <div className={stylesForms.row}>
            <FieldDropdown
              name="gender"
              options={optionsGenders}
              label={translate("Gender")}
            />
            <FieldDate
              name="birth_date"
              label={translate("Birth Day")}
              max={new Date().toJSON().split("T")[0]}
              isIOS={isIOS}
            />
          </div>
          <div className={stylesForms.row}>
            <FieldTextInput
              name="email"
              label={translate("Email")}
              disabled
              // onClick={() => authActions.openChangeEmail()}
            />
            <FieldTextInput name="phone" label={translate("Phone number")} />
          </div>
        </div>
        <div className={styles.links}>
          <LinkToModal onClick={authActions.openNewPassword}>
            {translate("Change password")}
          </LinkToModal>
          {/* <LinkToModal
                className={styles.delete}
                onClick={() => {
                  onOpenConfirmModalHandler({
                    onConfirm: onDeleteHandler,
                    title: translate(
                      "Your profile will be deleted. Are you sure?"
                    ),
                    Icon: Warning,
                    conformButtonText: translate("Delete profile"),
                    classNameIcon: styles.warningIcon,
                    classNameButton: styles.warningButton,
                  });
                }}
              >
                {translate("Delete profile")}
              </LinkToModal> */}
        </div>
        <ButtonSubmit
          className={classNameSubmit}
          loading={loading}
          // disabled={!isModify}
        >
          {translate("Save")}
        </ButtonSubmit>
      </Form>
    </Formik>
  );
};

export default FormProfile;
