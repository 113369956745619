import { useCallback, useMemo } from "react";
import useSWR, { SWRConfig, SWRConfiguration } from "swr";
import {
  crudFetcherCommon,
  crudFetcherNextApi,
  crudFetcherSeller,
} from "services/crud";
import useContentLanguage from "@/hooks/useContentLanguage";

interface CustomSWRConfig {
  children: JSX.Element;
  onUnauthorized: () => void;
  locale?: string;
}

export const CustomSWRConfig = ({
  children,
  onUnauthorized,
  locale,
}: CustomSWRConfig) => {
  const fetcher = useCallback(
    (resource: any, options: any = {}, ...rest: any) => {
      const { crudFetcher, contentLanguage } = options;

      let headers = {} as any;
      if (contentLanguage || locale) {
        headers["Accept-Language"] = contentLanguage || locale;
      }

      return crudFetcher
        ? crudFetcher
            .get({
              url: resource,
              options: { headers },
            })
            .catch((err: any) => {
              if (err?.code === "token_not_valid") onUnauthorized();
            })
        : fetch(resource, { ...rest }).then((res) => res.json());
    },
    [locale, onUnauthorized]
  );

  return (
    <SWRConfig
      value={{
        //   provider: customProvider,
        fetcher,
        //   refreshInterval: 10000,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          // Never retry on 404.
          if (error.status === 404) return;

          // Never retry for a specific key.
          if (key === "/api/user") return;

          // Only retry up to 10 times.
          if (retryCount >= 10) return;

          // Retry after 5 seconds.
          setTimeout(() => revalidate({ retryCount }), 5000);
        },
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            // We can send the error to Sentry,
            // or show a notification UI.
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

const addContentParam = (url: string, valueLang?: string) => {
  if (!valueLang) {
    return url;
  }

  if (url.indexOf("?") !== -1) {
    return `${url}&content-language=${valueLang}`;
  } else {
    return `${url}?content-language=${valueLang}`;
  }
};

interface useCommonSWR {
  key: any;
  options?: SWRConfiguration;
  defaultData?: any;
  isSensitiveContentLanguage?: boolean;
  normalizeData?: (data: any) => any;
}

export const useNextApiSWR = ({
  key,
  options,
  defaultData,
  normalizeData,
}: useCommonSWR) => {
  const { data, error, mutate, isValidating } = useSWR(
    [key, { crudFetcher: crudFetcherNextApi }],
    options
  );

  return useMemo(
    () => ({
      data: normalizeData
        ? normalizeData(data || defaultData)
        : data || defaultData,
      error,
      mutate,
      isValidating,
      isLoading: !data && !error,
    }),
    [data, defaultData, error, isValidating, mutate, normalizeData]
  );
};

export const useCommonSWR = ({
  key,
  options,
  defaultData,
  isSensitiveContentLanguage,
  normalizeData,
}: useCommonSWR) => {
  const value = useContentLanguage();

  const { data, error, mutate, isValidating } = useSWR(
    [
      isSensitiveContentLanguage ? `${addContentParam(key, value)}` : key,
      {
        crudFetcher: crudFetcherCommon,
        contentLanguage: isSensitiveContentLanguage ? value : undefined,
      },
    ],
    options
  );

  return useMemo(
    () => ({
      data: normalizeData
        ? normalizeData(data || defaultData)
        : data || defaultData,
      error,
      mutate,
      isValidating,
      isLoading: !data && !error,
    }),
    [data, defaultData, error, isValidating, mutate, normalizeData]
  );
};

export const useSellerSWR = ({
  key,
  options,
  defaultData,
  isSensitiveContentLanguage,
  normalizeData,
}: useCommonSWR) => {
  const value = useContentLanguage();

  const { data, error, mutate, isValidating } = useSWR(
    [
      isSensitiveContentLanguage ? `${addContentParam(key, value)}` : key,
      {
        crudFetcher: crudFetcherSeller,
        contentLanguage: isSensitiveContentLanguage ? value : undefined,
      },
    ],
    options
  );

  return useMemo(
    () => ({
      data: normalizeData
        ? normalizeData(data || defaultData)
        : data || defaultData,
      error,
      mutate,
      isValidating,
      isLoading: !data && !error,
    }),
    [data, defaultData, error, isValidating, mutate, normalizeData]
  );
};
