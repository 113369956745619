import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Cache, useSWRConfig } from "swr";
import { typeToken } from "@/constants/common";
import { CustomSWRConfig } from "@/libs/swr";
import authRequests from "@/modules/auth/requests";
import { deleteCookieAuth, getCookie } from "@/utils/cookie";
import tokenStorage from "@/utils/token";
import useIsSSR from "./useIsSSR";

const TIMEOUT_REFRESH_TOKEN = 1 * 60 * 1000;

const autoRefreshToken = (onUnauthorized: (flag: boolean) => void) => {
  const refresh = tokenStorage.getRefreshToken();

  if (refresh) {
    authRequests
      .tokenRefresh({ refresh })
      .then(({ access }: any) => {
        tokenStorage.set(
          { token: access, tokenRefresh: refresh },
          !tokenStorage.isSaveInSession()
        );
      })
      .catch((err) => {
        if (err?.code === "token_not_valid") {
          onUnauthorized(false);
        }
      });
  }
};

const useAutoRefreshToken = (onUnauthorized: (flag: boolean) => void) => {
  const onRefresh = useCallback(
    () => autoRefreshToken(onUnauthorized),
    [onUnauthorized]
  );

  useEffect(() => {
    const timerId = setInterval(onRefresh, TIMEOUT_REFRESH_TOKEN);

    return () => clearInterval(timerId);
  }, [onRefresh]);
};

const useUser = (providers: any[], locale?: string) => {
  const cacheRef = useRef<Cache<any>>(null);
  const isSSR = useIsSSR();
  const [isReady, setReady] = useState(false);
  const [isAuthorized, setAuthorized] = useState(false);
  const [isSeller, setSeller] = useState(false);

  useAutoRefreshToken(setAuthorized);

  const initHandlerAnonymous = useCallback(() => {
    setReady(true);
  }, []);

  const initHandler = useCallback(
    (tokenAuth: string) => {
      authRequests
        .tokenVerify({ token: tokenAuth })
        .then(() => {
          providers.map((provider) =>
            provider.setAuthHeader(
              typeToken,
              typeToken === "bearer"
                ? tokenStorage.getBearerToken()
                : tokenStorage.getCSRFToken()
            )
          );

          authRequests.getProfile().then((response) => {
            setSeller(response?.is_seller === true);
            setAuthorized(true);
            setReady(true);
          });
        })
        .catch((err: any) => {
          console.warn("initHandler", err);

          if (err?.code === "token_not_valid") {
            initHandlerAnonymous();
            return;
          }

          setReady(true);
        });
    },
    [initHandlerAnonymous, providers]
  );

  useEffect(() => {
    if (isSSR) {
      return;
    }

    const tokenAuth = tokenStorage.getTokenAuth();

    if (!tokenAuth) {
      deleteCookieAuth();
    }

    if (!!tokenAuth) {
      initHandler(tokenAuth);
    } else {
      const anonymousId = tokenStorage.getTokenAnonymous();

      if (!anonymousId) {
        initHandlerAnonymous();
      } else {
        providers.map((provider) => provider.setAnonymousHeader(anonymousId));
        setReady(true);
      }
    }
  }, [initHandler, initHandlerAnonymous, isSSR, providers]);

  const setAuthToken = useCallback(
    async (data: { access: string; refresh: string }, remember?: boolean) => {
      // @ts-ignore
      cacheRef.current.clear();

      let tokenAccess = "";
      let tokenRefresh = "";

      if (typeToken === "bearer") {
        tokenAccess = data.access;
        tokenRefresh = data.refresh;
      } else {
        tokenAccess = getCookie("csrftoken") || "";
      }

      tokenStorage.set({ token: tokenAccess, tokenRefresh }, remember);
      providers.map((provider) =>
        provider.setAuthHeader(
          typeToken,
          typeToken === "bearer"
            ? tokenStorage.getBearerToken()
            : tokenStorage.getCSRFToken()
        )
      );

      const response = await authRequests.getProfile();
      setSeller(response?.is_seller === true);
      setAuthorized(true);
      setReady(true);
    },
    [providers]
  );

  const eraseAuthToken = useCallback(() => {
    providers.map((provider) => provider.removeAuthHeader());
    tokenStorage.erase();

    setAuthorized(false);
  }, [providers]);

  const SWRConfig = useCallback(
    ({ children }: any) => (
      <CustomSWRConfig onUnauthorized={eraseAuthToken} locale={locale}>
        <>
          <CacheReset link={cacheRef} />
          {children}
        </>
      </CustomSWRConfig>
    ),
    [eraseAuthToken, locale]
  );

  return useMemo(
    () => ({
      isReady,
      isAuthorized,
      isSeller,
      setAuthToken,
      eraseAuthToken,
      SWRConfig,
    }),
    [isReady, isAuthorized, isSeller, setAuthToken, eraseAuthToken, SWRConfig]
  );
};

interface CacheReset {
  link: RefObject<Cache<any>>;
}

const CacheReset = ({ link }: CacheReset) => {
  const { cache } = useSWRConfig();

  useEffect(() => {
    // @ts-ignore
    link.current = cache;
  }, [cache, link]);

  return null;
};

export default useUser;
