import { APP_API, APP_NEXT_API, APP_SELLER_API } from "@/constants/common";
import crudFetcher from "@/libs/crudFetcher";
import dataProvider from "@/libs/dataProvider";
import tokenStorage from "@/utils/token";

export const dataProviderNextApi = dataProvider.getInstance(APP_NEXT_API);
export const crudFetcherNextApi = new crudFetcher(
  dataProviderNextApi,
  tokenStorage
);

export const dataProviderCommon = dataProvider.getInstance(APP_API);
export const crudFetcherCommon = new crudFetcher(
  dataProviderCommon,
  tokenStorage
);

export const dataProviderSeller = dataProvider.getInstance(APP_SELLER_API);
export const crudFetcherSeller = new crudFetcher(
  dataProviderSeller,
  tokenStorage
);
