import cn from "@/libs/cn";
import React, { useCallback } from "react";
import LoaderCircle from "@/controls/LoaderCircle";

import styles from "./styles.module.scss";

export interface PropsButton {
  type?: "submit" | "button" | "reset" | "link";
  rel?: string;
  children?: any;
  text?: string;
  href?: string;
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary";
  variant?: "contained" | "text" | "outlined";
  onClick?: (e: Event) => void;
  icon?: () => JSX.Element;
  width?: string | number;
  height?: string | number;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

const Button = ({
  type = "button",
  rel,
  children,
  text,
  href,
  size = "medium",
  color,
  variant = "contained",
  onClick = () => {},
  icon: Icon,
  width,
  height,
  className,
  loading,
  disabled,
}: PropsButton) => {
  const onClickHandler = useCallback(
    (e: Event) => {
      onClick(e);
    },
    [onClick]
  );

  const Tag = !!href ? "a" : "button";

  return (
    <Tag
      type={type !== "link" ? type : undefined}
      rel={rel}
      className={cn(
        styles.Button,
        size === "small" && styles.sizeSmall,
        size === "large" && styles.sizeLarge,
        color === "primary" && styles.colorPrimary,
        color === "secondary" && styles.colorSecondary,
        variant === "contained" && styles.variantContained,
        variant === "text" && styles.variantText,
        variant === "outlined" && styles.variantOutlined,
        className
      )}
      href={href}
      // @ts-ignore
      onClick={onClickHandler}
      style={{ width, height }}
      disabled={disabled || loading}
    >
      {text ? <div className={styles.text}>{text}</div> : children}
      {Icon && <Icon />}
      {loading && <LoaderCircle size={20} className={styles.loader} />}
    </Tag>
  );
};

export default Button;
