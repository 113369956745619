import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import useGlobalContext from "@/context/GlobalContext";
import cn from "@/libs/cn";
import routesPublic from "@/modules/public/constants/routesPublic";
import { useSlugByCategory } from "@/modules/public/hooks/useCategorySlug";
import { useDataPublicTopCategories } from "@/modules/public/hooks/useData";
import React, { useState } from "react";

import styles from "./styles.module.scss";

const PREVIEW_COUNT_ITEMS = 100;

interface ItemCategory {
  id: number;
  name: string;
  icon: string;
}

const ItemCategory = ({ id, name, icon }: ItemCategory) => {
  const slug = useSlugByCategory(id);

  return (
    <Link
      href={slug ? `${routesPublic.category()}${slug}/` : ""}
      key={id}
      className={styles.option}
    >
      <Image src={icon} width={40} height={40} alt={`Category: ${name}`} />
      <div className={styles.label} title={name}>
        {name}
      </div>
    </Link>
  );
};

const ButtonCategories = () => {
  const { translate } = useGlobalContext();
  const categories = useDataPublicTopCategories();
  const isLoaded = !!categories.data && !categories.error;
  const [show, setShow] = useState(false);
  const [all, setAll] = useState(false);

  return (
    <div className={styles.ButtonCategories}>
      <div className={styles.button} onClick={() => setShow(!show)}>
        <Image src="/assets/menu.svg" alt="menu" width={24} height={24} />
        <span className={styles.label}>{translate("All categories")}</span>
        <Image
          src="/assets/arrow-down-border.svg"
          width={24}
          height={24}
          alt="button arrow"
        />
      </div>
      {show && <div className={styles.back} onClick={() => setShow(false)} />}
      <div
        className={cn(styles.list, show && styles.show)}
        onClick={() => setShow(false)}
      >
        {isLoaded &&
          (all
            ? categories.data
            : categories.data.slice(0, PREVIEW_COUNT_ITEMS)
          ).map((props: any) => <ItemCategory key={props.id} {...props} />)}
        {/* <div>SHOW ALL</div> */}
      </div>
    </div>
  );
};

export default ButtonCategories;
