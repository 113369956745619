import React from "react";
import LoaderCircle from "@/controls/LoaderCircle";
import { useDataPreferences } from "../hooks/useData";

import PreferencesAll from "../modals/PreferencesAll";

import styles from "./styles.module.scss";

const PageSettings = () => {
  const { data } = useDataPreferences();

  return data ? (
    <div className={styles.content}>
      <PreferencesAll />
    </div>
  ) : (
    <LoaderCircle stretchToSpace margin="10%" />
  );
};

export default PageSettings;
