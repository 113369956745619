import { getCookie } from "@/utils/cookie";
import tokenStorage from "@/utils/token";
import { crudFetcherCommon } from "services/crud";

import apiAuth from "./constants/apiAuth";

const getProfile = () => {
  return crudFetcherCommon
    .get({
      url: apiAuth.profile(),
    });
};

const signIn = ({
  remember,
  password,
  email,
  googleToken,
  facebookToken,
  appleToken,
}: any) => {
  const options = {
    headers: {
      "x-sid": getCookie("sid"),
    },
  };

  const onSuccess = (response: any) => {
    // getProfile();
  };

  if (googleToken) {
    return crudFetcherCommon.post({
      url: apiAuth.googleAuth(),
      requestData: { access_token: googleToken },
      options,
    });
  }

  if (facebookToken) {
    return crudFetcherCommon.post({
      url: apiAuth.facebookAuth(),
      requestData: { access_token: facebookToken },
      options,
    });
  }

  if (appleToken) {
    return crudFetcherCommon.post({
      url: apiAuth.appleAuth(),
      requestData: { access_token: appleToken },
      options,
    });
  }

  return crudFetcherCommon.post({
    url: apiAuth.signIn(),
    requestData: {
      password,
      email,
    },
    options,
  });
};

const instagramLogin = ({ code }: {code: string}) => {
  return crudFetcherCommon.post({
    url: apiAuth.instagramAuth(),
    requestData: {
      code,
    },
  });
}

const tokenRefresh = ({ refresh }: {refresh: string}) => {
  return crudFetcherCommon.post({
    url: apiAuth.tokenRefresh(),
    requestData: {
      refresh,
    },
  });
};

const tokenVerify = ({ token }: any) => {
  return crudFetcherCommon
    .post({
      url: apiAuth.tokenVerify(),
      requestData: {
        token,
      },
    });
};

const signUp = (requestData: any) => {
  return crudFetcherCommon
    .post({
      url: apiAuth.authSignUp(),
      requestData,
    });
};

const forgotPassword = ({ email }: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authForgotPassword(),
    requestData: { login: email },
  });
};

const restoreAccount = ({ email }: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authRestoreAccount(),
    requestData: { email },
  });
};

const resendEmail = (email: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authResendEmail(),
    requestData: { email },
  });
};

const updatePhone = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.profile(),
    requestData,
  });
};

const changePassword = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.changePassword(),
    requestData,
  });
};

const resetPassword = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authResetPassword(),
    requestData,
  });
};

const updatePhoto = (requestData: any) => {
  const formdata = new FormData();
  if (requestData.name !== "undefined") {
    formdata.append("avatar", requestData);
  } else {
    formdata.append(
      "avatar",
      new File([requestData], `avatar-${Date.now()}.jpeg`)
    );
  }

  return crudFetcherCommon.patch({
    url: apiAuth.profilePhoto(),
    requestData: formdata,
  });
};

const updateProfile = (requestData: any) => {
  const { avatar, birthday, ...rest } = requestData;
  const data = rest;
  data.birthday = birthday;

  const isDeletePhoto =
    (typeof avatar === "string" && avatar?.length === 0) || avatar === null;
  const isDeleteBirthday =
    (typeof birthday === "string" && birthday?.length === 0) ||
    birthday === null;

  if (isDeletePhoto) {
    data.avatar = null;
  }
  if (isDeleteBirthday) {
    data.birthday = null;
  }

  return crudFetcherCommon.put({
    url: apiAuth.profile(),
    // action: sliceAuth.actions.updateProfile,
    requestData: data,
  });
};

const setCountry = (countryCode: string) => {
  const data = { country: countryCode.toUpperCase() };
  return crudFetcherCommon.put({
    url: apiAuth.profile(),
    // action: sliceAuth.actions.updateProfile,
    requestData: data,
  });
};

const setLanguage = (langCode: string) => {
  const data = { language: langCode.toUpperCase() };
  return crudFetcherCommon.put({
    url: apiAuth.profile(),
    // action: sliceAuth.actions.updateProfile,
    requestData: data,
  });
};

const setCurrency = (currencyCode: any) => {
  const data = { currency: currencyCode };
  return crudFetcherCommon
    .put({
      url: apiAuth.profile(),
      // action: sliceAuth.actions.updateProfile,
      requestData: data,
    })
    .then(() => {
      // callbackUpdateCart();
    });
};

const registerEmail = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authRegisterEmail(),
    requestData,
  });
};

const verifyEmail = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authVerifyEmail(),
    requestData,
  });
};

const confirmEmail = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authConfirmEmail(),
    // action: sliceAuth.actions.confirmEmail,
    requestData,
  });
};

const verifyRegistartion = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authVerifyRegistration(),
    requestData,
  });
};

const verifyAccountRestoration = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiAuth.authVerifyAccountRestoration(),
    requestData,
  });
};

const deleteProfile = () => {
  return crudFetcherCommon
    .delete({
      url: apiAuth.authDeleteProfile(),
      // action: sliceAuth.actions.deleteProfile,
    })
    .then((response: any) => {
      tokenStorage.erase();
      return response;
    });
};

const getCurrencies = () => {
  return crudFetcherCommon.get({
    url: apiAuth.currencies(),
    // action: sliceAuth.actions.getCurrencies,
    // nameRequestStatus: "getCurrencies",
  });
};

const getLanguages = () => {
  return crudFetcherCommon.get({
    url: apiAuth.languages(),
    // action: sliceAuth.actions.getLanguages,
    // nameRequestStatus: "getLanguages",
  });
};

const getLanguageText = (code: string) => {
  return crudFetcherCommon.get({
    url: apiAuth.languageText(code.toLowerCase()),
    // nameRequestStatus: "getLanguageText",
  });
};

const getAllCountries = () => {
  return crudFetcherCommon.get({
    url: apiAuth.getAllCountries(),
    // action: sliceAuth.actions.getAllCountries,
    // nameRequestStatus: "getAllCountries",
  });
};

const getCountries = () => {
  return crudFetcherCommon.get({
    url: apiAuth.getCountries(),
    // action: sliceAuth.actions.getCountries,
    // nameRequestStatus: "getCountries",
  });
};

const getDependencies = () => {
  return crudFetcherCommon.get({
    url: apiAuth.dependencies(),
    // action: sliceAuth.actions.getDependencies,
    // nameRequestStatus: "getDependencies",
  });
};

const logout = () => {
  const refresh = tokenStorage.getRefreshToken();

  return crudFetcherCommon.post({
    url: apiAuth.logout(),
    requestData: { refresh },
  });
};

const authRequests = {
  getProfile,
  signIn,
  instagramLogin,
  tokenRefresh,
  tokenVerify,
  signUp,
  // signOut,
  forgotPassword,
  restoreAccount,
  resendEmail,
  updatePhone,
  changePassword,
  resetPassword,
  updatePhoto,
  updateProfile,
  setCountry,
  setLanguage,
  setCurrency,
  registerEmail,
  verifyEmail,
  confirmEmail,
  verifyRegistartion,
  verifyAccountRestoration,
  deleteProfile,
  getCurrencies,
  getLanguages,
  getLanguageText,
  getAllCountries,
  getCountries,
  getDependencies,
  logout,
};

export default authRequests;
