import Tree from "@/components/Tree";
import CustomScroll from "@/components/CustomScroll";
import { useCallback, useMemo } from "react";
import Checkbox from "../Checkbox";

import styles from "./styles.module.scss";
import cn from "@/libs/cn";

const valueToStr = (i: any) => `${i}`;
// @ts-ignore
const removeDuplicates = (arr) => [...new Set(arr.map(valueToStr))];

interface ComponentItem {
  item: any;
  isActive: boolean;
  multiple: boolean;
  selected: any[];
  highlightValue?: boolean;
  onClickItem: (item: any) => void;
  onSelect: (item: any, checked: boolean) => void;
}

const ComponentItem = ({
  item,
  isActive,
  multiple,
  selected,
  onClickItem,
  onSelect,
}: ComponentItem) => {
  const onClick = useCallback(
    (e: any) => {
      if (!multiple) {
        onClickItem(item);
      } else {
        e.stopPropagation();
      }
    },
    [item, multiple, onClickItem]
  );

  const onSelectItem = useCallback(
    (e: any) => {
      onSelect(item, e.target.checked);
    },
    [item, onSelect]
  );

  return (
    <div className={styles.label} title={item.name} onClick={onClick}>
      {multiple && (
        <Checkbox
          isCompact
          onChange={onSelectItem}
          checked={selected.map(valueToStr).includes(`${item.id}`)}
        />
      )}
      <div className={styles.name}>
        {`${item.name} ${
          item.products_count ? `(${item.products_count})` : ""
        }`}
      </div>
    </div>
  );
};

interface TreeView {
  data?: any[];
  rootId?: any;
  label?: string;
  minHeight?: number | string;
  maxHeight?: number | string;
  onClickItem?: (item: any) => void;
  selected?: any[];
  value?: any;
  highlightValue?: boolean;
  onSelect?: (newSelected: any[], dataSelected: any[]) => void;
  multiple?: boolean;
  onlyNodal?: boolean;
  className?: string;
  classNameScrollContent?: string;
}

const TreeView = ({
  // activeId,
  data = [],
  rootId = null,
  label = "",
  minHeight = 150,
  maxHeight = 200,
  onClickItem = () => {},
  selected: initSelected = [],
  value,
  highlightValue,
  onSelect = () => {},
  multiple = false,
  onlyNodal = false,
  className,
  classNameScrollContent,
}: TreeView) => {
  const selectChildren = useCallback(
    (selected: any[], parentId: any, checked: any) => {
      if (checked) {
        const childrenSelected = data
          // eslint-disable-next-line eqeqeq
          .filter((item) => item.parent == parentId)
          .map((item) => item.id);

        return [...selected, ...childrenSelected];
      } else {
        const childrenSelected = selected.filter((id) => {
          // eslint-disable-next-line eqeqeq
          const find = data.find((item) => item.id == id);

          if (!find) {
            return true;
          }

          // eslint-disable-next-line eqeqeq
          if (find.parent == parentId) {
            return false;
          }

          return true;
        });

        return [...childrenSelected];
      }
    },
    [data]
  );

  // if all sibling is checked
  const selectParent = useCallback(
    (selected: any[], currentId: any) => {
      // eslint-disable-next-line eqeqeq
      const find = data.find((item) => item.id == currentId);

      if (!find) {
        return selected;
      }

      // eslint-disable-next-line eqeqeq
      const children = data.filter((item) => item.parent == find.parent);

      if (
        children.every((child) =>
          selected.map(valueToStr).includes(`${child.id}`)
        )
      ) {
        return [...selected, find.parent];
      }

      return selected;
    },
    [data]
  );

  const unselectParent = useCallback(
    (selected: any[], currentId: any) => {
      // eslint-disable-next-line eqeqeq
      const find = data.find((item) => item.id == currentId);

      if (!find) {
        return selected;
      }

      // eslint-disable-next-line eqeqeq
      return selected.filter((id) => id != find.parent);
    },
    [data]
  );

  const getNodal = useCallback(
    (selected: any[]) => {
      let newSelected = selected.filter((id) => {
        // eslint-disable-next-line eqeqeq
        const find = data.find((item) => item.id == id);

        if (!find) {
          return false;
        }

        // eslint-disable-next-line eqeqeq
        const findParent = data.find((item) => item.id == find.parent);

        if (!findParent) {
          return true;
        }

        return !selected.map(valueToStr).includes(`${findParent.id}`);
      });

      return newSelected;
    },
    [data]
  );

  const treeSelected = useMemo(() => {
    let newSelected = [...initSelected];
    let newItems: any[] = [];
    newSelected.forEach((id) => {
      newItems = selectChildren(newItems, id, true);
    });

    return [...newSelected, ...newItems];
  }, [initSelected, selectChildren]);

  const dataOfSelected = useCallback(
    (selected: any[]) =>
      data.filter((item) => selected.map(valueToStr).includes(`${item.id}`)),
    [data]
  );

  const onSelectItem = useCallback(
    (item: { id: any }, checked: any) => {
      // eslint-disable-next-line eqeqeq
      let newSelected = treeSelected.filter((id) => id != item.id);

      if (checked) {
        newSelected = [...newSelected, item.id];
        newSelected = selectChildren(newSelected, item.id, true);
        newSelected = selectParent(newSelected, item.id);
        newSelected = removeDuplicates(newSelected);

        newSelected = onlyNodal ? getNodal(newSelected) : newSelected;
        const dataSelected = dataOfSelected(newSelected);
        onSelect(newSelected, dataSelected);
      } else {
        newSelected = selectChildren(newSelected, item.id, false);
        newSelected = unselectParent(newSelected, item.id);
        newSelected = removeDuplicates(newSelected);

        newSelected = onlyNodal ? getNodal(newSelected) : newSelected;
        const dataSelected = dataOfSelected(newSelected);
        onSelect(newSelected, dataSelected);
      }
    },
    [
      dataOfSelected,
      getNodal,
      treeSelected,
      onSelect,
      onlyNodal,
      selectChildren,
      selectParent,
      unselectParent,
    ]
  );

  return (
    <div className={styles.TreeView}>
      {!!label && <h3>{label}</h3>}
      <CustomScroll
        minHeight={minHeight}
        maxHeight={maxHeight}
        className={className}
        classNameContent={cn(styles.content, classNameScrollContent)}
      >
        <Tree
          data={data}
          rootId={rootId}
          parentId={rootId}
          value={value}
          highlightValue={highlightValue}
          componentItem={(props: JSX.IntrinsicAttributes & ComponentItem) => (
            <ComponentItem
              {...props}
              selected={treeSelected}
              multiple={multiple}
              onSelect={onSelectItem}
              onClickItem={onClickItem}
            />
          )}
        />
      </CustomScroll>
    </div>
  );
};

export default TreeView;
