import { ValueRoute } from "@/types/modules/interface-types";

export const baseRoute = "cart";

export type TypeRoutes = {
  home: ValueRoute;
  details: ValueRoute;
  checkout: ValueRoute;
  paymentSuccess: ValueRoute;
  paymentCanceles: ValueRoute;
};

const routesCart = {
  home: () => `/${baseRoute}`,
  details: () => `/${baseRoute}/details/`,
  checkout: () => `/${baseRoute}/checkout/`,
  paymentSuccess: () => `/${baseRoute}/payment-success/`,
  paymentCanceles: () => `/${baseRoute}/payment-cancel/`,
} as TypeRoutes;

export default routesCart;
