// @ts-nocheck
import Waiting from "@/components/basic/Waiting";
import dynamic from "next/dynamic";
import { useMemo } from "react";
import { ArgsOpenModal, UseInterface } from "types/modules/interface-types";

const FormLogIn = dynamic(() => import("../forms/FormLogIn"), {
  ssr: false,
  suspense: false,
});

const FormSignUp = dynamic(() => import("../forms/FormSignUp"), {
  ssr: false,
  suspense: false,
});

const FormRecoveryPassword = dynamic(
  () => import("../forms/FormRecoveryPassword"),
  {
    ssr: false,
    suspense: false,
  }
);

const FormRestoreAccount = dynamic(
  () => import("../forms/FormRestoreAccount"),
  {
    ssr: false,
    suspense: false,
  }
);

const FormChangeEmail = dynamic(() => import("../forms/FormChangeEmail"), {
  ssr: false,
  suspense: false,
});

const FormNewPassword = dynamic(() => import("../forms/FormNewPassword"), {
  ssr: false,
  suspense: false,
});

const useActions = ({ openModal }: UseInterface) => {
  return useMemo(() => {
    return {
      openLogIn: (props) => {
        openModal((heplers) => {
          return (
            <Waiting>
              <FormLogIn {...props} />
            </Waiting>
          );
        });
      },
      openSignUp: () => {
        openModal(() => {
          return (
            <Waiting>
              <FormSignUp />
            </Waiting>
          );
        });
      },
      openRecoveryPassword: (props) => {
        openModal(() => {
          return (
            <Waiting>
              <FormRecoveryPassword {...props} />
            </Waiting>
          );
        });
      },
      openRestoreAccount: () => {
        openModal(() => {
          return (
            <Waiting>
              <FormRestoreAccount />
            </Waiting>
          );
        });
      },
      // TODO not implemented backend
      openChangeEmail: (props = {}) => {
        openModal(() => {
          return (
            <Waiting>
              <FormChangeEmail {...props} />
            </Waiting>
          );
        });
      },
      openNewPassword: (props = {}) => {
        openModal(() => {
          return (
            <Waiting>
              <FormNewPassword {...props} />
            </Waiting>
          );
        });
      },
      openPhone: () => {},
      openRestoreAccount: () => {},
    };
  }, [openModal]);
};

export default useActions;
