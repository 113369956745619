import { useCallback, useEffect, useState } from "react";
import Router from "next/router";

import styles from "./styles.module.scss";

const ProgressBar = () => {
  const [show, setShow] = useState(false);

  const routeChangeStart = useCallback(() => setShow(true), [setShow]);
  const routeChangeEnd = useCallback(() => setShow(false), [setShow]);

  useEffect(() => {
    Router.events.on("beforeHistoryChange", routeChangeStart);
    Router.events.on("routeChangeStart", routeChangeStart);
    Router.events.on("routeChangeComplete", routeChangeEnd);
    Router.events.on("routeChangeError", routeChangeEnd);
    return () => {
      Router.events.off("beforeHistoryChange", routeChangeStart);
      Router.events.off("routeChangeStart", routeChangeStart);
      Router.events.off("routeChangeComplete", routeChangeEnd);
      Router.events.off("routeChangeError", routeChangeEnd);
    };
  }, [routeChangeEnd, routeChangeStart]);

  return (
    <div className={styles.progressBar}>
      {show ? (
        <div className={styles.spinner} role="spinner">
          <div className={styles.spinnerIcon}></div>
        </div>
      ) : null}
    </div>
  );
};

export default ProgressBar;
