const API_ACCOUNTS = `/accounts/`;
const API_PROFILE = `/accounts/profile/`;
const API_ORDERS = `/orders/`;

const apiProfile = {
  profile: () => `${API_PROFILE}`,
  listGenders: () => `${API_PROFILE}gender-types/`,
  ordersAddresses: () => `${API_ACCOUNTS}addresses/`,
  ordersAddress: (addressId: any) => `${API_ACCOUNTS}addresses/${addressId}/`,
  onboardingStepOne: () => `/accounts/onboarding/step1/`,
  onboardingStepTwo: () => `/accounts/onboarding/step2/`,
  notifications: (tab: any) =>
    `${API_PROFILE}notifications/${tab ? `?type=${tab}` : ""}`,
  preferences: () => `/accounts/preferences/`,
  notificationChannels: () => `/public/notification_channels/`,
};

export default apiProfile;
