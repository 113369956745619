import cn from "@/libs/cn";
import NextImage, { ImageProps } from "next/image";
import { useMemo } from "react";

export interface PropsBaseImage extends ImageProps {
  media?: boolean;
}

const Image = ({
  src,
  media,
  className,
  unoptimized,
  ...rest
}: PropsBaseImage) => {
  let preSrc = useMemo(() => {
    if (media && !`${src}`.startsWith("http")) {
      console.error("Image url without host", src);

      return `${process.env.NEXT_PUBLIC_MEDIA_HOST}${src}`;
    }

    return `${src}`;
  }, [media, src]);

  return (
    <NextImage
      src={preSrc}
      className={cn(className)}
      unoptimized={
        unoptimized || media || !!process.env.NEXT_PUBLIC_UNOPTIMIZED_IMAGE
      }
      {...rest}
    ></NextImage>
  );
};

export default Image;
