import { useMemo } from "react";
import { UseInterface } from "types/modules/interface-types";
import { GalleryInterface } from "./interface-types";

import useActions from "./hooks/useActions";
import routesGallery, { baseRoute } from "./constants/routesGallery";

export const moduleName = "gallery";

export const defaultValue: GalleryInterface = {
  moduleName,
  baseRoute,
  routes: routesGallery,
  actions: {
    openNewCatalog: () => {},
    openRenameCatalog: () => {},
    openRenameFile: () => {},
    openSelectFiles: () => {},
  },
};

export const useInterface = (args: UseInterface): GalleryInterface => {
  const actions = useActions(args);

  return useMemo(
    () => ({
      moduleName,
      baseRoute,
      routes: routesGallery,
      actions,
    }),
    [actions]
  );
};

export default useInterface;
