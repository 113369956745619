const DOMAINE_STAGE = process.env.NEXT_PUBLIC_DOMAINE_STAGE;

export const DOMAINE =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:3000"
    : DOMAINE_STAGE;

export const APP_API =
  process.env.NEXT_PUBLIC_APP_API || `${DOMAINE_STAGE}/api/v1`;

export const APP_SELLER_API =
  process.env.NEXT_PUBLIC_APP_SELLER_API || `${DOMAINE_STAGE}/api/v1`;

export const APP_NEXT_API = `${DOMAINE}/api`;

export const STRIPE_LOCAL_SERVER = process.env.NEXT_PUBLIC_STRIPE_LOCAL_SERVER;

// Authentication type
type typeToken = "bearer" | "CSRF";
export const typeToken: typeToken = "bearer";

export const NAME_PARAM_CONTENT_LANGUAGE = "content-language";
