import LoaderCircle from "@/controls/LoaderCircle";
import useRouter from "@/hooks/useRouter";
import { useDataOrder } from "@/modules/cart/hooks/useData";
import { IApiOrder } from "@/types/interfaces/orders";
import OrderDetails from "../components/order/OrderDetails";

const PageOrder = () => {
  const {
    query: { id },
  } = useRouter();

  const { data } = useDataOrder(id) as { data: IApiOrder };

  return (
    <div>
      {data ? <OrderDetails {...data} /> : <LoaderCircle stretchToSpace />}
    </div>
  );
};

export default PageOrder;
