import React, { useCallback, useMemo } from "react";
import cn from "@/libs/cn";
import Link from "@/components/basic/Link";
import { IApiOrder } from "@/types/interfaces/orders";
import useGlobalContext from "@/context/GlobalContext";
import routesProfile from "../../constants/routesProfile";
import CardOrderProduct from "../CardOrderProduct";
import { addingZero } from "@/utils/date";
import useRouter from "@/hooks/useRouter";
import { useDataPublicStore } from "@/modules/public/hooks/useData";
import routesPublic from "@/modules/public/constants/routesPublic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.module.scss";

export const calculatePrice = (shippingSum: string, itemsSum: string) => {
  return (parseFloat(shippingSum) + parseFloat(itemsSum)).toFixed(2);
};

const formatCreateDateOrder = (value?: any) => {
  try {
    const dateObj = new Date(value);

    let date = dateObj.getDate() as any;
    date = date > 9 ? date : `0${date}`;

    let month = (dateObj.getMonth() + 1) as any;
    month = month > 9 ? month : `0${month}`;

    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    return `${date}.${month}.${dateObj.getFullYear()} ${addingZero(
      hours
    )}:${addingZero(minutes)}`;
  } catch (err) {
    console.error("formatCreateDateOrder", value, err);
  }
  return "--.--.----";
};

const StoreLabel = ({ id }: any) => {
  const { translate } = useGlobalContext();
  const { data } = useDataPublicStore(id);

  return (
    <Link href={routesPublic.store(id)} className={styles.StoreLabel}>
      <div>{translate("Store")}:&nbsp;</div>
      <div>{data?.title}</div>
    </Link>
  );
};

const StoreAddress = ({ id }: any) => {
  const { translate } = useGlobalContext();
  const { data } = useDataPublicStore(id);

  const address = useMemo(() => data?.addresses?.[0], [data]);

  const addressStr = useMemo(
    () =>
      (address
        ? [
            address.country,
            address.city,
            address.province,
            address.address_line1,
            address.address_line2,
          ]
        : []
      )
        .filter((item: any) => item)
        .join(", "),
    [address]
  );

  if (!address) {
    return <div>{translate("The store has no address.")}</div>;
  }

  return (
    <div className={styles.StoreAddress}>
      <div>
        {translate("Store address")}:&nbsp;{addressStr}
      </div>
      <div>{data.phone}</div>
    </div>
  );
};

const CardOrder = ({
  id,
  name,
  created,
  modified,
  status,
  shipping_sum,
  grand_total,
  shipping_address,
  is_pickup,
  is_gift,
  items,
  store,
}: IApiOrder) => {
  const { currency, translate } = useGlobalContext();
  const router = useRouter();

  const goToOrder = useCallback(
    () => router.push(routesProfile.order(id)),
    [id, router]
  );

  const goToProduct = useCallback(
    (productId: any) => router.push(routesPublic.product(productId)),
    [router]
  );

  const isReceived = status === "received";

  return (
    <div className={styles.CardOrder}>
      <div className={styles.header}>
        <div className={styles.wrapOrder}>
          <div className={styles.order}>
            {translate("Order")}:&nbsp;
            <div className={styles.name}>{name}</div>
            ,&nbsp;
            <div className={styles.created}>
              {formatCreateDateOrder(created)}
            </div>
          </div>
          <StoreLabel id={store} />
        </div>
        <div className={styles.wrapStatus}>
          <div
            className={cn(
              styles.status,
              status === "pending" && styles.pending,
              status === "received" && styles.received,
              status === "delivering" && styles.delivering,
              status === "completed" && styles.completed,
              status === "canceled" && styles.canceled
            )}
          >
            {status}
          </div>
          <div className={styles.modified}>
            {formatCreateDateOrder(modified)}
          </div>
        </div>
      </div>

      <div className={styles.list}>
        {items?.map((item) => {
          return <CardOrderProduct key={item.id} {...item} />;
        })}
      </div>
      <div className={styles.footer}>
        {is_gift && (
          <FontAwesomeIcon
            // @ts-ignore
            icon="fa-solid fa-gift"
            className={styles.gift}
            title={translate("For a gift")}
          />
        )}
        {is_pickup && (
          <FontAwesomeIcon
            // @ts-ignore
            icon="fa-solid fa-person-walking"
            className={styles.pickup}
            title={translate("Self Delivery")}
          />
        )}
        <div className={styles.delivery}>
          {is_pickup ? (
            <StoreAddress id={store} />
          ) : (
            <>
              {shipping_address && (
                <div className={styles.address}>
                  <div>
                    {shipping_address.first_name}&nbsp;
                    {shipping_address.last_name}
                  </div>
                  <div>
                    {shipping_address.country.name},&nbsp;
                    {shipping_address.city},&nbsp;
                    {shipping_address.address_line1}&nbsp;
                    {shipping_address.address_line2}
                  </div>
                  <div>{shipping_address.phone}</div>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.summary}>
          {!is_pickup && (
            <div className={styles.item}>
              <div>{translate("Delivery cost")}:&nbsp;</div>
              {shipping_sum}
              {currency.symbol}
            </div>
          )}
          <div className={cn(styles.item, styles.total)}>
            <div>{translate("Total")}:&nbsp;</div>
            {grand_total}
            {currency.symbol}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOrder;
