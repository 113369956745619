import React from "react";

import Button, { PropsButton } from "@/controls/Button";

const ButtonSubmit = (props: PropsButton) => {
  return (
    <Button {...props} type="submit" color="primary">
      {props.children}
    </Button>
  );
};

export default ButtonSubmit;
