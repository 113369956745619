import React, { useCallback, useState } from "react";

import InlineIcons from "@/components/basic/InlineIcons";
import styles from "./styles.module.scss";
import cn from "@/libs/cn";

interface DetailsAccordion {
  className?: string;
  header: any;
  children: any;
}

const DetailsAccordion = ({
  className,
  header,
  children,
}: DetailsAccordion) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return (
    <div className={cn(styles.detailsAccordion, className)}>
      <div
        className={styles.detailsAccordion_headerWrapper}
        onClick={toggleAccordion}
      >
        <div className={styles.detailsAccordion_header}>{header}</div>
        <InlineIcons.ArrowDown
          className={cn(
            styles.detailsAccordion_icon,
            isOpen ? styles.opened : styles.closed
          )}
        />
      </div>
      {isOpen && (
        <div className={styles.detailsAccordion_contentWrapper}>{children}</div>
      )}
    </div>
  );
};

export default DetailsAccordion;
