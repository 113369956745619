import { removeEmptyItems } from "@/utils/data";
import InlineIcons from "@/components/basic/InlineIcons";
import React from "react";

import styles from "./styles.module.scss";

interface CardAddressOption {
  address_line1?: string;
  address_line2?: string;
  city?: string;
  country?: { name: string };
  email?: string;
  first_name?: string;
  id: number;
  is_active: boolean;
  is_pickup: boolean;
  last_name?: string;
  phone?: string;
  province?: { name: string };
  to_save: boolean;
  zip_code?: string;
}

const CardAddressOption = (props: CardAddressOption) => {
  const {
    id,
    is_pickup,
    to_save,
    address_line1,
    address_line2,
    city,
    country,
    email,
    first_name,
    last_name,
    phone,
    province,
    zip_code,
  } = props;

  return (
    <div className={styles.cardAddressOption}>
      <InlineIcons.Map className={styles.icon} />
      {is_pickup ? (
        <div className={styles.details}>
          <div className={styles.street}>{`${first_name} ${last_name}`}</div>
          <div className={styles.city}>{phone}</div>
          <div className={styles.country}>
            {typeof country === "string" ? country : country?.name}&nbsp;
          </div>
        </div>
      ) : (
        <div className={styles.details}>
          <div className={styles.street}>
            {removeEmptyItems([address_line1, address_line2]).join(", ")}
          </div>
          <div className={styles.city}>
            {removeEmptyItems([city, province?.name, zip_code]).join(", ")}
          </div>
          <div className={styles.country}>
            {typeof country === "string" ? country : country?.name}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardAddressOption;
