const iconsNames = {
  appleLarge: "/assets/icons/social/apple-large.svg",
  apple: "/assets/icons/social/apple.svg",
  facebookLarge: "/assets/icons/social/facebook-large.svg",
  facebook: "/assets/icons/social/facebook.svg",
  googleLarge: "/assets/icons/social/google-large.svg",
  instagram: "/assets/icons/social/instagram.svg",
  playmarket: "/assets/icons/social/playmarket.svg",
  twitter: "/assets/icons/social/twitter.svg",
  addPlus: "/assets/icons/add-plus.svg",
  arrowBottom: "/assets/icons/arrow-bottom.svg",
  arrowDown: "/assets/icons/arrow-down.svg",
  arrowDownBorder: "/assets/icons/arrow-down-border.svg",
  arrowRight: "/assets/icons/arrow-right.svg",
  arrowShortLeft: "/assets/icons/arrow-short-left.svg",
  arrowShortRight: "/assets/icons/arrow-short-right.svg",
  arrowUp: "/assets/icons/arrow-up.svg",
  ask: "/assets/icons/ask.svg",
  attachFile: "/assets/icons/attach-file.svg",
  attention: "/assets/icons/attention.svg",
  authSignIn: "/assets/icons/auth-sign-in.svg",
  authSignUp: "/assets/icons/auth-sign-up.svg",
  bell: "/assets/icons/bell.svg",
  bluePoint: "/assets/icons/blue-point.svg",
  blurry: "/assets/icons/blurry.svg",
  bus: "/assets/icons/bus.svg",
  camera: "/assets/icons/camera.svg",
  caretDown: "/assets/icons/caret-down.svg",
  cart: "/assets/icons/cart.svg",
  check: "/assets/icons/check.svg",
  close: "/assets/icons/close.svg",
  close2: "/assets/icons/close2.svg",
  comment: "/assets/icons/comment.svg",
  copyLink: "/assets/icons/copy-link.svg",
  creditCard: "/assets/icons/credit-card.svg",
  cubes: "/assets/icons/cubes.svg",
  currency: "/assets/icons/currency.svg",
  edit: "/assets/icons/edit.svg",
  exit: "/assets/icons/exit.svg",
  feedback: "/assets/icons/feedback.svg",
  filter: "/assets/icons/filter.svg",
  guard: "/assets/icons/guard.svg",
  hamburger: "/assets/icons/hamburger.svg",
  heartFill: "/assets/icons/heart-fill.svg",
  heartNoFill: "/assets/icons/heart-no-fill.svg",
  heart: "/assets/icons/heart.svg",
  help: "/assets/icons/help.svg",
  home: "/assets/icons/home.svg",
  image: "/assets/icons/image.svg",
  info: "/assets/icons/info.svg",
  labelCartApplePay: "/assets/icons/label-cart-apple-pay.svg",
  labelCartMaster: "/assets/icons/label-cart-master.svg",
  labelCartVisa: "/assets/icons/label-cart-visa.svg",
  like: "/assets/icons/like.svg",
  listUi: "/assets/icons/list-ui.svg",
  location: "/assets/icons/location.svg",
  logoShop: "/assets/icons/logo-shop.svg",
  map: "/assets/icons/map.svg",
  menuVertical: "/assets/icons/menu-vertical.svg",
  menu: "/assets/icons/menu.svg",
  minus: "/assets/icons/minus.svg",
  mobileLogin: "/assets/icons/mobile-login.svg",
  notificationsCar: "/assets/icons/notifications-car.svg",
  notificationsCart: "/assets/icons/notifications-cart.svg",
  notificationsComment: "/assets/icons/notifications-comment.svg",
  notificationsGift: "/assets/icons/notifications-gift.svg",
  notifications: "/assets/icons/notifications.svg",
  order: "/assets/icons/order.svg",
  orders: "/assets/icons/orders.svg",
  picture: "/assets/icons/picture.svg",
  pinDefault: "/assets/icons/markers/pin-default.svg",
  pinSale: "/assets/icons/markers/pin-sale.svg",
  placeholderImage: "/assets/icons/placeholder-image.svg",
  play: "/assets/icons/play.svg",
  plus: "/assets/icons/plus.svg",
  question: "/assets/icons/question.svg",
  refresh: "/assets/icons/refresh.svg",
  reviews: "/assets/icons/reviews.svg",
  saleImage: "/assets/icons/sale-image.svg",
  search: "/assets/icons/search.svg",
  securing: "/assets/icons/securing.svg",
  send: "/assets/icons/send.svg",
  sent: "/assets/icons/sent.svg",
  settingsActive: "/assets/icons/settings-active.svg",
  settings: "/assets/icons/settings.svg",
  share: "/assets/icons/share.svg",
  socialNetwork: "/assets/icons/social-network.svg",
  starFill: "/assets/icons/star-fill.svg",
  starFilled: "/assets/icons/star-filled.svg",
  starNoFill: "/assets/icons/star-no-fill.svg",
  star: "/assets/icons/star.svg",
  successPurchase: "/assets/icons/success-purchase.svg",
  terms: "/assets/icons/terms.svg",
  trash: "/assets/icons/trash.svg",
  unwatch: "/assets/icons/unwatch.svg",
  upload: "/assets/icons/upload.svg",
  user: "/assets/icons/user.svg",
  warning: "/assets/icons/warning.svg",
  warranty: "/assets/icons/warranty.svg",
  watch: "/assets/icons/watch.svg",
  world: "/assets/icons/world.svg",
};

export default iconsNames;
