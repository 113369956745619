import { crudFetcherCommon } from "services/crud";
import apiProfile from "./constants/apiProfile";

const addAddress = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiProfile.ordersAddresses(),
    requestData,
  });
};

const updateAddress = (addressId: any, requestData: any) => {
  return crudFetcherCommon.put({
    url: apiProfile.ordersAddress(addressId),
    requestData,
  });
};

const deleteAddress = (addressId: any) => {
  return crudFetcherCommon.delete({
    url: apiProfile.ordersAddress(addressId),
  });
};

const updatePreferences = (requestData: any) => {
  return crudFetcherCommon.put({
    url: apiProfile.preferences(),
    requestData,
  });
};

const onboardingStepOne = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiProfile.onboardingStepOne(),
    requestData,
  });
};

const onboardingStepTwo = (requestData: any) => {
  return crudFetcherCommon.post({
    url: apiProfile.onboardingStepTwo(),
    requestData,
  });
};

const profileRequests = {
  addAddress,
  updateAddress,
  deleteAddress,
  updatePreferences,
  onboardingStepOne,
  onboardingStepTwo,
};

export default profileRequests;
