import React from "react";
import Link from "@/components/basic/Link";
import routesHome from "@/modules/home/constants/routesHome";
import CardOrderProduct from "../../CardOrderProduct";
import { IApiProductItemOfOrder } from "@/types/interfaces/orders";

import styles from "./styles.module.scss"

interface Products {
  items: IApiProductItemOfOrder[];
}

const Products = ({ items }: Products) => {
  return (
    <div className={styles.products}>
      {items.map((item, idx) => (
        <Link
          key={item.id}
          href={routesHome.product(item.product.id)}
          className={styles.link}
        >
          <CardOrderProduct
            id={item.id}
            quantity={item.quantity}
            checkout_price={item.checkout_price}
            product={item.product}
          />
        </Link>
      ))}
    </div>
  );
};

export default Products;
