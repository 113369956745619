import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "@/libs/cn";
import PublicIcons from "@/components/basic/PublicIcon";
import CustomScroll from "@/components/CustomScroll";
import LoaderCircle from "@/controls/LoaderCircle";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";

export interface PropsDropdown {
  label?: string;
  options: { [key: string]: string }[];
  value?: string | number | null;
  placeholder?: string;
  onChange?: (value: any, isCustom: boolean) => void;
  hideValue?: boolean;
  customItem?: (props: { value: string; label: string }) => JSX.Element;
  disabled?: boolean;
  allowCustomInput?: boolean;
  highlightNonExistValue?: boolean;
  hiddenBackground?: boolean;
  makeSorting?: boolean;
  loading?: boolean;
  className?: string;
  classNameOption?: string;
  classNameValue?: string;
}

const Dropdown = (props: PropsDropdown) => {
  const {
    label,
    options,
    value,
    placeholder,
    onChange = (value) => {},
    hideValue,
    customItem: CustomItem,
    disabled,
    allowCustomInput,
    highlightNonExistValue = true,
    hiddenBackground,
    makeSorting = true,
    loading,
    className,
    classNameOption,
    classNameValue,
  } = props;
  const refInput = useRef(null);
  const [width, setWidth] = useState(0);
  const [show, setShow] = useState(false);

  const currentValue = useMemo(() => {
    if (hideValue) {
      return "";
    }

    const data = options.find((el) => el.value == value);

    if (data) {
      return data?.label;
    }

    if (allowCustomInput && typeof value === "string" && value) {
      return value;
    }

    return "";
  }, [allowCustomInput, hideValue, options, value]);

  const refCallback = useCallback((el: any) => {
    if (el) {
      setWidth(el.getBoundingClientRect().width);
    }
  }, []);

  useEffect(() => {
    const hideList = () => setShow(false);
    document?.addEventListener("scroll", hideList, false);

    return () => document?.removeEventListener("scroll", hideList, false);
  }, [show]);

  const [filterStr, setFilterStr] = useState(null);
  const onInput = useCallback((e: any) => {
    const val = e.target.value;

    setFilterStr(val);
  }, []);

  const onKeyDown = useCallback(
    (e: any) => {
      const val = e.target.value;
      if (allowCustomInput && (e.keyCode === 13 || e.key === "Enter")) {
        e.preventDefault();
        e.stopPropagation();
        setShow(false);

        if (val != currentValue) {
          onChange(val, true);
        }
      }
    },
    [allowCustomInput, currentValue, onChange]
  );

  const [focus, setFocus] = useState(false);
  const onBlur = useCallback(
    (e: any) => {
      setFocus(false);
      // @ts-ignore
      const val = refInput.current?.value;

      if (val != currentValue) {
        onChange(val, true);
      }
    },
    [currentValue, onChange]
  );

  const onFocus = useCallback((e: any) => {
    setFocus(true);
  }, []);

  const sortedData = useMemo(() => {
    try {
      return makeSorting
        ? options.sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          )
        : options;
    } catch (err) {
      return options;
    }
  }, [options, makeSorting]);

  const filteredData = useMemo(
    () =>
      sortedData.filter(
        (item) =>
          filterStr === null ||
          item.label.toLowerCase().indexOf(`${filterStr}`.toLowerCase()) !== -1
      ),
    [sortedData, filterStr]
  );

  const onClear = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setFilterStr(null);
      onChange(null, false);

      if (refInput && refInput.current) {
        // @ts-ignore
        refInput.current.value = "";
      }
    },
    [onChange]
  );

  const onChangeHandler = useCallback(
    (value: any) => {
      setFilterStr(null);
      onChange(value, false);
    },
    [onChange]
  );

  useEffect(() => {
    if (!loading && !focus && filterStr && currentValue) {
      setFilterStr(null);
    }
  }, [currentValue, filterStr, focus, loading, options, value]);

  const isError = useMemo(() => {
    if (loading) {
      return false;
    }

    if (!filterStr) {
      return false;
    }

    if (!currentValue) {
      return true;
    }
  }, [currentValue, filterStr, loading]);

  return (
    <div className={className}>
      {!!label && (
        <div className={cn(stylesForms.basicLabel, styles.label)}>{label}</div>
      )}
      <div
        ref={refCallback}
        className={cn(
          stylesForms.basicWrapInput,
          styles.Dropdown,
          hiddenBackground && styles.hiddenBackground,
          disabled && styles.disabled
        )}
        onClick={() => setShow(!show)}
      >
        {show && <div className={styles.back} />}
        {!hideValue && (
          <>
            {allowCustomInput ? (
              <input
                ref={refInput}
                className={cn(
                  highlightNonExistValue && isError && styles.error
                )}
                value={filterStr !== null ? filterStr : currentValue}
                placeholder={placeholder}
                onInput={onInput}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onFocus={onFocus}
                autoComplete="none"
                onClick={() => setShow(!show)}
              />
            ) : (
              <div
                className={cn(
                  stylesForms.input,
                  hiddenBackground && styles.hiddenBackground,
                  !currentValue && stylesForms.placeholder,
                  styles.value
                )}
              >
                <div className={cn(styles.current, classNameValue)}>
                  {currentValue || placeholder}
                </div>
              </div>
            )}
          </>
        )}
        {allowCustomInput && (!!filterStr || !!value) && (
          <div className={styles.clear}>
            {loading ? (
              <LoaderCircle stretchToSpace size={18} />
            ) : (
              <PublicIcons src={PublicIcons.names.close2} onClick={onClear} />
            )}
          </div>
        )}
        {!hideValue && (
          <div className={styles.arrow}>
            <PublicIcons src={PublicIcons.names.arrowDown} alt={"dropdown"} />
          </div>
        )}
        <div className={cn(styles.list, show && styles.show)} style={{ width }}>
          <CustomScroll maxHeight={200}>
            <>
              {filteredData.map(({ value, label, ...rest }) =>
                !!CustomItem ? (
                  <CustomItem
                    key={value}
                    value={value}
                    label={label}
                    {...rest}
                  />
                ) : (
                  <div
                    className={cn(styles.option, classNameOption)}
                    key={value}
                    onClick={() => onChangeHandler(value)}
                  >
                    {label}
                  </div>
                )
              )}
            </>
          </CustomScroll>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
