import * as Yup from "yup";
import { regExpPhone, regExpEmail } from "@/utils/regexp";
import { IArgsValidation } from "./types";

const authValidation = ({ translate }: IArgsValidation) => {
  const scheme = {
    first_name: Yup.string()
      .max(50, translate("First name must be at most 50 characters"))
      .nullable(),
    last_name: Yup.string()
      .max(50, translate("Last name must be at most 50 characters"))
      .nullable(),
    birthday: Yup.string().nullable(),
    email: Yup.string()
      .email("Please enter a valid email address")
      .matches(regExpEmail, "Please enter a valid email address"),
    email_signin: Yup.string().min(1, "Please enter a valid email address"),
    phone: Yup.string().matches(
      regExpPhone,
      translate("Phone number is not valid")
    ),
    gender: Yup.string().nullable(),
    /*
    site: Yup.string()
      .url(translate("Invalid website url"))
      .max(50, translate("Website must be at most 50 characters")),
    */
    oldPassword: Yup.string(),
    password: Yup.string()
      .min(8, translate("Password must be at least 8 characters"))
      .max(32, translate("Password must be at most 32 characters"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\\[-`{-~]).{8,32}$/,
        translate(
          "The password must contain at least one small letter, one capital letter, one number, one special character "
        )
      ),
    password_signin: Yup.string().min(
      1,
      translate("Password must be at least 1 characters")
    ),
    password_confirm: Yup.string().oneOf(
      [Yup.ref("password")],
      translate("Both passwords are not matching")
    ),
    remeber: Yup.boolean(),
    is_accept: Yup.boolean().oneOf(
      [true],
      translate("You must check that you agree")
    ),
  };

  return scheme;
};

export default authValidation;
