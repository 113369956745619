import { useCallback } from "react";
import { useField } from "formik";
import cn from "@/libs/cn";
import Dropdown, { PropsDropdown } from "@/controls/Dropdown";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";

export interface PropsFieldDropdown extends PropsDropdown {
  label?: string;
  inlineLabel?: boolean;
  name: string;
  fieldClassName?: string;
  options: any[];
  placeholder?: string;
  onChange?: (val: any, isCustom: boolean, helpers?: any) => void;
}

const FieldDropdown = (props: PropsFieldDropdown) => {
  const {
    name,
    fieldClassName,
    label,
    inlineLabel,
    options,
    placeholder,
    onChange: onChangeOut = () => {},
    allowCustomInput,
    highlightNonExistValue,
    makeSorting,
    disabled,
    loading,
  } = props;
  const [field, meta, helpers] = useField(name);

  const { error, touched } = meta;

  const onChange = useCallback(
    (val: any, isCustom: boolean) => {
      helpers.setValue(val);
      onChangeOut(val, isCustom, helpers);
    },
    [helpers, onChangeOut]
  );

  return (
    <div
      className={cn(
        styles.FieldDropdown,
        disabled && styles.disabled,
        fieldClassName
      )}
    >
      <div className={cn(styles.wrap, inlineLabel && styles.inline)}>
        <Dropdown
          label={label}
          className={styles.dropdown}
          onChange={onChange}
          value={field.value}
          options={options}
          placeholder={placeholder}
          allowCustomInput={allowCustomInput}
          highlightNonExistValue={highlightNonExistValue}
          makeSorting={makeSorting}
          loading={loading}
          disabled={disabled}
        />
      </div>
      <div
        className={cn(
          stylesForms.basicError,
          error && touched && stylesForms.show
        )}
      >
        {touched && error}
      </div>
    </div>
  );
};

export default FieldDropdown;
