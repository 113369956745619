import useGlobalContext from "@/context/GlobalContext";
import Link from "@/components/basic/Link";
import useDataLinks from "../../constants/dataFooterLinks";

import styles from "./styles.module.scss";
import Image from "@/components/basic/Image";
import { MARKETPLACE_EMAIL, MARKETPLACE_NAME, MARKETPLACE_TEL } from "@/constants/info";

const Footer = () => {
  const {
    translate,
    modules: {
      home: { routes: homeRoutes },
    },
  } = useGlobalContext();

  const info = useDataLinks();

  return (
    <footer className={styles.Footer}>
      <div className={styles.links}>
        <div className={styles.info}>
          <div className={styles.title}>{translate("Information")}</div>
          <div className={styles.list}>
            {info.map((item) => (
              <Link key={item.href} href={item.href}>
                {item.text}
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.contact}>
          <Link href={homeRoutes.home()}>
            <Image src="/assets/logo.svg" width="154" height="43" alt="logo" />
          </Link>
          <div>
            <div className={styles.name}>{translate("Email") + ":"}</div>
            <a className={styles.link} href={`mailto:${MARKETPLACE_EMAIL}`}>
              {`${MARKETPLACE_EMAIL}`}
            </a>
          </div>
          <div>
            <div className={styles.name}>{translate("Phone") + ":"}</div>
            <a className={styles.link} href={`"tel:${MARKETPLACE_TEL}`}>
              {MARKETPLACE_TEL}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        Copyright © {MARKETPLACE_NAME}. All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
