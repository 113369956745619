import { useCallback } from "react";
import cn from "@/libs/cn";

import styles from "./styles.module.scss";
import PublicIcons from "../basic/PublicIcon";

interface Drawer {
  anchor: "left" | "common";
  show: boolean;
  setShow: (open: boolean) => void;
  hideByClickSpace?: boolean;
  className?: string;
  classNameContent?: string;
  children: any;
}

const Drawer = (props: Drawer) => {
  const {
    anchor,
    children,
    className,
    classNameContent,
    show,
    setShow,
    hideByClickSpace = true,
  } = props;

  const onStopEvent = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      className={cn(
        styles.Drawer,
        show && styles.show,
        anchor === "common" && styles.common,
        anchor === "left" && styles.left,
        className
      )}
      onClick={() => setShow(false)}
    >
      <PublicIcons
        src={PublicIcons.names.close2}
        className={styles.close}
        width={24}
        height={24}
      />
      <div
        className={cn(styles.content, classNameContent)}
        onClick={hideByClickSpace ? () => setShow(false) : onStopEvent}
      >
        {children}
      </div>
    </div>
  );
};

export default Drawer;
