import { useMemo } from "react";
import { NAME_PARAM_CONTENT_LANGUAGE } from "@/constants/common";
import useLocation from "./useLocation";

export const useContentLanguage = () => {
  const location = useLocation();

  const URLparams = useMemo(
    () => new URLSearchParams(location?.search),
    [location?.search]
  );

  const valueLanguage = useMemo(
    () => URLparams.get(NAME_PARAM_CONTENT_LANGUAGE) || undefined,
    [URLparams]
  );
  return valueLanguage;
};

export default useContentLanguage;
