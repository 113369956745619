import React, { useCallback } from "react";
import useGlobalContext from "@/context/GlobalContext";
import cn from "@/libs/cn";
import Image from "@/components/basic/Image";
import { useDataPublicProduct } from "@/modules/public/hooks/useData";
import LoaderCircle from "@/controls/LoaderCircle";
import { isZero } from "@/utils/format";
import useRouter from "@/hooks/useRouter";
import routesPublic from "@/modules/public/constants/routesPublic";

import { IApiProductItemOfOrder } from "@/types/interfaces/orders";
import { IApiProductDetails } from "@/types/interfaces/products";

import styles from "./styles.module.scss";

const CardOrderProduct = ({
  id: cartItemId,
  quantity,
  product,
  checkout_price,
}: IApiProductItemOfOrder) => {
  const { isMobileSize, translate, currency } = useGlobalContext();
  const router = useRouter();

  const { data } = useDataPublicProduct(product.id);

  const goToProduct = useCallback(
    (productId: any) => router.push(routesPublic.product(productId)),
    [router]
  );

  if (!data) {
    return (
      <div className={cn(styles.CardOrderProduct)}>
        <LoaderCircle stretchToSpace />
      </div>
    );
  }

  const { id, final_price, name, main_pic, brand } = data as IApiProductDetails;
  const finalPrice = isZero(checkout_price) ? final_price : checkout_price;

  return (
    <div key={id} className={cn(styles.CardOrderProduct)}>
      <div className={styles.wrapImage} onClick={() => goToProduct(id)}>
        <Image
          src={main_pic?.thumbnail}
          alt={main_pic?.name}
          // layout="fill"
          width={120}
          height={120}
          objectFit="cover"
        />
      </div>
      <div className={styles.info}>
        <div className={styles.name} onClick={() => goToProduct(id)}>
          {name}
        </div>
        <div className={styles.brand}>
          <div className={styles.label}>{translate("Brand")}:</div>
          {brand}
        </div>
        <div className={styles.price}>
          <div className={styles.label}>{translate("Price")}:</div>
          {finalPrice}
          {currency.symbol}
          {isMobileSize && (
            <>
              <div className={styles.quantity}>
                &nbsp;x&nbsp;
                {quantity}
                {translate("pcs")}
              </div>
              <div className={styles.totalPprice}>
                {(parseFloat(finalPrice) * quantity).toFixed(2)}
                {currency.symbol}
              </div>
            </>
          )}
        </div>
      </div>
      {!isMobileSize && (
        <>
          <div className={styles.quantity}>
            {quantity}
            {translate("pcs")}
          </div>
          <div className={styles.totalPprice}>
            {(parseFloat(finalPrice) * quantity).toFixed(2)}
            {currency.symbol}
          </div>
        </>
      )}
    </div>
  );
};

export default CardOrderProduct;
