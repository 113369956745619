import { crudFetcherCommon, crudFetcherNextApi } from "services/crud";
import apiPublic from "./constants/apiPublic";

const currencies = () => {
  return crudFetcherCommon.get({
    url: apiPublic.currencies(),
  });
};

const storesTags = () => {
  return crudFetcherCommon.get({
    url: apiPublic.storesTags(),
  });
};

const countries = () => {
  return crudFetcherCommon.get({
    url: apiPublic.countries(),
  });
};

const countryProvinces = (countryCode: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.countryProvinces(countryCode),
  });
};

const countryCities = (countryCode: string) => {
  return crudFetcherCommon.get({
    url: apiPublic.countryCities(countryCode),
  });
};

const categories = () => {
  return crudFetcherCommon.get({
    url: apiPublic.categories(),
  });
};

const products = () => {
  return crudFetcherCommon.get({
    url: apiPublic.products(),
  });
};

const product = (productId: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.product(productId),
  });
};

const relatedProducts = (productId: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.relatedProducts(productId),
  });
};

const properties = () => {
  return crudFetcherCommon.get({
    url: apiPublic.properties(),
  });
};

const categoryProperties = (categoryId: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.categoryProperties(categoryId),
  });
};

const storesDeliveryOptions = () => {
  return crudFetcherCommon.get({
    url: apiPublic.storesDeliveryOptions(),
  });
};

const stores = () => {
  return crudFetcherCommon.get({
    url: apiPublic.stores(),
  });
};

const store = (storeId: any) => {
  return crudFetcherCommon.get({
    url: apiPublic.store(storeId),
  });
};

const feturedStores = () => {
  return crudFetcherCommon.get({
    url: apiPublic.feturedStores(),
  });
};

const featuredTags = () => {
  return crudFetcherCommon.get({
    url: apiPublic.featuredTags(),
  });
};

const newStores = () => {
  return crudFetcherCommon.get({
    url: apiPublic.newStores(),
  });
};

const MOCKTopCategories = () => {
  return crudFetcherNextApi.get({
    url: apiPublic.categories(),
  });
};

const geocodeAddressSearch = (address: string) => {
  return crudFetcherNextApi.get({
    url: `/geocode/address/?q=${address}`,
  });
};

const geocodeAutocomplete = (address: string) => {
  return crudFetcherNextApi.get({
    url: `/geocode/autocomplete/?input=${address}&language=es`,
  });
};

const publicRequests = {
  currencies,
  storesTags,
  countries,
  countryProvinces,
  countryCities,
  categories,
  products,
  product,
  relatedProducts,
  properties,
  categoryProperties,
  storesDeliveryOptions,
  stores,
  store,
  feturedStores,
  featuredTags,
  newStores,
  MOCKTopCategories,
  geocodeAddressSearch,
  geocodeAutocomplete,
};

export default publicRequests;
