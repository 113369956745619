import PublicIcons from "@/components/basic/PublicIcon";
import cn from "@/libs/cn";
import React, { useCallback, useMemo, useState } from "react";

import styles from "./styles.module.scss";

interface ToggleIconButton {
  label: string;
  onToggle: (val: boolean) => void;
  value?: boolean;
  defaultValue?: boolean;
  hideNoActiveIcon?: boolean;
  className?: string;
}

const ToggleIconButton = ({
  label,
  onToggle,
  value,
  defaultValue = false,
  hideNoActiveIcon,
  className,
}: ToggleIconButton) => {
  const [switchOn, setOn] = useState(defaultValue);

  const onClick = useCallback(() => {
    if (typeof value !== "undefined") {
      onToggle(!value);
    } else {
      onToggle(!switchOn);
      setOn(!switchOn);
    }
  }, [onToggle, switchOn, value]);

  const isOn = useMemo(
    () => (typeof value !== "undefined" ? value : switchOn),
    [switchOn, value]
  );

  return (
    <div
      className={cn(styles.ToggleIconButton, isOn && styles.on, className)}
      onClick={onClick}
    >
      <div
        className={cn(
          styles.icon,
          hideNoActiveIcon && styles.hideNoActiveIcon,
          !isOn && styles.off
        )}
      >
        <PublicIcons
          src={PublicIcons.names.heartNoFill}
          className={cn(
            styles.active,
            hideNoActiveIcon && styles.hideNoActiveIcon,
            !isOn && styles.on
          )}
        />
        <PublicIcons
          src={PublicIcons.names.heartFill}
          className={cn(styles.noactive, isOn && styles.on)}
        />
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default ToggleIconButton;
