import { useCallback, useEffect, useState } from "react";
import Button from "@/controls/Button";

import styles from "./styles.module.scss";
import cn from "@/libs/cn";
import { OptionsModal } from "../useModal";

interface useConfirm {
  open: (component: any, options?: OptionsModal) => JSX.Element;
  close: () => void;
  back: () => void;
}

export interface PropsConfirmModal {
  title: string;
  message?: string;
  onConfirm: () => void;
  textOK?: string;
  buttonOkColor?: "primary" | "secondary";
  icon?: JSX.Element;
  iconColor?: "primary" | "secondary" | "info" | "error";
  textCancel?: string;
  onCancel?: () => void;
  hideButtonOk?: boolean;
  hideButtonCancel?: boolean;
}

const useConfirm = ({ open, close, back }: useConfirm) => {
  const onConfirm = useCallback(
    (callback = () => {}) => {
      close();
      callback();
    },
    [close]
  );

  const confirm = useCallback(
    (args: PropsConfirmModal) => {
      open(() => (
        <ConfirmModal
          {...args}
          onConfirm={() => {
            onConfirm(args.onConfirm);
          }}
          onCancel={back}
        />
      ));
    },
    [back, onConfirm, open]
  );

  return confirm;
};

const ConfirmModal = (props: PropsConfirmModal) => {
  const {
    icon: Icon,
    iconColor = "primary",
    title = "",
    message = "",
    textOK = "OK",
    onConfirm,
    buttonOkColor,
    textCancel = "Cancel",
    onCancel,
    hideButtonOk,
    hideButtonCancel,
  } = props;

  return (
    <div className={styles.ConfirmModal}>
      <div className={styles.header}>
        {Icon && (
          // @ts-ignore
          <Icon
            height={26}
            className={cn(
              iconColor === "primary" && styles.primary,
              iconColor === "secondary" && styles.secondary,
              iconColor === "info" && styles.info,
              iconColor === "error" && styles.error
            )}
          />
        )}
        <div className={styles.title}>{title}</div>
      </div>

      <div className={styles.message}>{message}</div>
      <div className={styles.wrapButton}>
        {!hideButtonOk && (
          <Button onClick={onConfirm} color={buttonOkColor}>
            {textOK}
          </Button>
        )}
        {!hideButtonCancel && <Button onClick={onCancel}>{textCancel}</Button>}
      </div>
    </div>
  );
};

export default useConfirm;
