import React from "react";
import cn from "@/libs/cn";
import InlineIcons from "@/components/basic/InlineIcons";
import HrLine from "@/controls/HrLine";

import styles from "./styles.module.scss";
import PublicIcons from "@/components/basic/PublicIcon";

// TODO this is duplicate, need to merge
// client/components/EditList/index.tsx

interface EditList {
  title?: string;
  titleIntoBody?: boolean;
  icon?: (props: any) => JSX.Element;
  onEdit?: (id: any) => void;
  onAdd?: () => void;
  labelAdd?: string;
  data: any[];
  ids: any[];
  placeForHeader?: () => JSX.Element;
  classNameBody?: string;
  classNameHeader?: string;
  margin?: any;
}

const EditList = ({
  title,
  titleIntoBody,
  icon: Icon,
  onEdit,
  onAdd,
  labelAdd,
  data,
  ids,
  placeForHeader,
  classNameBody,
  classNameHeader,
  margin,
}: EditList) => {
  const Title = () => (
    <div className={cn(styles.header, classNameHeader)}>
      {title && <h2>{title}</h2>}
      {labelAdd && (
        <div className={styles.add} onClick={onAdd}>
          <InlineIcons.AddPlus />
          <div>{labelAdd}</div>
        </div>
      )}
    </div>
  );

  return (
    <div className={cn(styles.editList)} style={{ margin }}>
      {!titleIntoBody && title && <Title />}
      <div className={cn(styles.body, classNameBody)}>
        {placeForHeader && (
          <>
            {placeForHeader}
            <HrLine />
          </>
        )}
        {titleIntoBody && <Title />}
        {data.map((item: any, idx: any) => (
          <React.Fragment key={idx}>
            <div className={styles.item}>
              <div className={styles.content}>{item}</div>
              {onEdit && (
                <>
                  {Icon ? (
                    <Icon id={ids?.[idx]} onClick={() => onEdit(ids?.[idx])} />
                  ) : (
                    <PublicIcons
                      src={PublicIcons.names.edit}
                      onClick={() => onEdit(ids?.[idx])}
                    />
                  )}
                </>
              )}
            </div>
            {idx !== data.length - 1 && <HrLine />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default EditList;
