import cn from "@/libs/cn";
import styles from "./styles.module.scss";

interface Icon {
  size?: number;
  width?: number | string;
  height?: number | string;
  component: any;
  className?: string;
}

const Icon = ({
  size,
  width,
  height,
  component: Component,
  className,
  ...rest
}: Icon) => {
  return (
    <Component
      {...rest}
      width={width}
      height={height}
      className={cn(styles.icon, className)}
    />
  );
};

export default Icon;
