import { UseInterface } from "types/modules/interface-types";
import { HomeInterface } from "./interface-types";

import routesHome, { baseRoute } from "./constants/routesHome";

export const moduleName = "home";

export const defaultValue: HomeInterface = {
  moduleName,
  baseRoute,
  routes: routesHome,
  actions: {}
};

export const useInterface = (args: UseInterface): HomeInterface => {
  return defaultValue;
};

export default useInterface;
