import { useMemo } from "react";
import { UseInterface } from "types/modules/interface-types";

const useActions = ({ openModal }: UseInterface) => {
  return useMemo(() => {
    return {
      openPublic: () => {
        // actions
      },
    };
  }, []);
};

export default useActions;
