// @ts-nocheck
import Waiting from "@/components/basic/Waiting";
import dynamic from "next/dynamic";
import { useMemo } from "react";
import { UseInterface } from "types/modules/interface-types";

const FormNewCatalog = dynamic(() => import("../forms/FormNewCatalog"), {
  ssr: false,
  suspense: false,
});

const FormRenameCatalog = dynamic(() => import("../forms/FormRenameCatalog"), {
  ssr: false,
  suspense: false,
});

const FormRenameFile = dynamic(() => import("../forms/FormRenameFile"), {
  ssr: false,
  suspense: false,
});

const FormSelectFiles = dynamic(() => import("../forms/FormSelectFiles"), {
  ssr: false,
  suspense: false,
});

const useActions = ({ openModal, translate }: UseInterface) => {
  return useMemo(() => {
    return {
      openNewCatalog: (props) => {
        openModal(
          () => {
            return (
              <Waiting>
                <FormNewCatalog {...props} />
              </Waiting>
            );
          },
          { title: translate("New catalog") }
        );
      },
      openRenameCatalog: (props) => {
        openModal(
          () => {
            return (
              <Waiting>
                <FormRenameCatalog {...props} />
              </Waiting>
            );
          },
          { title: translate("Rename catalog") }
        );
      },
      openRenameFile: (props) => {
        openModal(
          () => {
            return (
              <Waiting>
                <FormRenameFile {...props} />
              </Waiting>
            );
          },
          { title: translate("Rename file") }
        );
      },
      openSelectFiles: (props) => {
        openModal(
          () => {
            return (
              <Waiting>
                <FormSelectFiles {...props} />
              </Waiting>
            );
          },
          { title: translate("Select file(s)") }
        );
      },
    };
  }, [openModal, translate]);
};

export default useActions;
