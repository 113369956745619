import { useCallback } from "react";
import useGlobalContext from "@/context/GlobalContext";
import CardAddressOption from "@/components/cards/CardAddressOption";
import LoaderCircle from "@/controls/LoaderCircle";
import { useDataOrdersAddresses, useDataProfile } from "../hooks/useData";
import EditList from "../components/EditList";
import profileRequests from "../requests";
import InlineIcons from "@/components/basic/InlineIcons";

import styles from "./styles.module.scss";

const PageAddresses = () => {
  const {
    translate,
    toast,
    openConfirm,
    modules: {
      profile: {
        actions: { openAddress },
      },
    },
  } = useGlobalContext();
  const { data: dataProfile } = useDataProfile();

  const { data, mutate, isLoading } = useDataOrdersAddresses();

  const onSuccess = useCallback(() => mutate(), [mutate]);

  const onEdit = useCallback(
    (addressId: any) => {
      const initValues = data.find((i: any) => i.id == addressId) || undefined;
      openAddress({ addressId, initValues, onSuccess });
    },
    [data, onSuccess, openAddress]
  );

  const onAdd = useCallback(
    () =>
      openAddress({
        onSuccess,
        initValues: {
          first_name: dataProfile?.first_name,
          last_name: dataProfile?.last_name,
          phone: dataProfile?.phone,
        },
      }),
    [
      dataProfile?.first_name,
      dataProfile?.last_name,
      dataProfile?.phone,
      onSuccess,
      openAddress,
    ]
  );

  const onConfirmDelete = useCallback(
    (addressId: any) => {
      profileRequests
        .deleteAddress(addressId)
        .then(() => {
          toast.info(translate("Address methot has been deleted"));
          onSuccess();
        })
        .catch((err) => {
          toast.error(`${err.detail}`);
        });
    },

    [onSuccess, toast, translate]
  );

  const onDelete = useCallback(
    (id: any) => {
      openConfirm({
        title: translate("Delete address!"),
        message: translate("Are you sure you want to delete address?"),
        // icon: InlineIcons.Warning,
        onConfirm: () => onConfirmDelete(id),
        textOK: translate("Delete"),
        buttonOkColor: "primary",
      });
    },
    [onConfirmDelete, openConfirm, translate]
  );

  return (
    <div>
      {isLoading ? (
        <LoaderCircle stretchToSpace />
      ) : (
        <EditList
          title={translate("Addresses")}
          onAdd={onAdd}
          onEdit={() => {}}
          icon={({ id }: any) => (
            <div className={styles.icons}>
              <InlineIcons.Edit onClick={() => onEdit(id)} />
              <InlineIcons.Trash onClick={() => onDelete(id)} />
            </div>
          )}
          labelAdd={translate("Add address")}
          data={data.map((item: any) => (
            <CardAddressOption key={item} {...item} />
          ))}
          ids={data.map((i: any) => i.id)}
        />
      )}
    </div>
  );
};

export default PageAddresses;
