import { UseInterface } from "types/modules/interface-types";
import { AuthInterface } from "./interface-types";

import useActions from "./hooks/useActions";
import routesAuth, { baseRoute } from "./constants/routesAuth";
import { useCallback, useMemo } from "react";
import authRequests from "./requests";

export const moduleName = "auth";

export const defaultValue: AuthInterface = {
  moduleName,
  baseRoute,
  routes: routesAuth,
  actions: {
    openLogIn: () => { },
    openSignUp: () => { },
    openRecoveryPassword: () => { },
    openRestoreAccount: () => { },
    openChangeEmail: () => { },
    openNewPassword: () => { },
    openPhone: () => { },
    logout: () => { },
  },
  requests: {},
  data: {}
};

export const useInterface = (args: UseInterface): AuthInterface => {
  const { eraseAuthToken } = args;
  const actions = useActions(args);

  const logout = useCallback(() => {
    authRequests
      .logout()
      .then(() => eraseAuthToken())
      .catch((err) => {
        // The logout endpoint not implemented on the backend
        if (typeof err === "string" && err.startsWith("<!")) {
          eraseAuthToken();
        }
      });
  }, [eraseAuthToken]);

  return useMemo(
    () => ({
      moduleName,
      baseRoute,
      routes: routesAuth,
      actions: { ...actions, logout },
    }),
    [actions, logout]
  );
};

export default useInterface;
