import { useMemo } from "react";
import { UseInterface } from "types/modules/interface-types";
import { ProfileInterface } from "./interface-types";

import routesProfile, { baseRoute } from "./constants/routesProfile";
import useActions from "./hooks/useActions";
import profileRequests from "./requests";
import { PropsFormAddress } from "./form/FormAddress";

export const moduleName = "profile";

export const defaultValue: ProfileInterface = {
  moduleName,
  baseRoute,
  routes: routesProfile,
  requests: profileRequests,
  actions: {
    openProfile: (props: any) => {},
    openAddress: (props: PropsFormAddress) => {},
    openListAddresses: (props: any) => {},
    openOnboarding: (props: any) => {},
    openPreferencesProfile: (props: any) => {},
    openPreferencesNotifications: (props: any) => {},
    openPreferencesCategories: (props: any) => {},
    openPreferencesProductsTags: (props: any) => {},
    openPreferencesStoresTags: (props: any) => {},
    openPreferencesStores: (props: any) => {},
    openPreferencesLocations: (props: any) => {}
  },
};

export const useInterface = (args: UseInterface): ProfileInterface => {
  const actions = useActions(args);

  return useMemo(() => ({ ...defaultValue, actions }), [actions]);
};

export default useInterface;
