const formatNumber = (val: any, fractionDigits?: number) => {
  if (typeof val === "string" && val) {
    return val;
  }

  if (typeof val === "number" && !Number.isNaN(val)) {
    return val.toFixed(fractionDigits);
  }

  const mock = 0;

  return mock.toFixed(fractionDigits);
};

export const isZero = (val: any) => {
  if (!val) {
    return true;
  }

  try {
    const parsed = parseFloat(val);

    return !parsed;
  } catch (err) {
    console.error("isZero", typeof val, val, err);

    return true;
  }
};

export const formatFinalPrice = (val: any) => formatNumber(val, 2);

export const formatPrice = (val: any) => formatNumber(val, 2);

export const formatDiscount = (val: any) => `${parseFloat(val)}`;

export const formatWeight = (val: any) => `${parseFloat(val)}`;

export const formatFileName = (val: any) =>
  val && typeof val === "string"
    ? val.length < 20
      ? val
      : `${val.substring(0, 15)}...${val.substring(val.length - 8, val.length)}`
    : ``;
