type ValueRoute = () => string;

export type TypeRoutes = {
  home: ValueRoute;
  stores: ValueRoute;
  products: ValueRoute;
  product: (productId: any) => string;
  becomeSeller: ValueRoute;
  about: ValueRoute;
  contact: ValueRoute;
  delivery: ValueRoute;
  return: ValueRoute;
  policy: ValueRoute;
  privacy: ValueRoute;
  term: ValueRoute;
  permissionDenied: ValueRoute;
};

export const baseRoute = "";

const routesHome = {
  home: () => `${baseRoute}/`,
  stores: () => `${baseRoute}/stores/`,
  products: () => `${baseRoute}/category/`, // root for all products
  product: (productId: any) => `${baseRoute}/products/${productId}/`,
  becomeSeller: () => `${baseRoute}/become-seller/`,
  about: () => `${baseRoute}/about/`,
  contact: () => `${baseRoute}/contact/`,
  delivery: (tab: any) => `${baseRoute}/delivery/`,
  return: () => `${baseRoute}/return/`,
  policy: () => `${baseRoute}/policy/`,
  privacy: () => `${baseRoute}/privacy/`,
  term: () => `${baseRoute}/term/`,
  permissionDenied: () => `${baseRoute}/permission-denied/`,
} as TypeRoutes;

export default routesHome;
