import { useEffect, useRef } from "react";
import useGlobalContext from "@/context/GlobalContext";

import useRouter from "./useRouter";
import useIsSSR from "./useIsSSR";
import useQuery from "./useQuery";

const useProcessQueryAction = () => {
  const cont = useRef("");
  const isSSR = useIsSSR();
  const query = useQuery();
  const router = useRouter();
  const { modules, toast: globalToast } = useGlobalContext();

  useEffect(() => {
    const { pathname, href } = global?.location;

    if (!isSSR && cont.current !== href) {
      cont.current = href;
      // Example: ?action=auth:openNewPassword&email=ruslan.muraha21@gmail.com&token=eyJhbGciOiJIUzI1NiIsIn
      const { action, toast, ...params } = query;

      if (action) {
        // auth:openNewPassword
        const v = `${action}`.split(":");

        // @ts-ignore
        const moduleAction = modules?.[v[0]]?.actions?.[v[1]];
        if (
          v.length === 2 &&
          moduleAction &&
          typeof moduleAction === "function"
        ) {
          moduleAction(params);
        } else {
          console.error("Undefined action", v);
        }
      }

      if (toast) {
        // error:Incorect token
        const v = `${toast}`.split(":");
        // @ts-ignore
        const action = globalToast?.[v[0]];
        if (v.length === 2 && action && typeof action === "function") {
          action(v[1]);
        } else {
          console.error("Undefined toast", v);
        }
      }

      if (action || toast) {
        router.replace(pathname);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
};

export default useProcessQueryAction;
