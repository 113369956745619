import Image from "@/components/basic/Image";
import useGlobalContext from "@/context/GlobalContext";
import cn from "@/libs/cn";
import routesPublic from "@/modules/public/constants/routesPublic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "next/router";
import { useCallback, useRef, useState } from "react";

import styles from "./styles.module.scss";

interface ButtonGlobalSearch {
  router: any;
  paramName?: string;
  className?: string;
}

const ButtonGlobalSearch = (props: ButtonGlobalSearch) => {
  const { router, paramName = "search", className } = props;
  const { translate } = useGlobalContext();
  const refInput = useRef(null);
  const { query } = router;
  const [searchStr, setSearchStr] = useState(query[paramName] || "");
  const pathname = routesPublic.category();

  const onChange = useCallback((e: any) => {
    const val = e.target.value;
    setSearchStr(val);
  }, []);

  const applyValue = useCallback(
    (val: any) => {
      // @ts-ignore
      refInput.current?.blur();
      if (val) {
        router.push(
          {
            pathname,
            query: { ...query, page: 1, [paramName]: val },
          },
          undefined,
          { scroll: false }
        );
        return;
      }
      const newQuery = { ...query, page: 1 };
      delete newQuery[paramName];

      router.push(
        {
          pathname,
          query: newQuery,
        },
        undefined,
        { scroll: false }
      );
    },
    [paramName, pathname, query, router]
  );

  const onKeyDown = useCallback(
    (e: any) => {
      const val = e.target.value;
      if (e.keyCode === 13 || e.key === "Enter") {
        applyValue(val);
      }
    },
    [applyValue]
  );

  const onClear = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setSearchStr("");
      applyValue(null);
    },
    [applyValue]
  );

  const onEventStop = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      className={cn(styles.ButtonGlobalSearch, className)}
      onClick={onEventStop}
    >
      <input
        ref={refInput}
        type="search"
        name="global-search"
        placeholder={translate("Search")}
        value={searchStr}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <div className={styles.icon}>
        <Image src="/assets/search.svg" alt="search" width={24} height={24} />
      </div>
      {!!searchStr && (
        <FontAwesomeIcon
          // @ts-ignore
          icon="fa-solid fa-xmark"
          className={styles.clear}
          onClick={onClear}
        />
      )}
    </div>
  );
};

export default withRouter(ButtonGlobalSearch);
