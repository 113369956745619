import axios, { AxiosInstance } from "axios";

const HTTP_CODE = {
  HTTP_400_BAD_REQUEST: 400,
  HTTP_401_UNAUTHORIZED: 401,
  HTTP_403_FORBIDDEN: 403,
  HTTP_404_NOT_FOUND: 404,
  HTTP_500_INTERNAL_SERVER_ERROR: 500,
  HTTP_502_BAD_GATEWAY: 502,
};

const AUTH_HEADER_NAME_BEARER = "Authorization";
const AUTH_HEADER_NAME_CSRF = '"X-CSRFToken';

class dataProvider {
  code = HTTP_CODE;
  baseURL: string;
  instance: AxiosInstance;
  static providers: any = {};

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getInstance(baseURL: string) {
    if (!this.providers[baseURL]) {
      this.providers[baseURL] = new dataProvider(baseURL);
    }

    return this.providers[baseURL] as dataProvider;
  }

  setAuthHeader(typeToken: string, token: string) {
    const headerName =
      typeToken === "bearer" ? AUTH_HEADER_NAME_BEARER : AUTH_HEADER_NAME_CSRF;
    this.instance.defaults.headers.common[headerName] = token;
  }

  removeAuthHeader() {
    this.instance.defaults.headers.common.Authorization = "";
    this.instance.defaults.headers.common["X-CSRFToken"] = "";
  }

  setAnonymousHeader(anonymousId: string) {
    this.instance.defaults.headers.common["anonymous"] = anonymousId;
  }

  setLanguageParam(code: string) {
    if (!this.instance.defaults.params) {
      this.instance.defaults.params = {};
    }
    this.instance.defaults.params["language"] = (code || "").toUpperCase();
  }
}

export default dataProvider;
