import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "next/router";
import cn from "@/libs/cn";
import CustomScroll from "@/components/CustomScroll";
import TreeViewCategories from "../TreeViewCategories";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";
import useGlobalContext from "@/context/GlobalContext";
import NativeSelect from "@/components/NativeSelect";

interface FilterDropDown {
  router: any;
  label: string;
  data: any[];
  defaultIndex?: any;
  paramName: string;
  className?: string;
  showAsInput?: boolean;
  makeSorting?: boolean;
  treeApiUrl?: string;
  allowCustomValue?: boolean;
  hideUnselect?: boolean;
}

const FilterDropDown = ({
  router,
  label,
  data = [],
  defaultIndex = null,
  paramName,
  className = "",
  showAsInput,
  makeSorting = true,
  treeApiUrl,
  allowCustomValue = false,
  hideUnselect,
}: FilterDropDown) => {
  const { isMobileSize, translate } = useGlobalContext();
  const { pathname, query } = router;
  const ref = useRef(null);
  const refNative = useRef(null);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(0);
  const [filterStr, setFilterStr] = useState(null);

  const currentValue = query[paramName];

  let currentLabel =
    data.find((item: any) => item.value == currentValue)?.label || "";
  if (!currentLabel) {
    if (defaultIndex !== null && data[defaultIndex]?.label) {
      currentLabel = data[defaultIndex].label;
    } else if (allowCustomValue && !currentLabel && currentValue) {
      currentLabel = currentValue;
    }
  }

  const showOptions = useCallback(
    (val: boolean) => {
      if (!isMobileSize) {
        setShow(val);
      }
    },
    [isMobileSize]
  );

  useEffect(() => {
    if (show && ref.current) {
      // @ts-ignore
      const w = ref.current.getBoundingClientRect().width;
      setWidth(w);
    }
  }, [show]);

  const onSelect = useCallback(
    (value: any, resetSearch = true) => {
      if (resetSearch) {
        setFilterStr(null);
      }

      if (value) {
        router.push(
          {
            pathname,
            query: { ...query, page: 1, [paramName]: value },
          },
          undefined,
          { scroll: false }
        );
        return;
      }
      const newQuery = { ...query, page: 1 };
      delete newQuery[paramName];

      router.push(
        {
          pathname,
          query: newQuery,
        },
        undefined,
        { scroll: false }
      );
    },
    [paramName, pathname, query, router]
  );

  const onInput = useCallback((e: any) => {
    const val = e.target.value;
    setFilterStr(val);
  }, []);

  const onKeyDown = useCallback(
    (e: any) => {
      const val = e.target.value;
      if (allowCustomValue && (e.keyCode === 13 || e.key === "Enter")) {
        onSelect(val, false);
        showOptions(false);
      }
    },
    [allowCustomValue, onSelect, showOptions]
  );

  const sortedData = useMemo(
    () =>
      makeSorting
        ? data.sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          )
        : data,
    [data, makeSorting]
  );

  const filteredData = useMemo(
    () =>
      sortedData.filter(
        (item) =>
          filterStr === null ||
          item.label.toLowerCase().indexOf(`${filterStr}`.toLowerCase()) !== -1
      ),
    [sortedData, filterStr]
  );

  const options = useMemo(() => {
    const tdata = filteredData.map((item: any) => ({
      value: item.value,
      title: item.label,
    }));

    return hideUnselect
      ? tdata
      : [{ value: -1, title: translate("Unselect") }, ...tdata];
  }, [filteredData, hideUnselect, translate]);

  const onClear = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      onSelect(null);
    },
    [onSelect]
  );

  const onHide = useCallback(() => {
    showOptions(false);
    setFilterStr(null);
  }, [showOptions]);

  return (
    <div
      ref={ref}
      className={cn(
        styles.FilterDropDown,
        allowCustomValue && styles.allowCustomValue,
        className
      )}
      style={{
        zIndex: show ? 3 : "unset",
      }}
    >
      {show && <div className={styles.back} onClick={() => onHide()} />}
      {showAsInput ? (
        <div
          className={cn(
            stylesForms.basicWrapInput,
            styles.value,
            showAsInput && styles.showAsInput
          )}
          onClick={() => showOptions(!show)}
        >
          <input
            // className={stylesForms.input}
            value={filterStr !== null ? filterStr : currentLabel}
            placeholder={label}
            onInput={onInput}
            onKeyDown={onKeyDown}
            disabled={!allowCustomValue}
          />
          {(!!filterStr || !!currentValue) && !isMobileSize && (
            <FontAwesomeIcon
              // @ts-ignore
              icon="fa-solid fa-xmark"
              className={styles.clear}
              onClick={onClear}
            />
          )}
          {/* @ts-ignore */}
          <FontAwesomeIcon icon="fa-solid fa-angle-down" />
        </div>
      ) : (
        <div className={cn(styles.value)}>
          {isMobileSize ? (
            // @ts-ignore
            <FontAwesomeIcon icon="fa-solid fa-arrow-up-short-wide" />
          ) : (
            <div className={cn(stylesForms.basicLabel, styles.label)}>
              {label}
            </div>
          )}
          <div
            className={cn(styles.current)}
            onClick={() => showOptions(!show)}
          >
            <div>{currentLabel}</div>
            {/* @ts-ignore */}
            <FontAwesomeIcon icon="fa-solid fa-angle-down" />
          </div>
        </div>
      )}
      <div
        className={cn(
          styles.list,
          treeApiUrl && styles.tree,
          show && styles.show
        )}
        onClick={() => showOptions(false)}
        style={{ width }}
      >
        {!!treeApiUrl ? (
          <TreeViewCategories
            apiUrl={treeApiUrl}
            paramName={paramName}
            activeId={null}
            minHeight={200}
            maxHeight={200}
          />
        ) : (
          <CustomScroll key={filteredData.length} maxHeight={250}>
            <>
              {filteredData.map((item: any) => (
                <div
                  key={item.value}
                  className={cn(
                    styles.item,
                    item.isBold && styles.bold,
                    currentValue === item.value && styles.active
                  )}
                  title={item.label}
                  onClick={() => onSelect(item.value)}
                >
                  {item.label}
                </div>
              ))}
            </>
          </CustomScroll>
        )}
      </div>
      {isMobileSize && (
        <NativeSelect
          ref={refNative}
          options={options}
          onChange={(value) => onSelect(value == "-1" ? "" : value)}
          defaultValue={currentValue}
        />
      )}
    </div>
  );
};

export default withRouter(FilterDropDown);
