/* eslint-disable no-unused-vars */
import cn from "@/libs/cn";
import React, { useCallback } from "react";

import styles from "./styles.module.scss";

interface LinkToModal {
  children?: JSX.Element | string;
  text?: string;
  onClick: (e: Event) => void;
  className?: string;
}

const LinkToModal = ({
  children,
  text,
  onClick: onClickTop,
  className,
}: LinkToModal) => {
  const onClick = useCallback(
    (e: Event) => {
      if (onClickTop) {
        e.stopPropagation();
        e.preventDefault();
        onClickTop(e);
      }
    },
    [onClickTop]
  );

  return (
    <span
      className={cn(styles.linkToModal, className)}
      // @ts-ignore
      onClick={onClick}
      aria-hidden="true"
    >
      {text || children}
    </span>
  );
};

export default LinkToModal;
