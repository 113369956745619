import { useCallback, useEffect, useMemo, useState } from "react";
import publicRequests from "@/modules/public/requests";

const KEY_NAME_CURRENCY = "currency";

const defaultCurrency = {
  value: "EUR",
  label: "EUR",
  rate: "1.000",
  symbol: "€",
};

const useCurrency = () => {
  const [value, setValue] = useState(defaultCurrency.value);
  const [list, setList] = useState([defaultCurrency] as any);

  useEffect(() => {
    publicRequests
      .currencies()
      .then((response) =>
        setList(
          response.map((item: any) => ({
            value: item.code,
            label: item.name,
            rate: item.rate,
            symbol: item.symbol,
          }))
        )
      )
      .catch((err) => console.error("Error retrieve currencies data", err));
  }, []);

  const priceByRate = useCallback(
    (price: any) => {

      return price;

      let rate =
        list.find((i: any) => i.value === value)?.rate || defaultCurrency.rate;
      rate = parseFloat(rate);

      let val;
      if (typeof price === "string") {
        val = parseFloat(price);
      } else {
        val = price;
      }

      return val * rate;
    },
    [list, value]
  );

  const data = useMemo(
    () => ({
      value: value || defaultCurrency.value,
      priceByRate,
      symbol:
        list.find((i: any) => i.value === value)?.symbol ||
        defaultCurrency.symbol,
      list,
      onChange: (val: any) => {
        setValue(val);
        global?.localStorage?.setItem(KEY_NAME_CURRENCY, val);
      },
    }),
    [list, value, priceByRate]
  );

  useEffect(() => {
    // TODO not used here
    // const currencyFromStorage =
    //   global?.localStorage?.getItem(KEY_NAME_CURRENCY);

    // if (currencyFromStorage) {
    //   setValue(currencyFromStorage);
    // }
  }, []);

  return data;
};

export default useCurrency;
