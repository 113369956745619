import { useMemo } from "react";
import useGlobalContext from "@/context/GlobalContext";
import useQuery from "@/hooks/useQuery";
import {
  rangeLastMonthISOString,
  rangeLastYearISOString,
  rangeThisMonthISOString,
  rangeThisYearISOString,
} from "@/utils/date";

const TimeOrders = {
  all: { value: "", paramValue: "" },
  thisMonth: { value: "this-month", paramValue: rangeThisMonthISOString() },
  lastMonth: { value: "last-month", paramValue: rangeLastMonthISOString() },
  thisYear: { value: "this-year", paramValue: rangeThisYearISOString() },
  lastYear: { value: "lastYear", paramValue: rangeLastYearISOString() },
};

export const useDataByTimeOrders = () => {
  const { translate } = useGlobalContext();

  const data = useMemo(
    () => [
      {
        value: TimeOrders.all.value,
        label: translate("All period"),
      },
      {
        value: TimeOrders.thisMonth.value,
        label: translate("This month"),
      },
      {
        value: TimeOrders.lastMonth.value,
        label: translate("Last month"),
      },
      {
        value: TimeOrders.thisYear.value,
        label: translate("This year"),
      },
      {
        value: TimeOrders.lastYear.value,
        label: translate("Last year"),
      },
    ],
    [translate]
  );

  return data;
};

export const getProfileOrdersFilter = (
  query: { [key: string]: string } = {}
) => {
  const { search, date_range } = query;

  const filter = [];

  if (search) {
    filter.push(`search=${search}`);
  }

  if (date_range) {
    const data = Object.values(TimeOrders).find(
      (item) => item.value === date_range
    );
    if (data) {
      filter.push(`date_range=${data.paramValue}`);
    }
  }

  return filter.join("&");
};

const useProfileOrdersFilter = () => {
  const query = useQuery();

  const filterStr = getProfileOrdersFilter(query);

  return filterStr;
};

export default useProfileOrdersFilter;
