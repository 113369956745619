import React, { useCallback } from "react";
import { useField } from "formik";
import cn from "@/libs/cn";
import TextInput, { PropsTextInput } from "@/controls/TextInput";

import stylesForms from "@/styles/forms.module.scss";
export interface PropsFieldTextInput extends PropsTextInput {
  name: string;
  fieldClassName?: string;
  maxCharacters?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: (e: Event) => void;
}

const FieldTextInput = (props: PropsFieldTextInput) => {
  const {
    name,
    onChange: onChangeOut = () => {},
    fieldClassName,
    type,
    onClick,
  } = props;
  const [field, meta] = useField(name);

  const { error, touched } = meta;

  const onChange = useCallback(
    (e: Event) => {
      field.onChange(e);
      onChangeOut(e);
    },
    [field, onChangeOut]
  );

  return (
    <div
      className={cn(stylesForms.basicWrap, fieldClassName)}
      onClick={onClick}
    >
      <TextInput {...props} type={type} {...field} onChange={onChange} />
      <div
        className={cn(
          stylesForms.basicError,
          error && touched && stylesForms.show
        )}
      >
        {touched && error}
      </div>
    </div>
  );
};

export default FieldTextInput;
